import {ACTION} from "../system/System"

export const loginSuccess = (mobilephone, jwt) => {
    return {
      type: ACTION.LOGIN_SUCCESS,
      mobilephone: mobilephone,
      jwt: jwt
    };
};

export const loginFail = () => {
  return {
    type: ACTION.LOGIN_FAIL,
  };
};

export const windowResize = () => {
  return {
    type: ACTION.WINDOW_RESIZE,
  };
};

export const login = () => dispatch => {
  setTimeout(() => {
    dispatch({
      type: "login",
    });
  }, 1000);
};

export const logout = () => {
  return {
    type: ACTION.LOGOUT,
  };
};

export const updateAttributeList = (attributeList) => {
  return {
    type: ACTION.UPDATE_ATTRIBUTE_LIST,
    attributeList: attributeList
  };
};