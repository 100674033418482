import React from "react"
import "../system/common.css"
import { connect } from "react-redux"

class DevelopPage extends React.Component {

    constructor( props ) {
        super( props )
        this.state = {

        }
        
    }

    render() {

        const { isLogin, mobilephone, clientHeight } = this.props

 

        return (
            <div className="horizontalCenter verticalCenter" style={this.props.isShow?{height:"100%"}:{display:"none",height:"100%"}}>
                {this.props.content}功能待开发……
            </div>
        )
    }
}

export default connect(
    //mapStateToProps
    state => {
        return {
            jwt: state.loginInfo.jwt
        }
    },
    //mapDispatchToProps
    {

    }
)(DevelopPage);