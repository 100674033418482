import React, { useRef, useState } from "react"
import "../system/common.css"
import { BrowserRouter, Link, Route, Switch } from "react-router-dom"
import Template,{withSubscription} from "../system/Template"
import { connect } from "react-redux"
import {Layout, Menu, Breadcrumb, Button, Tabs, Tag, message, ConfigProvider} from 'antd';
import { AppstoreOutlined, MailOutlined, UserOutlined, OrderedListOutlined, ShoppingOutlined, SettingOutlined, AlertOutlined } from '@ant-design/icons';
import ProductAddPage from "./ProductAddPage"
import ProductModifyPage from "./ProductModifyPage"
import QiTaPhoneNumberPage from "./QiTaPhoneNumberPage"
import ProductAttributeManagePage from "./ProductAttributeManagePage"
import DevelopPage from "./DevelopPage"
import {loginSuccess, logout} from "../store/reactReduxAction"
import * as System from "../system/System"
import {tab} from "@testing-library/user-event/dist/tab";
import {lo} from "pinyin/data/dict-zi-web";
import AccountManagePage from "../account/AccountManagePage";
import CompanyIctPartnerPage from "./company/CompanyIctPartnerPage";
import CompanyIctProductPage from "./company/CompanyIctProductPage";

import zhCN from 'antd/es/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
import IndexFrequentExternalSystemPage from "./index/IndexFrequentExternalSystemPage";
import IndexFrequentAmapPage from "./index/IndexFrequentAmapPage";
moment.locale('zh-cn');


const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

const TemplateWithSubscription = withSubscription(Template)

class HomePage extends React.Component {



    constructor( props ) {
        super( props )
        this.state = {
            isLoading:false,
            pageInfoList: [

                {id: 'pageId_index_frequent_externalSystem', tagText: '首页-常用-第三方系统', isDisplay:false},
                {id: 'pageId_index_frequent_amap', tagText: '首页-常用-作战地图', isDisplay:true},

                {id: 'pageId_company_ict_partner', tagText: '公司-ICT-合作商', isDisplay:false},
                {id: 'pageId_company_ict_product', tagText: '公司-ICT-产品', isDisplay:false},
                {id: 'pageId_company_ict_cost', tagText: '公司-ICT-造价', isDisplay:false},

                {id: 'pageId_store_product_add', tagText: '商城-产品-新增', isDisplay:false},
                {id: 'pageId_store_product_modify', tagText: '商城-产品-修改', isDisplay:false},
                {id: 'pageId_store_product_query', tagText: '商城-产品-查询', isDisplay:false},

                {id: 'pageId_store_order_all', tagText: '商城-订单-全部', isDisplay:false},
                {id: 'pageId_store_order_notPay', tagText: '商城-订单-未付款', isDisplay:false},
                {id: 'pageId_store_order_pay', tagText: '商城-订单-已付款', isDisplay:false},
                {id: 'pageId_store_order_agentOrder', tagText: '商城-订单-代客下单', isDisplay:false},

                {id: 'pageId_store_qiTa_phoneNumber', tagText: '商城-其他-号码', isDisplay:false},

                {id: 'pageId_finance_model_model1', tagText: '理财-模型-模型1', isDisplay:false},

                {id: 'pageId_account_manage_all', tagText: '账号-管理-增删查改', isDisplay:false},
                {id: 'pageId_account_manage_query', tagText: '账号-管理-查询', isDisplay:false},
                {id: 'pageId_account_manage_add', tagText: '账号-管理-增加', isDisplay:false},
                {id: 'pageId_account_manage_modify', tagText: '账号-管理-修改', isDisplay:false},
                {id: 'pageId_account_manage_delete', tagText: '账号-管理-删除', isDisplay:false},

                {id: 'pageId_setting_miniProgram', tagText: '设置-小程序', isDisplay:false},


            ],
            currentPagId:"pageId_index_frequent_amap",
            currentTopMenuId:"topMenuId_index"

        }
        
    }


    handleClickTopMenuItem=(e)=>{

        const topMenuId = e.key
        this.setState({
            currentTopMenuId: topMenuId,
        })

    }


    handleClickMenuItem=(e)=>{

        let pageInfoList = this.state.pageInfoList

        pageInfoList.map((item,index)=>{
            if(item["id"]===e.key)
                pageInfoList[index].isDisplay=true
        })

        this.setState({
            currentPagId: e.key,
            pageInfoList: pageInfoList
        })

    }


    handleCloseTag=(id)=>{

        let pageInfoList = this.state.pageInfoList
        let currentPagId=this.state.currentPagId

        pageInfoList.map((item,index)=>{
            if(item["id"]===id)
                pageInfoList[index].isDisplay=false
        })

        const pageInfoListOfNotDisplay = pageInfoList.filter(item=>{
            return (item.isDisplay===true && item.id!==id)
        })

        if(id===currentPagId && pageInfoListOfNotDisplay.length!=0){
            currentPagId = pageInfoListOfNotDisplay[pageInfoListOfNotDisplay.length-1].id
        }

        this.setState({
            currentPagId: currentPagId,
            pageInfoList: pageInfoList
        })
    }

    handleClickTag=(id)=>{
        this.setState({
            currentPagId: id
        })
    }




    handleLogout=()=>{
        const { jwt } = this.props
        const { logout } = this.props
        let url = System.URL.LOGOUT
        System.fetchGet(url,jwt, (data)=>{
            if ( data.code!==100 ) {
                alert("注销失败")
                return
            }

            logout()
            
        })
    }


    componentDidMount() {
        const { loginSuccess } = this.props;
        // loginSuccess("accountId_hwang", "eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiJhMmJjYTFjYjBmZWY0ZTBjOTc3NDg0OWI1YjgwZjQzZiIsInN1YiI6IntcImFjY291bnRJZFwiOlwiYWNjb3VudElkX2h3YW5nXCIsXCJhY2NvdW50VHlwZUlkXCI6XCJhY2NvdW50VHlwZUlkX2FkbWluXCIsXCJwZXJtaXNzaW9uTGlzdFwiOltcImFjY291bnRUeXBlSWRfYWRtaW5cIixcImFjY291bnRQZXJtaXNzaW9uSWRfbWFuYWdlQWNjb3VudFwiLFwiYWNjb3VudFBlcm1pc3Npb25JZF9leHBvcnREYXRhXCJdfSIsImlzcyI6InNnIiwiaWF0IjoxNzMwMzcwMjg0LCJleHAiOjE3NjE5MDYyODR9.ZiySxoH-NdmmkvMhTG-v0GGWjZRXB9EzwjZm1H3W9ms")
    }




    render() {

        const { isLogin, logout, mobilephone, clientHeight } = this.props

        // const [activeKey, setActiveKey] = useState(this.state.pageInfoList[0].key);
        // const [items, setItems] = useState(this.state.pageInfoList);



        // defaultPanes1 = defaultPanes1.concat({label: 'Tab 1', children: 'Content of Tab Pane 1', key: '1'})
        // defaultPanes1 = defaultPanes1.concat({label: 'Tab 2', children: 'Content of Tab Pane 2', key: '2'})

        let currentPagIndex = 0

        const tagListContent = this.state.pageInfoList.map((item,index)=>{

            if(item["isDisplay"]===false) return

            let style ={}
            style["cursor"]="pointer"
            style["margin"]="5px"
            if(item["id"]===this.state.currentPagId){
                style["color"]="#0088cc"
                currentPagIndex = index
            }

            return (
                <Tag closable onClose={()=>this.handleCloseTag(item["id"])} onClick={()=>this.handleClickTag(item["id"])} style={style}>
                    {item["tagText"]}
                </Tag>
            )


        })


        const topMenuInfoList = [
            {
                label: '首页',
                key: 'topMenuId_index',
            },
            // {
            //     label: '公司',
            //     key: 'topMenuId_company',
            // },
            // {
            //     label: '商城',
            //     key: 'topMenuId_store',
            // },
            // {
            //     label: '理财',
            //     key: 'topMenuId_finance',
            // },
            // {
            //     label: '账号',
            //     key: 'topMenuId_account',
            // },
            {
                label: '设置',
                key: 'topMenuId_setting',
            }]



        const content= 
            <Layout style={{minHeight:clientHeight}}>
                <Header className="header" style={{padding:"0 3px",backgroundColor:"#F0F2F5"}}>
                    <div className="logo hwBlueColor hwWhiteBackground horizontalCenter">
                        <strong>Hwang</strong>
                    </div>
                    <Menu onClick={this.handleClickTopMenuItem} defaultSelectedKeys={[this.state.currentTopMenuId]} mode="horizontal" items={topMenuInfoList} style={{float:"left",width:"880px",height:"65px",fontSize:"1.5em"}} />
                    <div className="horizontalCenter" style={{float:"left",width:"194px",backgroundColor:"white"}}>
                        <div style={{float:"left"}}>{mobilephone}</div>
                        <div style={{float:"left"}}>
                            <Button type="link" block onClick={this.handleLogout}>注销</Button>
                        </div>  
                    </div>
                </Header>
                <Layout style={{ padding: '3px' }}>
                    <Sider width={200} className="site-layout-background" style={this.state.currentTopMenuId==='topMenuId_index'?{}:{display:'none'}}>
                        <Menu
                            mode="inline"
                            defaultOpenKeys={['menuId_index_frequent']}
                            defaultSelectedKeys={['pageId_index_frequent_amap']}
                            style={{ height: '100%', borderRight: 0 }}
                        >
                            <SubMenu key="menuId_index_frequent" icon={<ShoppingOutlined />} title="常用">
                                <Menu.Item key="pageId_index_frequent_externalSystem" onClick={this.handleClickMenuItem}>第三方系统</Menu.Item>
                                <Menu.Item key="pageId_index_frequent_amap" onClick={this.handleClickMenuItem}>作战地图</Menu.Item>
                            </SubMenu>
                        </Menu>
                    </Sider>

                    <Sider width={200} className="site-layout-background" style={this.state.currentTopMenuId==='topMenuId_company'?{}:{display:'none'}}>
                        <Menu
                            mode="inline"
                            defaultOpenKeys={['menuId_company_ict']}
                            defaultSelectedKeys={['pageId_company_ict_product']}
                            style={{ height: '100%', borderRight: 0 }}
                        >
                            <SubMenu key="menuId_company_ict" icon={<ShoppingOutlined />} title="ICT">
                                <Menu.Item key="pageId_company_ict_partner" onClick={this.handleClickMenuItem}>合作商</Menu.Item>
                                <Menu.Item key="pageId_company_ict_product" onClick={this.handleClickMenuItem}>产品</Menu.Item>
                                <Menu.Item key="pageId_company_ict_cost" onClick={this.handleClickMenuItem}>造价</Menu.Item>
                            </SubMenu>
                        </Menu>
                    </Sider>

                    <Sider width={200} className="site-layout-background" style={this.state.currentTopMenuId==='topMenuId_store'?{}:{display:'none'}}>
                        <Menu
                            mode="inline"
                            defaultOpenKeys={['menuId_account_user']}
                            defaultSelectedKeys={['pageId_store_product_query']}
                            style={{ height: '100%', borderRight: 0 }}
                        >
                            <SubMenu key="menuId_store_product" icon={<ShoppingOutlined />} title="产品">
                                <Menu.Item key="pageId_store_product_add" onClick={this.handleClickMenuItem}>新增</Menu.Item>
                                <Menu.Item key="pageId_store_product_modify" onClick={this.handleClickMenuItem}>修改</Menu.Item>
                                {/* <Menu.Item key="pageId_store_product_attribute_manage" onClick={this.handleClickMenuItem}>属性管理</Menu.Item> */}
                                <Menu.Item key="pageId_store_product_query" onClick={this.handleClickMenuItem}>查询</Menu.Item>
                            </SubMenu>
                            <SubMenu key="menuId_store_order" icon={<OrderedListOutlined />} title="订单">
                                <Menu.Item key="pageId_store_order_all" onClick={this.handleClickMenuItem}>全部</Menu.Item>
                                <Menu.Item key="pageId_store_order_notPay" onClick={this.handleClickMenuItem}>未付款</Menu.Item>
                                <Menu.Item key="pageId_store_order_pay" onClick={this.handleClickMenuItem}>已付款</Menu.Item>
                                <Menu.Item key="pageId_store_order_agentOrder"onClick={this.handleClickMenuItem} >代客下单</Menu.Item>
                            </SubMenu>
                            <SubMenu key="menuId_store_qiTa" icon={<AlertOutlined />} title="其他">
                                <Menu.Item key="pageId_store_qiTa_phoneNumber" onClick={this.handleClickMenuItem}>号码</Menu.Item>
                            </SubMenu>
                        </Menu>
                    </Sider>

                    <Sider width={200} className="site-layout-background" style={this.state.currentTopMenuId==='topMenuId_finance'?{}:{display:'none'}}>
                        <Menu
                            mode="inline"
                            defaultOpenKeys={['menuId_finance_model']}
                            defaultSelectedKeys={['pageId_finance_model_model1']}
                            style={{ height: '100%', borderRight: 0 }}
                        >
                            <SubMenu key="menuId_finance_model" icon={<ShoppingOutlined />} title="模型">
                                <Menu.Item key="pageId_finance_model_model1" onClick={this.handleClickMenuItem}>模型1</Menu.Item>
                            </SubMenu>
                        </Menu>
                    </Sider>

                    <Sider width={200} className="site-layout-background" style={this.state.currentTopMenuId==='topMenuId_account'?{}:{display:'none'}}>
                        <Menu
                            mode="inline"
                            defaultOpenKeys={['menuId_account_manage']}
                            defaultSelectedKeys={['pageId_account_manage_all']}
                            style={{ height: '100%', borderRight: 0 }}
                        >
                            <SubMenu key="menuId_account_manage" icon={<ShoppingOutlined />} title="管理">
                                <Menu.Item key="pageId_account_manage_all" onClick={this.handleClickMenuItem}>增删查改</Menu.Item>
                                <Menu.Item key="pageId_account_manage_query" onClick={this.handleClickMenuItem}>查询</Menu.Item>
                                <Menu.Item key="pageId_account_manage_add" onClick={this.handleClickMenuItem}>增加</Menu.Item>
                                <Menu.Item key="pageId_account_manage_modify" onClick={this.handleClickMenuItem}>修改</Menu.Item>
                                <Menu.Item key="pageId_account_manage_delete" onClick={this.handleClickMenuItem}>删除</Menu.Item>
                            </SubMenu>
                        </Menu>
                    </Sider>

                    <Sider width={200} className="site-layout-background" style={this.state.currentTopMenuId==='topMenuId_setting'?{}:{display:'none'}}>
                        <Menu
                            mode="inline"
                            defaultSelectedKeys={['pageId_setting_miniProgram']}
                            style={{ height: '100%', borderRight: 0 }}
                        >
                            <Menu.Item key="pageId_setting_miniProgram" onClick={this.handleClickMenuItem}>小程序</Menu.Item>
                        </Menu>
                    </Sider>

                    <Layout style={{ padding: '0 0 0 3px' }}>

                        <div style={{ minHeight:'40px', padding: '4px', margin:"0 0 3px 0", backgroundColor:"white"}}>
                            {tagListContent}
                        </div>



                        <Content
                            className="site-layout-background"
                            style={{
                                padding: 24,
                                margin: 0,
                                minHeight: 280,
                            }}
                        >

                            <IndexFrequentExternalSystemPage isShow={this.state.currentPagId==="pageId_index_frequent_externalSystem"}></IndexFrequentExternalSystemPage>
                            <IndexFrequentAmapPage isShow={this.state.currentPagId==="pageId_index_frequent_amap"}></IndexFrequentAmapPage>
                            <CompanyIctPartnerPage isShow={this.state.currentPagId==="pageId_company_ict_partner"}></CompanyIctPartnerPage>
                            <CompanyIctProductPage isShow={this.state.currentPagId==="pageId_company_ict_product"}></CompanyIctProductPage>
                            <AccountManagePage isShow={this.state.currentPagId==="pageId_account_manage_all"}></AccountManagePage>
                            {/*<ProductAddPage isShow={this.state.currentPagId==="pageId_store_product_add"}></ProductAddPage>*/}
                            {/*<ProductModifyPage isShow={this.state.currentPagId==="pageId_store_product_modify"}></ProductModifyPage>*/}
                            {/*<QiTaPhoneNumberPage isShow={this.state.currentPagId==="pageId_store_qiTa_phoneNumber"}></QiTaPhoneNumberPage>*/}
                            <DevelopPage isShow={
                                ['pageId_company_ict_cost',
                                'pageId_store_product_add',
                                'pageId_store_product_modify',
                                'pageId_store_product_query',
                                'pageId_store_order_all',
                                'pageId_store_order_notPay',
                                'pageId_store_order_pay',
                                'pageId_store_order_agentOrder',
                                'pageId_store_qiTa_phoneNumber',
                                'pageId_finance_model_model1',
                                'pageId_account_manage_query',
                                'pageId_account_manage_add',
                                'pageId_account_manage_modify',
                                'pageId_account_manage_delete',
                                'pageId_setting_miniProgram',
                                ].indexOf(this.state.currentPagId) >= 0} content={"【"+this.state.pageInfoList[currentPagIndex]["tagText"]+"】"}>
                                // this.state.currentPagId !=="pageId_store_product_add" && this.state.currentPagId!=="pageId_store_product_modify" && this.state.currentPagId!=="pageId_store_product_attribute_manage" && this.state.currentPagId!=="pageId_store_qiTa_phoneNumber"
                            </DevelopPage>

                            {/* <ProductAttributeManagePage isShow={this.state.currentPagId==="pageId_store_product_attribute_manage"}></ProductAttributeManagePage> */}
                            {/* {this.state.currentPage} */}
                        </Content>
                    </Layout>
                </Layout>
            </Layout>

        return (
            <ConfigProvider locale={zhCN}>
                <TemplateWithSubscription isLoading={this.state.isLoading}>{content}</TemplateWithSubscription>
            </ConfigProvider>
            )
    }


    
}

export default connect(
    //mapStateToProps
    state => {
        return {
            isLogin: state.loginInfo.isLogin,
            mobilephone: state.loginInfo.mobilephone,
            jwt: state.loginInfo.jwt,
            clientHeight : state.clientHeight
        };
    },
    //mapDispatchToProps
    {
        loginSuccess,
        logout
    }
)(HomePage);



