import React from "react"
import "../system/common.css"
import { connect } from "react-redux"
import { Switch, Button, Col, Divider, Input,InputNumber, Row, Select, Space, Typography, Tag, Upload } from 'antd';
import Item from "antd/lib/list/Item";
import * as System from "../system/System"
import { UploadOutlined } from '@ant-design/icons';

const { Text, Link, Title } = Typography;
const { Option, OptGroup } = Select;
const { TextArea } = Input

class ProductAttributeManagePage extends React.Component {

    constructor( props ) {
        super( props )
        
        // System.fetchGet(System.URL.LOGIN+'?mobilephone=13829900000&&appKey=appKey',(data)=>{

        //     if ( data.code!==System.RESULT.SUCCESS.code ) {
        //         alert(JSON.stringify(data))
        //         return
        //     }

           
            
        // })
        
    }

    

    render() {

        console.log("modify-state",this.state);

        const { isLogin, mobilephone, clientHeight } = this.props
        // const {  } = this.state;

        


        return (
            <div className="horizontalCenter" style={this.props.isShow?{}:{display:"none"}}>
                <Space align="" direction="vertical" style={{width:"500px"}}>
                
                    <Row align="middle" style={{ width: "100%" }}>
                        <Col className="horizontalCenter" flex="100px" style={{}}></Col>
                        <Col flex="auto" style={{}}>
                        </Col>
                    </Row>         

                </Space>
            </div>
        )
    }
}

export default connect(
    //mapStateToProps
    state => {
        return {
            jwt: state.loginInfo.jwt
        }
    },
    //mapDispatchToProps
    {

    }
)(ProductAttributeManagePage);