import {ACTION} from "../system/System"
import * as System from "../system/System"

// export default function loginReducer(state = {isLogin:true, mobilephone:"13829900000"}, action) {
export default function loginReducer(state = {isLogin:System.INIT_DATA_IS_LOGIN, mobilephone:System.INIT_DATA_MOBILEPHONE, jwt:System.INIT_DATA_JWT}, action) {

    console.log("state",state);
    console.log("action",action);

    switch (action.type) {
        case ACTION.LOGIN_SUCCESS:
            return {isLogin:true, mobilephone:action.mobilephone, jwt:action.jwt};
        case ACTION.LOGIN_FAIL:
            return {isLogin:false, mobilephone:null, jwt:null};
        case ACTION.LOGOUT:
            return {isLogin:false, mobilephone:null, jwt:null};
        default:
            return state;
    }
}