import React from "react"
import { BrowserRouter, Link, Route, Switch } from "react-router-dom"
import HomePage from "./HomePage"
import Login from "../account/Login"
import LoginRoute from "./LoginRoute"
import * as System from "../system/System"

export default class Router extends React.Component {

  render() {

    // System.fetchGet(System.URL.LOGIN+'?mobilephone=13829900000&&appKey=appKey',(data)=>{
    //   if ( data.code!==System.RESULT.SUCCESS.code ) {
    //       alert(JSON.stringify(data))
    //       return
    //   }
    // })
    // var start = (new Date()).getTime();
    // while((new Date()).getTime() - start < 1000) {
    //     continue;
    // }

    return (
      <div>
        <BrowserRouter>
          <Switch>
            {/* <Route exact path="/" component={HomePage} />
            <Route path="/login" component={Login} />
            <Route component={() => <div>404</div>} /> */}

            <Route path="/web/login" component={Login} />
            {/*<Route path="/web/login" component={HomePage} />*/}
            <LoginRoute component={HomePage} />


          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}
