import React, { Children } from 'react';
import "./common.css"
import $ from 'jquery'
import { Spin } from 'antd'
import 'antd/dist/antd.min.css';
import {windowResize} from "../store/reactReduxAction"
import { connect } from "react-redux";


class Template extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            clientWidth: 0,
            clientHeight: 0,
            centerWidth: 0,
            sideWidth: 0,
            sideContent: null,
            
            pageWidth: 0,
            pageHeight: 0
        }

        this.state.clientWidth = document.body.clientWidth
        this.state.clientHeight = document.body.clientHeight

        if (this.state.clientWidth > 1280) {
            this.state.centerWidth = 1280
            this.state.pageWidth = document.body.clientWidth
            this.state.sideWidth = (document.body.clientWidth - 1280) / 2
            this.state.sideContent = <div>&nbsp;</div>
        } else {
            this.state.centerWidth = 1280
            this.state.pageWidth = 1280
            this.state.sideWidth = 0
            this.state.sideContent = <div></div>
        }
        
    }

    componentDidMount() {
        window.addEventListener('resize', this.reLayout.bind(this));

        if(document.createEvent) {
            var event = document.createEvent("HTMLEvents");
            event.initEvent("resize", true, true);
            window.dispatchEvent(event);
        } else if(document.createEventObject) {
            window.fireEvent("onresize");
        }
    }

    componentWillUnmount() {       
        window.removeEventListener('resize',this.reLayout.bind(this));
    }

    // shouldComponentUpdate(nextProps, nextState) {
    //     console.log("2222")
    //     return true
    // }
    
    
        
        

    reLayout = e => {

        $("#page").css("visibility", "hidden");

        if (document.body.clientWidth > 1280) {

            this.setState({
                clientWidth : document.body.clientWidth,
                clientHeight : document.body.clientHeight,
                centerWidth : 1280,
                sideWidth : (document.body.clientWidth - 1280) / 2,
                pageWidth : document.body.clientWidth,
                pageHeight : document.getElementById("content").scrollHeight,
                sideContent : <div>&nbsp;</div>
            })

		} else {

            this.setState({
                clientWidth : document.body.clientWidth,
                clientHeight : document.body.clientHeight,
                centerWidth : 1280,
                sideWidth : 0,
                pageWidth : 1280,
                pageHeight : document.getElementById("content").scrollHeight,
                sideContent : <div></div>
             })

        }

        const {windowResize} = this.props
        windowResize()
		
		$("#page").css("visibility", "visible");
    }


    render() {

        let isLoading = this.props.isLoading === true ? {visibility:'visible'}:{visibility:'hidden'}

        return(
            <div>
                <div id="view_loading" className="horizontalCenter verticalCenter" style={{width:this.state.pageWidth, height:this.state.clientHeight, ...isLoading}}>
                    <Spin tip="Loading..." />
                </div>


                <div id="page">
                    <div id="left_blank" style={{width:this.state.sideWidth}}>{this.state.sideContent}</div>
                    <div id="content" style={{width: this.state.centerWidth, backgroundcolor: 'aqua'}}>
                        {/* {this.props.content} */}
                        {this.props.children}
                    </div>
                    <div id="right_blank" style={{width:this.state.sideWidth}}>{this.state.sideContent}</div>
                </div>
            </div>
        )
    }

};



export default connect(
    //mapStateToProps
    state => {
        return {
            clientHeight : state.clientHeight,
            jwt: state.loginInfo.jwt
        };
    },
    //mapDispatchToProps
    {
        windowResize
    },
)(Template);



export const withSubscription = WrappedComponent => props => {
    return <WrappedComponent {...props} />

}
