import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

class LoginRoute extends Component {
    render() {
        const { component: Component, isLogin, ...rest } = this.props;
        console.log("LoginRoute", this.props);
        return (
            <Route
                {...rest}
                render={props => {
                    console.log(this.props.location.pathname)
                    return isLogin ? (
                        <Component {...props} />
                    ) : (
                        <Redirect
                            to={{
                            pathname: "/web/login",
                            state: { redirect: this.props.location.pathname },
                            }}
                        />
                    )
                }}
            />
        )
    }
}

export default connect(
    state => ({
        isLogin: state.loginInfo.isLogin,
        jwt: state.loginInfo.jwt
    })
)(LoginRoute)