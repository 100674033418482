import React, { useState } from "react"
import "../../system/common.css"
import { connect } from "react-redux"
import {
    Switch,
    Button,
    Col,
    Divider,
    Input,
    InputNumber,
    Row,
    Select,
    Space,
    Typography,
    Tag,
    Upload,
    Radio,
    Pagination,
    message,
    Modal,
    Dropdown,
    DatePicker
} from 'antd';
import Item from "antd/lib/list/Item";
import * as System from "../../system/System"
import { UploadOutlined,DownOutlined } from '@ant-design/icons';
import {beforeCompressFileUpload, beforeCompressUpload, beforeExcelUpload} from "../../system/System";
import moment from 'moment';



const { Text, Link, Title } = Typography;
const { Option, OptGroup } = Select;
const { TextArea } = Input

class CompanyIctPartnerPage extends React.Component {

    constructor( props ) {
        super( props )

        const { jwt } = this.props

        this.state = {
            isTipModalOpen:false,
            modalContent:"",
            statusListOfAll:[],
            page:1,
            pageSize:10,
            resultCount:0,
            partnerList:[],
            keywordOfPartnerName:"",
            isAddPartnerModalOpen:false,
            isConfirmDeleteModalOpen: false,
            isModifyPartnerModalOpen:false,
            isZhenxuanModalOpen:false,
            partnerIdOfWillDelete:"",
            addPartnerModal:{
                name:"广东大慧州信息技术有限公司",
                statusIdOfSelect:"isOrNotStatusId_yes",
                statusNameOfSelect:"是",

            },
            modifyPartnerModal:{
                id:"",
                name:"",
                statusId:"",
                statusName:""
            },

            zhenxuanData:{
                partnerId:"partnerId_001",
                partnerName:"惠州市泰思云通信有限公司",
                projectName:"惠州市公安局惠阳区分局移动警务延续服务采购项目",
                deadlineDate:"2023-04-04",
                templateFileNameList:[],
                priceFileName:null,
            },
            zhenxuanTemplateFileUploadProps: {
                action: System.URL.UPLOAD_ZHENXUAN_TEMPLATE_FILE,
                withCredentials:true,
                onChange({ file, fileList }) {
                },
                customRequest: info => {
                    const formData = new FormData();
                    formData.append('file', info.file);//名字和后端接口名字对应
                    formData.append('partnerId', this.state.zhenxuanData.partnerId);

                    let url = System.URL.UPLOAD_ZHENXUAN_TEMPLATE_FILE
                    System.fetchPost(url,jwt,
                        formData,
                        (data) => {
                            if (data.code !== System.RESULT.SUCCESS.code) {
                                alert(JSON.stringify(data))
                                return
                            }

                            let {zhenxuanTemplateFileUploadProps, zhenxuanData} = this.state
                            zhenxuanData.templateFileNameList.push(data.name)
                            zhenxuanTemplateFileUploadProps.fileList.push({
                                uid: data.uid,
                                name: data.name,
                                status: 'done',
                                response: '',
                                url: data.url,
                            })
                            this.setState({
                                zhenxuanTemplateFileUploadProps:zhenxuanTemplateFileUploadProps,
                                zhenxuanData: zhenxuanData
                            })
                        }
                    )
                },
                onRemove: file => {//删除图片调用

                    let url = System.URL.DELETE_ZHENXUAN_TEMPLATE_FILE
                    url = url + "?partnerId=" + this.state.zhenxuanData.partnerId
                    url = url + "&fileName=" + file.name

                    System.fetchGet(url, jwt, (data)=>{
                        if (data.code !== System.RESULT.SUCCESS.code) {
                            alert(JSON.stringify(data))
                            return
                        }

                        let {zhenxuanTemplateFileUploadProps, zhenxuanData} = this.state
                        let index = zhenxuanTemplateFileUploadProps.fileList.indexOf(file);
                        // iconImageUploadProps.fileList = iconImageUploadProps.fileList.slice();
                        zhenxuanTemplateFileUploadProps.fileList.splice(index, 1)

                        index = zhenxuanData.templateFileNameList.indexOf(file.name);
                        zhenxuanData.templateFileNameList.splice(index, 1)
                        this.setState({
                            zhenxuanTemplateFileUploadProps: zhenxuanTemplateFileUploadProps,
                            zhenxuanData:zhenxuanData
                        })
                    })
                },
                fileList: [],
            },
            zhenxuanPriceFileUploadProps: {
                action: System.URL.UPLOAD_ZHENXUAN_PRICE_FILE,
                withCredentials:true,
                onChange({ file, fileList }) {
                },
                customRequest: info => {
                    const formData = new FormData();
                    formData.append('file', info.file);//名字和后端接口名字对应
                    formData.append('partnerId', this.state.zhenxuanData.partnerId);

                    let url = System.URL.UPLOAD_ZHENXUAN_PRICE_FILE
                    System.fetchPost(url,jwt,
                        formData,
                        (data) => {
                            if (data.code !== System.RESULT.SUCCESS.code) {
                                alert(JSON.stringify(data))
                                return
                            }

                            let {zhenxuanPriceFileUploadProps, zhenxuanData} = this.state
                            zhenxuanData.priceFileName=data.name
                            zhenxuanPriceFileUploadProps.fileList.push({
                                uid: data.uid,
                                name: data.name,
                                status: 'done',
                                response: '',
                                url: data.url,
                            })
                            this.setState({
                                zhenxuanPriceFileUploadProps:zhenxuanPriceFileUploadProps,
                                zhenxuanData: zhenxuanData
                            })
                        }
                    )
                },
                onRemove: file => {//删除图片调用

                    let url = System.URL.DELETE_ZHENXUAN_PRICE_FILE
                    url = url + "?partnerId=" + this.state.zhenxuanData.partnerId
                    url = url + "&fileName=" + file.name

                    System.fetchGet(url, jwt, (data)=>{
                        if (data.code !== System.RESULT.SUCCESS.code) {
                            alert(JSON.stringify(data))
                            return
                        }

                        let {zhenxuanPriceFileUploadProps, zhenxuanData} = this.state
                        let index = zhenxuanPriceFileUploadProps.fileList.indexOf(file);
                        zhenxuanPriceFileUploadProps.fileList.splice(index, 1)

                        index = zhenxuanData.templateFileNameList.indexOf(file.name);
                        zhenxuanData.priceFileName=null
                        this.setState({
                            zhenxuanPriceFileUploadProps: zhenxuanPriceFileUploadProps,
                            zhenxuanData:zhenxuanData
                        })
                    })
                },
                fileList: [],
            }
        }
        
    }


    componentDidMount() {
        // this.handleClickQueryButton()

        // let url = System.URL.LOGIN
        // System.fetchGet(url,(data)=>{
        //
        //     if ( data.code!==100 ) {
        //         this.setState({
        //             isTipModalOpen: true,
        //             modalContent: JSON.stringify(data)
        //         })
        //         return
        //     }
        //
        //     this.setState({
        //     })
        // })

        const { jwt } = this.props
        let url = System.URL.FIND_ALL_IS_OR_NOT_STATUS
        System.fetchGet(url,jwt, (data)=>{

            if ( data.code!==100 ) {
                this.setState({
                    isTipModalOpen: true,
                    modalContent: JSON.stringify(data)
                })
                return
            }

            this.setState({
                statusListOfAll: data.statusList
                // isTipModalOpen: true,
                // modalContent: "添加成功"
            }, ()=> {
                this.onClickSearchPartnerButton()
            })
        })

        // setTimeout(() => {
        //     this.onClickZhenxuanButton("partnerId_001", "惠州市泰思云通信有限公司")
        // }, 1000);
        //
        // setTimeout(() => {
        //     this.onClickCreateZhenxuanPackageAndDownloadButton()
        // }, 2000);

    }

    handleChangePageOrSize=(page, pageSize)=>{
        this.comQueryButton.focus()
        this.comQueryButton.blur()

        this.setState({
            page: page,
            pageSize: pageSize
        }, ()=> {
            this.onClickSearchPartnerButton()
        })

    }


    onChangeSearchPartnerInput = (e) =>{

        this.setState({
            keywordOfPartnerName: e.target.value
        })
    }




    onClickSearchPartnerButton = () => {

        const { jwt } = this.props

        this.comQueryButton.focus()
        this.comQueryButton.blur()

        let url = System.URL.FIND_PARTNER_LIST_OF_PAGE
        url = url + "?page=" + this.state.page
        url = url + "&pageSize=" + this.state.pageSize
        if( this.state.keywordOfPartnerName !== "" ) {
            url = url + "&keywordOfPartnerName=" + this.state.keywordOfPartnerName
        }

        // this.setState({
        //     isTipModalOpen: true,
        //     modalContent: url
        // })
        //
        // return

        System.fetchGet(url,jwt,(data)=>{

            if ( data.code!==100 ) {
                this.setState({
                    isTipModalOpen: true,
                    modalContent: JSON.stringify(data)
                })
                return
            }

            // this.setState({
            //     isTipModalOpen: true,
            //     modalContent: JSON.stringify(data)
            // })

            this.setState({
                resultCount: data.resultCount,
                partnerList: data.partnerList
            })

        })
    }


    onClickAddPartnerModalStatusDropdown = ({ key }) => {

        let addPartnerModal = this.state.addPartnerModal

        addPartnerModal.statusIdOfSelect = key
        this.state.statusListOfAll.map(item=>{
            if(item["id"]===key){
                addPartnerModal.statusNameOfSelect = item["name"]
            }
        })

        this.setState({
            addPartnerModal: addPartnerModal
        })
    }


    onChangeAddPartnerModalNameInput=(e)=>{

        let addPartnerModal = this.state.addPartnerModal
        addPartnerModal.name = e.target.value

        this.setState({
            addPartnerModal: addPartnerModal
        })
    }


    onClickAddPartnerButton = () => {

        this.setState({
            isAddPartnerModalOpen:true
        })
        this.comQueryButton.focus()
        this.comQueryButton.blur()
    }


    onClickAddPartnerModalSubmitButton=()=>{

        const { jwt } = this.props

        this.comQueryButton.focus()
        this.comQueryButton.blur()

        let url = System.URL.ADD_PARTNER
        url = url+"?name="+this.state.addPartnerModal.name
        url = url+"&statusId="+this.state.addPartnerModal.statusIdOfSelect

        System.fetchGet(url,jwt, (data)=>{

            if ( data.code!==100 ) {
                this.setState({
                    isTipModalOpen: true,
                    modalContent: JSON.stringify(data)
                })
                return
            }

            let addPartnerModal = this.state.addPartnerModal
            addPartnerModal.name = "广东大慧州信息技术有限公司"
            addPartnerModal.statusIdOfSelect = "isOrNotStatusId_yes"
            addPartnerModal.statusNameOfSelect = "是"

            this.setState({
                addPartnerModal: addPartnerModal,
                isTipModalOpen: true,
                modalContent: JSON.stringify(data),
                isAddPartnerModalOpen: false
            })

            this.onClickSearchPartnerButton()


        })
    }


    onClickWillDeletePartner = (partnerId) =>{
        this.comQueryButton.focus()
        this.comQueryButton.blur()

        this.setState({
            isConfirmDeleteModalOpen: true,
            partnerIdOfWillDelete: partnerId
        })
    }


    onClickZhenxuanButton = (partnerId, partnerName) =>{
        const { jwt } = this.props
        this.comQueryButton.focus()
        this.comQueryButton.blur()

        let url = System.URL.FIND_ZHENXUAN_TEMPLATE_FILE_LIST_AND_PRICE_FILE
        url = url+"?partnerName="+partnerName

        System.fetchGet(url,jwt,(data)=>{

            if ( data.code!==100 ) {
                this.setState({
                    isTipModalOpen: true,
                    modalContent: JSON.stringify(data)
                })
                return
            }


            let zhenxuanData = this.state.zhenxuanData
            let zhenxuanTemplateFileUploadProps = this.state.zhenxuanTemplateFileUploadProps
            let zhenxuanPriceFileUploadProps = this.state.zhenxuanPriceFileUploadProps

            zhenxuanData.partnerId = partnerId
            zhenxuanData.partnerName = partnerName
            if (data.templateFileNameList!=null){
                zhenxuanData.templateFileNameList = data.templateFileNameList
            }else{
                zhenxuanData.templateFileNameList = []
            }
            if (data.priceFileName!=undefined){
                zhenxuanData.priceFileName = data.priceFileName
            }else {
                zhenxuanData.priceFileName = null
            }

            zhenxuanTemplateFileUploadProps.fileList = []
            for (let i=0; i<zhenxuanData.templateFileNameList.length; i++) {
                zhenxuanTemplateFileUploadProps.fileList.push({
                        uid: zhenxuanData.templateFileNameList[i],
                        name: zhenxuanData.templateFileNameList[i],
                        status: "done",
                        response: "",
                        url: "",
                })
            }

            zhenxuanPriceFileUploadProps.fileList = []
            if (zhenxuanData.priceFileName!=null){
                zhenxuanPriceFileUploadProps.fileList.push({
                    uid: zhenxuanData.priceFileName,
                    name: zhenxuanData.priceFileName,
                    status: "done",
                    response: "",
                    url: "",
                })
            }




            this.setState({
                isZhenxuanModalOpen: true,
                zhenxuanData: zhenxuanData
            })

        })

        // let zhenxuanData = this.state.zhenxuanData
        //
        // zhenxuanData.partnerId = partnerId
        // zhenxuanData.partnerName = partnerName
        //
        // this.setState({
        //     isZhenxuanModalOpen: true,
        //     zhenxuanData: zhenxuanData
        // })

    }


    onClickCancelDeletePartner = () =>{
        this.comQueryButton.focus()
        this.comQueryButton.blur()

        this.setState({
            isConfirmDeleteModalOpen: false,
            partnerIdOfWillDelete: ""
        })
    }


    onClickConfirmDeletePartner = (id) =>{
        const { jwt } = this.props
        this.comQueryButton.focus()
        this.comQueryButton.blur()

        let url = System.URL.DELETE_PARTNER
        url = url+"?partnerId="+id
        System.fetchGet(url,jwt,(data)=>{
            if ( data.code!==100 ) {
                this.setState({
                    isTipModalOpen: true,
                    modalContent: JSON.stringify(data),
                    isConfirmDeleteModalOpen:false
                })
                return
            }

            this.setState({
                isTipModalOpen: true,
                modalContent: "删除成功",
                isConfirmDeleteModalOpen:false
            })

            this.onClickSearchPartnerButton()
        })
    }


    onChangeModifyPartnerModalNameInput=(e)=>{

        let modifyPartnerModal = this.state.modifyPartnerModal
        modifyPartnerModal.name = e.target.value

        this.setState({
            modifyPartnerModal: modifyPartnerModal
        })
    }


    onClickModifyPartnerModalStatusDropdown = ({ key }) => {

        let modifyPartnerModal = this.state.modifyPartnerModal

        modifyPartnerModal.statusId = key
        this.state.statusListOfAll.map(item=>{
            if(item["id"]===key){
                modifyPartnerModal.statusName = item["name"]
            }
        })

        this.setState({
            modifyPartnerModal: modifyPartnerModal
        })
    }


    onClickModifyPartnerModalSubmitButton = () =>{
        const { jwt } = this.props
        this.comQueryButton.focus()
        this.comQueryButton.blur()

        // message.info(typeof this.state.modifyAccountModal.password)


        let url = System.URL.MODIFY_PARTNER
        url = url + "?partnerId=" + this.state.modifyPartnerModal.id
        url = url + "&name=" + this.state.modifyPartnerModal.name
        url = url + "&statusId=" + this.state.modifyPartnerModal.statusId
        // console.log("hwang",url)
        // message.info(url)
        System.fetchGet(url,jwt,(data)=>{
            if ( data.code!==100 ) {
                this.setState({
                    isTipModalOpen: true,
                    modalContent: JSON.stringify(data),
                })
                return
            }

            this.setState({
                // isTipModalOpen: true,
                // modalContent: url,
                isModifyPartnerModalOpen:false,
            })

            message.success("修改成功")

            this.onClickSearchPartnerButton()
        })
    }


    onClickModifyPartnerButton = (partnerId) =>{
        const { jwt } = this.props
        this.comQueryButton.focus()
        this.comQueryButton.blur()

        let url = System.URL.FIND_PARTNER_BY_ID
        url = url+"?partnerId="+partnerId
        // console.log("hwang",url)
        System.fetchGet(url,jwt,(data)=>{
            if ( data.code!==100 ) {
                this.setState({
                    isTipModalOpen: true,
                    modalContent: JSON.stringify(data),
                })
                return
            }

            let modifyPartnerModal = this.state.modifyPartnerModal
            modifyPartnerModal.id = data.partner.id
            modifyPartnerModal.name = data.partner.name
            modifyPartnerModal.statusId = data.partner.status.id
            modifyPartnerModal.statusName = data.partner.status.name

            this.setState({
                // isTipModalOpen: true,
                // modalContent: JSON.stringify(data),
                isModifyPartnerModalOpen:true,
                modifyPartnerModal: modifyPartnerModal
            })
        })
    }


    beforeZhenxuanTemplateFileUpload=(file)=>{
        const isZhenxuanTemplate = (
            file.name==="封面封条.docx" ||
            file.name==="报名表.docx" ||
            file.name==="代销委托函.docx" ||
            file.name==="商务分册.docx" ||
            file.name==="技术分册.docx"
        )
        if (!isZhenxuanTemplate) {
            alert('上传文件名称须为：\n封面封条.docx\n报名表.docx\n代销委托函.docx\n商务分册.docx\n技术分册.docx')
        }
        const isLower50M = file.size *1.0 / 1024 / 1024  < 100;
        if (!isLower50M) {
            alert("上传文件大小应小于50M")
        }
        return isZhenxuanTemplate && isLower50M;
    }


    beforeZhenxuanPriceFileUpload=(file)=>{
        const isZhenxuanPriceFile = (
            file.name==="报价.xlsx"
        )
        if (!isZhenxuanPriceFile) {
            alert('上传文件名称须为：\n报价.xlsx')
        }
        const isLower1M = file.size *1.0 / 1024 / 1024  < 1;
        if (!isLower1M) {
            alert("上传文件大小应小于1M")
        }
        return isZhenxuanPriceFile && isLower1M;
    }


    onChangeDeadlineDate = (date, dateString) => {

        let zhenxuanData = this.state.zhenxuanData

        zhenxuanData.deadlineDate = dateString
        this.setState({
            zhenxuanData: zhenxuanData
        })
    }


    onClickCreateZhenxuanPackageAndDownloadButton = () => {
        const { jwt } = this.props
        this.comQueryButton.focus()
        this.comQueryButton.blur()

        const requiredFileNameList = ["报名表.docx", "代销委托函.docx", "封面封条.docx", "技术分册.docx", "商务分册.docx", "报价.xlsx"]
        let fileNameList = this.state.zhenxuanData.templateFileNameList

        if (this.state.zhenxuanData.priceFileName!==null){
            fileNameList.push(this.state.zhenxuanData.priceFileName)
        }

        for (let i=0; i<requiredFileNameList.length; i++){
            if( fileNameList.indexOf(requiredFileNameList[i])===-1){
                alert("请上传文件："+requiredFileNameList[i])
                return
            }
        }

        let zhenxuanData = this.state.zhenxuanData
        const formData = new FormData();
        formData.append('partnerId', zhenxuanData.partnerId);//名字和后端接口名字对应
        formData.append('projectName', zhenxuanData.projectName);
        formData.append('deadlineDate', zhenxuanData.deadlineDate);
        formData.append('templateFileNameList', zhenxuanData.templateFileNameList);
        if (zhenxuanData.priceFileName!==null){
            formData.append('priceFileName', zhenxuanData.priceFileName);
        }


        let url = System.URL.CREATE_ZHENXUAN_PACKAGE_AND_DOWNLOAD
        System.fetchPost(url,jwt,
            formData,
            (data) => {
                if (data.code !== System.RESULT.SUCCESS.code) {
                    alert(JSON.stringify(data))
                    return
                }

                alert("运行结束")
            }
        )
    }


    onChangeZhenxuanModalProjectnameInput = (e) =>{

        let zhenxuanData = this.state.zhenxuanData

        zhenxuanData.projectName = e.target.value

        this.setState({
            zhenxuanData: zhenxuanData
        })
    }



    

    render() {

        const { isLogin, mobilephone, clientHeight } = this.props

        let partnerListContent = []

        partnerListContent.push(
                <Row align="middle" style={{ width: "100%",color:"white"}}>
                    <Col className="hwBlueBackground" align="middle" flex="200px" style={{}}>
                        编码
                    </Col>
                    <Col className="hwBlueBackground" align="middle" flex="240px" style={{}}>
                        名称
                    </Col>
                    <Col className="hwBlueBackground" align="middle" flex="100px" style={{}}>
                        是否有效
                    </Col>
                    <Col className="hwBlueBackground" align="middle"flex="auto" style={{}}>
                        -
                    </Col>
                    <Col className="hwBlueBackground" align="middle" flex="70px" style={{}}>
                        -
                    </Col>
                    <Col className="hwBlueBackground" align="middle" flex="70px" style={{}}>
                        -
                    </Col>
                    <Col className="hwBlueBackground" align="middle" flex="70px" style={{}}>
                        -
                    </Col>
                </Row>
        )
        partnerListContent.push(
            this.state.partnerList.map((item,index) => (
                <Row align="middle" style={{ width: "100%"}}>
                    <Col align="middle" flex="200px" style={{backgroundColor:index%2===1?"lightgray":"white"}}>
                        {item.id}
                    </Col>
                    <Col align="middle" flex="240px" style={{backgroundColor:index%2===1?"lightgray":"white"}}>
                        {item.name}
                    </Col>
                    <Col align="middle" flex="100px" style={{backgroundColor:index%2===1?"lightgray":"white"}}>
                        {item.status.name}
                    </Col>
                    <Col  align="middle"flex="auto" style={{backgroundColor:index%2===1?"lightgray":"white"}}>
                        -
                    </Col>
                    <Col align="middle" flex="70px" style={{}}>
                        <Button onClick={(partnerId)=>this.onClickModifyPartnerButton(item.id)}>编辑</Button>
                    </Col>
                    <Col align="middle" flex="70px" style={{}}>
                        <Button onClick={(partnerId)=>{this.onClickWillDeletePartner(item.id)}}>删除</Button>
                    </Col>
                    <Col align="middle" flex="70px" style={{}}>
                        <Button onClick={(partnerId,partnerName)=>{this.onClickZhenxuanButton(item.id, item.name)}}>甄选</Button>
                    </Col>
                </Row>
            ))
        )

        let statusDropdownMenuItems = this.state.statusListOfAll.map((item,index) => (
            {label:item.name, key:item.id}
        ))

        return (
            <div className="horizontalCenter" style={this.props.isShow?{}:{display:"none"}}>
                <Modal maskClosable={false} wrap title="提示" width={800} open={this.state.isTipModalOpen} onOk={()=>this.setState({isTipModalOpen:false})} onCancel={()=>this.setState({isTipModalOpen:false})}>
                    {this.state.modalContent}
                </Modal>

                <Modal  maskClosable={false} title="新增合作商" width={800} style={{}} okText="提交" cancelText="取消" open={this.state.isAddPartnerModalOpen} onOk={this.onClickAddPartnerModalSubmitButton} onCancel={()=>this.setState({isAddPartnerModalOpen:false})}>
                    <Space direction="vertical" style={{width   :"100%", height:"400px"}}>

                        <Row align="middle" style={{ width: "100%"}}>
                            <Col flex="10%"></Col>
                            <Col flex="auto">
                                <Space direction="vertical" style={{width:"100%"}}>
                                    <Row align="middle" style={{ width: "100%"}}>
                                        <Col align="right" flex="100px">名称：</Col>
                                        <Col flex="auto">
                                            <Input style={{ width: "80%"}} value={this.state.addPartnerModal.name} onChange={this.onChangeAddPartnerModalNameInput}></Input>
                                        </Col>
                                    </Row>
                                    <Row align="middle" style={{ width: "100%"}}>
                                        <Col align="right" flex="100px">是否有效：</Col>
                                        <Col flex="auto">
                                            <Dropdown trigger={"click"} menu={{items:statusDropdownMenuItems, onClick:this.onClickAddPartnerModalStatusDropdown}}>
                                                <Button>
                                                    <Space>
                                                        {this.state.addPartnerModal.statusNameOfSelect}
                                                        <DownOutlined />
                                                    </Space>
                                                </Button>
                                            </Dropdown>
                                        </Col>
                                    </Row>
                                </Space>
                            </Col>
                        </Row>
                    </Space>
                </Modal>

                <Modal
                    maskClosable={false}
                    title="提示"
                    open={this.state.isConfirmDeleteModalOpen}
                    onOk={(partnerId)=>this.onClickConfirmDeletePartner(this.state.partnerIdOfWillDelete)}
                    onCancel={this.onClickCancelDeletePartner}
                >
                    <p>是否删除</p>
                </Modal>


                <Modal maskClosable={false} title="编辑合作商" width={800} style={{}} okText="提交" cancelText="取消" open={this.state.isModifyPartnerModalOpen} onOk={this.onClickModifyPartnerModalSubmitButton} onCancel={()=>this.setState({isModifyPartnerModalOpen:false})}>
                    <Space direction="vertical" style={{width:"100%", height:"400px"}}>

                        <Row align="middle" style={{ width: "100%"}}>
                            <Col flex="10%"></Col>
                            <Col flex="auto">
                                <Space direction="vertical" style={{width:"100%"}}>
                                    <Row align="middle" style={{ width: "100%"}}>
                                        <Col align="right" flex="100px">编码：</Col>
                                        <Col flex="auto">
                                            <Input disabled="disabled" style={{ width: "80%"}} value={this.state.modifyPartnerModal.id} onChange={{}}></Input>
                                        </Col>
                                    </Row>
                                    <Row align="middle" style={{ width: "100%"}}>
                                        <Col align="right" flex="100px">名称：</Col>
                                        <Col flex="auto">
                                            <Input style={{width: "80%"}} value={this.state.modifyPartnerModal.name} onChange={this.onChangeModifyPartnerModalNameInput}></Input>
                                        </Col>
                                    </Row>
                                    <Row align="middle" style={{ width: "100%"}}>
                                        <Col align="right" flex="100px">是否有效：</Col>
                                        <Col flex="auto">
                                            <Dropdown trigger={"click"} menu={{items:statusDropdownMenuItems, onClick:this.onClickModifyPartnerModalStatusDropdown}}>
                                                <Button>
                                                    <Space>
                                                        {this.state.modifyPartnerModal.statusName}
                                                        <DownOutlined />
                                                    </Space>
                                                </Button>
                                            </Dropdown>
                                        </Col>
                                    </Row>
                                </Space>
                            </Col>
                        </Row>
                    </Space>
                </Modal>


                <Modal maskClosable={false} title="甄选" width={800} okText="提交" cancelText="取消" footer={[
                    <Button type="primary" onClick={this.onClickCreateZhenxuanPackageAndDownloadButton}>
                        生成甄选文件并下载
                    </Button>,
                    <Button onClick={()=>this.setState({isZhenxuanModalOpen:false})}>
                        取消
                    </Button>,
                ]} open={this.state.isZhenxuanModalOpen} onCancel={()=>this.setState({isZhenxuanModalOpen:false})}>
                    <Space direction="vertical" style={{width:"100%", minHeight:"400px", maxHeight:"600px", overflowY:"auto"}}>

                        <Row align="middle" style={{ width: "100%"}}>
                            <Col flex="10%"></Col>
                            <Col flex="auto">
                                <Space direction="vertical" style={{width:"100%"}}>
                                    <Row align="middle" style={{ width: "100%"}}>
                                        <Col align="right" flex="100px">合作商名称：</Col>
                                        <Col flex="auto">
                                            <Input disabled={"disabled"} style={{ width: "80%"}} value={this.state.zhenxuanData.partnerName} onChange={{}}></Input>
                                        </Col>
                                    </Row>
                                    <Row align="middle" style={{ width: "100%"}}>
                                        <Col align="right" flex="100px">项目名称：</Col>
                                        <Col flex="auto">
                                            <Input style={{ width: "80%"}} value={this.state.zhenxuanData.projectName} onChange={this.onChangeZhenxuanModalProjectnameInput}></Input>
                                        </Col>
                                    </Row>
                                    <Row align="middle" style={{ width: "100%"}}>
                                        <Col align="right" flex="100px">截止时间：</Col>
                                        <Col flex="auto">
                                            <DatePicker value={moment(this.state.zhenxuanData.deadlineDate, "YYYY-MM-DD")} onChange={this.onChangeDeadlineDate} />
                                        </Col>
                                    </Row>
                                    <Divider style={{ margin: '4px 0' }} />
                                    <Row align="middle" style={{ width: "100%"}}>
                                        <Col align="right" flex="100px">文件模版：</Col>
                                        <Col flex="auto">
                                            <Upload {...this.state.zhenxuanTemplateFileUploadProps} beforeUpload={this.beforeZhenxuanTemplateFileUpload} >
                                                <Button>
                                                    <UploadOutlined /> 添加
                                                </Button>
                                            </Upload>
                                        </Col>
                                    </Row>
                                    <Divider style={{ margin: '4px 0' }} />
                                    <Row align="middle" style={{ width: "100%"}}>
                                        <Col align="right" flex="100px">报价文件：</Col>
                                        <Col flex="auto">
                                            <Upload {...this.state.zhenxuanPriceFileUploadProps} beforeUpload={this.beforeZhenxuanPriceFileUpload} >
                                                <Button>
                                                    <UploadOutlined /> 添加
                                                </Button>
                                            </Upload>
                                        </Col>
                                    </Row>
                                </Space>
                            </Col>
                        </Row>
                    </Space>
                </Modal>


                <Space align="" direction="vertical" style={{width:"100%"}}>
                    <Row align="right" style={{ width: "100%" }}>
                        <Col flex="100px">
                            <Button type="primary" onClick={this.onClickAddPartnerButton}>新增合作商</Button>
                        </Col>
                        <Col flex="auto"></Col>
                        <Col align="right" flex="300px" style={{}}>
                            {/*<Button type={"primary"} ref={(button)=>this.comQueryButton = button} onClick={()=>{}}>查询</Button>*/}
                            <Input style={{ width: '230px' }} defaultValue="" onChange={this.onChangeSearchPartnerInput}/>
                            <Button type="primary" ref={(button)=>this.comQueryButton = button} onClick={this.onClickSearchPartnerButton}>搜索</Button>
                        </Col>
                    </Row>

                    <Divider orientation="middle"></Divider>
                        {partnerListContent}
                    <Divider/>


                    <Row align="middle" style={{ width: "100%" }}>
                        <Col flex="auto" align="middle" style={{}}>
                            <Pagination showSizeChanger current={this.state.page} pageSize={this.state.pageSize} pageSizeOptions={[2,10,50]} defaultCurrent={1} total={this.state.resultCount} onChange={this.handleChangePageOrSize} />
                        </Col>
                    </Row>

                </Space>
            </div>
        )
    }
}

export default connect(
    //mapStateToProps
    state => {
        return {
            jwt: state.loginInfo.jwt
        }
    },
    //mapDispatchToProps
    {

    }
)(CompanyIctPartnerPage);