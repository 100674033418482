import React from "react"
import "../system/common.css"
import { connect } from "react-redux"
import { Switch, Button, Col, Divider, Input,InputNumber, Row, Select, Space, Typography, Tag, Upload } from 'antd';
import Item from "antd/lib/list/Item";
import * as System from "../system/System"
import { UploadOutlined, PlusOutlined } from '@ant-design/icons';
import {updateAttributeList} from "../store/reactReduxAction"
import pinyin from 'pinyin'
import copy from 'copy-to-clipboard'

const { Text, Link, Title } = Typography;
const { Option, OptGroup } = Select;
const { TextArea } = Input

class ProductModifyPage extends React.Component {

    constructor( props ) {
        super( props )

        const {updateAttributeList, attributeList} = this.props
        const { jwt } = this.props

        this.state = {
            shangPinSelectName: null,
            industryList:[],
            typeListArray:[],
            newAttributeNameInput:null,
            

            attributeIdOfSelect:null,
            attributeNameOfSelect:null,
            priceOfInput:null,

            shangPinInfoList:[],
            shangPinAttributeListArray:[],
            submitData:{
                id:null,
                name:null,
                introduce:null,
                shangPinTypeId:null,
                shangPinTypeName:null,
                iconVideo:{directory:"",video:null},
                iconImageList:{directory:"",imageList:[]},
                detailImageList:{directory:"",imageList:[]},
                attributePriceListOfOrigin:[],
                attributePriceList:[],
                isSale:false,
                sort:null
            },

            iconVideoUploadProps: {
                action: System.URL.UPLOAD_VIDEO,
                withCredentials:true,
                onChange({ file, fileList }) {
                },
                customRequest: info => {

                    const formData = new FormData();
                    formData.append('file', info.file);//名字和后端接口名字对应

                    let url = System.URL.UPLOAD_VIDEO
                    System.fetchPost(url,jwt,
                        formData,
                        (data) => {
                            if (data.code !== System.RESULT.SUCCESS.code) {
                                alert(JSON.stringify(data))
                                return
                            }

                            let {iconVideoUploadProps, submitData} = this.state
                            submitData.iconVideo.video = data.name
                            iconVideoUploadProps.fileList = []
                            iconVideoUploadProps.fileList.push({
                                uid: data.uid,
                                name: data.name,
                                status: 'done',
                                response: '',
                                url: data.url,
                            })
                            this.setState({
                                iconVideoUploadProps:iconVideoUploadProps,
                                submitData: submitData
                            })
                        }
                    )
                },
                onRemove: file => {//删除视频调用

                    let {iconVideoUploadProps, submitData} = this.state
                    let index = iconVideoUploadProps.fileList.indexOf(file);
                    iconVideoUploadProps.fileList.splice(index, 1)

                    submitData.iconVideo.video = null
                    this.setState({
                        iconVideoUploadProps: iconVideoUploadProps,
                        submitData:submitData
                    })
                },
                fileList: [],
            },
            iconImageUploadProps: {
                action: System.URL.UPLOAD_IMAGE,
                withCredentials:true,
                onChange({ file, fileList }) {
                },
                customRequest: info => {

                    const formData = new FormData();
                    formData.append('file', info.file);//名字和后端接口名字对应

                    let url = System.URL.UPLOAD_IMAGE
                    System.fetchPost(url,jwt,
                        formData,
                        (data) => {
                            if (data.code !== System.RESULT.SUCCESS.code) {
                                alert(JSON.stringify(data))
                                return
                            }

                            let {iconImageUploadProps, submitData} = this.state
                            submitData.iconImageList.imageList.push(data.name)
                            iconImageUploadProps.fileList.push({
                                uid: data.uid,
                                name: data.name,
                                status: 'done',
                                response: '',
                                url: data.url,
                            })
                            this.setState({
                                iconImageUploadProps:iconImageUploadProps,
                                submitData: submitData
                            })
                        }
                    )
                },
                onRemove: file => {//删除图片调用

                    let {iconImageUploadProps, submitData} = this.state
                    let index = iconImageUploadProps.fileList.indexOf(file);
                    iconImageUploadProps.fileList.splice(index, 1)

                    index = submitData.iconImageList.imageList.indexOf(file.name);
                    submitData.iconImageList.imageList.splice(index, 1)
                    this.setState({
                        iconImageUploadProps: iconImageUploadProps,
                        submitData:submitData
                    })
                },
                fileList: [],
            },

            detailImageUploadProps: {
                action: System.URL.UPLOAD_IMAGE,
                withCredentials:true,
                onChange({ file, fileList }) {
                },
                customRequest: info => {

                    const formData = new FormData();
                    formData.append('file', info.file);//名字和后端接口名字对应

                    let url = System.URL.UPLOAD_IMAGE
                    System.fetchPost(url, jwt,
                        formData,
                        (data) => {
                            if (data.code !== System.RESULT.SUCCESS.code) {
                                alert(JSON.stringify(data))
                                return
                            }

                            let {detailImageUploadProps, submitData} = this.state
                            submitData.detailImageList.imageList.push(data.name)
                            detailImageUploadProps.fileList.push({
                                uid: data.uid,
                                name: data.name,
                                status: 'done',
                                response: '',
                                url: data.url,
                            })
                            this.setState({
                                detailImageUploadProps:detailImageUploadProps,
                                submitData:submitData
                            })
                        }
                    )
                },
                onRemove: file => {//删除图片调用

                    let {detailImageUploadProps,submitData} = this.state
                    let index = detailImageUploadProps.fileList.indexOf(file);
                    detailImageUploadProps.fileList.splice(index, 1)

                    index = submitData.detailImageList.imageList.indexOf(file.name);
                    submitData.detailImageList.imageList.splice(index, 1)
                    this.setState({
                        detailImageUploadProps: detailImageUploadProps,
                        submitData:submitData
                    })
                },
                fileList: [],
            }
        }

        // System.fetchGet(System.URL.LOGIN+'?mobilephone=13829900000&&appKey=appKey',(data)=>{

        //     if ( data.code!==System.RESULT.SUCCESS.code ) {
        //         alert(JSON.stringify(data))
        //         return
        //     }

            // 读取全部行业类型
            let url = System.URL.FIND_SHANGPIN_MODULE_INDUSTRY_TYPE_LIST
            System.fetchGet(url, jwt , (data) => {

                if (data.code !== System.RESULT.SUCCESS.code) {
                    alert(System.URL.FIND_SHANGPIN_MODULE_INDUSTRY_TYPE_LIST+JSON.stringify(data))
                    return
                }

                this.setState({
                    industryList: data.shangPinIndustryList,
                    typeListArray: data.shangPinTypeListArray
                })

            })

            // 读取全部属性
            url = System.URL.READ_ATTRIBUTE_LIST_OF_ALL
            System.fetchGet(url, jwt, (data) => {

                if (data.code !== System.RESULT.SUCCESS.code) {
                    alert(JSON.stringify(data))
                    return
                }

                updateAttributeList(data.attributeList)

                // this.handleShangPinSearch('梅')

            })
            
        // })
        
    }

    handleShangPinSearch = value => {

        let {shangPinSelectName} = this.state
        const { jwt } = this.props

        if( value===null || value==="" ){
            this.setState({
                shangPinInfoList: [],
                shangPinAttributeListArray:[]
            })
            return
        }

        let url = System.URL.FIND_PRODUCT_LIST_BY_PRODUCT_NAME+"?name="+value
        System.fetchGet(url, jwt, (data) => {

            if (data.code !== System.RESULT.SUCCESS.code) {
                alert(System.URL.FIND_PRODUCT_LIST_BY_PRODUCT_NAME+JSON.stringify(data))
                return
            }

            // shangPinListOfSearchResult.push({id:"shangPinInfo_tuDou",name:"土豆"})
            this.setState({
                // shangPinListOfSearchResult: shangPinListOfSearchResult,
                shangPinInfoList: data["shangPinInfoList"],
                shangPinAttributeListArray: data["shangPinAttributeListArray"]
            })

        })

        
       
    }

    handleShangPinChange = (value,option) => {

        const {submitData,shangPinInfoList,shangPinAttributeListArray, iconVideoUploadProps, iconImageUploadProps, detailImageUploadProps} = this.state

        submitData.id = option.key
        submitData.name = option.value
        submitData.isSale = false
        submitData.attributePriceListOfOrigin = []
        submitData.sort = 1

        console.log(option.value)

        shangPinInfoList.map(item => {
            if (item.id===option.key) {
                submitData.shangPinTypeId=item.shangPinType.id
                submitData.shangPinTypeName=item.shangPinType.name
                submitData.introduce = item.introduce
                submitData.sort = item.sort
            }
        })


        shangPinAttributeListArray.map(shangPinAttributeList => {
            shangPinAttributeList.map(item => {
                if (item.shangPinInfo.id===option.key ) {
                    if (item.status.id==='status_valid') {
                        submitData.isSale = true
                    }

                    submitData.attributePriceListOfOrigin.push({
                        id:item.id,
                        attribute1:item.attribute1,
                        name:item.attribute1.name,
                        price:item.price,
                        isSale:item.status.id==='status_valid'?true:false
                    })

                    

                    const iconVideoJSON = JSON.parse(item.iconVideoPath)
                    const iconImageListJSON = JSON.parse(item.iconPath)
                    const detailImageListJSON = JSON.parse(item.detailImagePath)
                    
                    if( iconVideoJSON !== null && ("video" in iconVideoJSON) ){
                        submitData.iconVideo = iconVideoJSON
                        submitData.iconVideo.video = iconVideoJSON.video

                        iconVideoUploadProps.fileList = []
                        iconVideoUploadProps.fileList.push({
                            uid: iconVideoJSON.video,
                            name: iconVideoJSON.video,
                            status: 'done',
                            response: '',
                            url: System.URL.VIDEO_PREFIX+iconVideoJSON.video,
                        })
                    }else {
                        submitData.iconVideo.video = null
                    }

                    submitData.iconImageList.imageList = iconImageListJSON.imageList
                    submitData.detailImageList.imageList = detailImageListJSON.imageList

                    iconImageUploadProps.fileList = []
                    iconImageListJSON.imageList.map(item => {
                        iconImageUploadProps.fileList.push({
                            uid: item,
                            name: item,
                            status: 'done',
                            response: '',
                            url: System.URL.IMAGE_PREFIX+item,
                        })
                    })

                    detailImageUploadProps.fileList = []
                    detailImageListJSON.imageList.map(item => {
                        detailImageUploadProps.fileList.push({
                            uid: item,
                            name: item,
                            status: 'done',
                            response: '',
                            url: System.URL.IMAGE_PREFIX+item,
                        })
                    })
                    
                }
            })
        })

        submitData.attributePriceListOfOrigin.sort(System.arrayRank("name",false))



        this.setState({
            shangPinSelectName: option.value,
            submitData:submitData,
            iconImageUploadProps,
            detailImageUploadProps
        })
    }

    handleTypeSelect = (value, option) => {

        let {submitData}  = this.state
        submitData.shangPinTypeId = option.key
        submitData.shangPinTypeName = option.value
        

        this.setState({
            submitData:submitData
        })
    }

    handleIntroduceChange = (e) => {
        let {submitData}  = this.state
        submitData.introduce = e.target.value

        this.setState({
            submitData:submitData
        })
    }

    handleAttributeSelect = (value, option) => {

        this.setState({
            attributeIdOfSelect :option.key,
            attributeNameOfSelect: option.value
        })
    }

    handleNewAttributeNameInputChange = event => {
        this.setState({
            newAttributeNameInput: event.target.value,
        })
    }

    addAttribute = () => {
        const { jwt } = this.props
        const { newAttributeNameInput } = this.state;
        const { updateAttributeList, attributeList } = this.props

        if (newAttributeNameInput === null || newAttributeNameInput === '') {
            return
        }

        let attributePinYin = pinyin(newAttributeNameInput, { style: pinyin.STYLE_TONE2 }).toString()
        attributePinYin = attributePinYin.match(/[a-zA-Z0-9]/g).join("")
        const id = "attribute_guige1_" + attributePinYin
        const parameterString = "id=" + id + "&" + "name=" + encodeURIComponent(newAttributeNameInput)

        let url = System.URL.ADD_ATTRIBUTE + "?" + parameterString
        System.fetchGet(url, jwt,  (data) => {

            if (data.code !== System.RESULT.SUCCESS.code) {
                alert(JSON.stringify(data))
                return
            }

            let url = System.URL.READ_ATTRIBUTE_LIST_OF_ALL
            System.fetchGet(url, jwt, (data) => {

                if (data.code !== System.RESULT.SUCCESS.code) {
                    alert(JSON.stringify(data))
                    return
                }

                updateAttributeList(data.attributeList)

            })

        })

        this.setState({

        });
    };

    handleShangPinAttributeSearch = value => {

        const { jwt } = this.props

        let {shangPinSelectName} = this.state
        const {updateAttributeList, attributeList} = this.props

        if( value===null || value==="" ){
            let url = System.URL.READ_ATTRIBUTE_LIST_OF_ALL
            System.fetchGet(url, jwt, (data) => {

                if (data.code !== System.RESULT.SUCCESS.code) {
                    alert(JSON.stringify(data))
                    return
                }

                updateAttributeList(data.attributeList)
            })
        }else{
            let url = System.URL.READ_ATTRIBUTE_LIST_BY_KEYWORD+"?keyword="+value
            System.fetchGet(url, jwt, (data) => {

                if (data.code !== System.RESULT.SUCCESS.code) {
                    alert(JSON.stringify(data))
                    return
                }

                updateAttributeList(data.attributeList)
            })
        }
       
    }

    handlePriceChange = (value) => {

        this.setState({
            priceOfInput:value*100
        })
    }

    handlePriceOfOriginChange = (id, value) => {

        let {submitData}  = this.state

        const index = submitData.attributePriceListOfOrigin.map(item=>item.id).indexOf(id)
        submitData.attributePriceListOfOrigin[index].price = value*100

        this.setState({
            submitData:submitData
        })
    }

    handleStatusOfOriginChange = (id, checked, event) => {

        let {submitData}  = this.state

        const index = submitData.attributePriceListOfOrigin.map(item=>item.id).indexOf(id)
        submitData.attributePriceListOfOrigin[index].isSale = checked

        this.setState(
            {
            submitData:submitData
            },
            ()=>{

                let {submitData}  = this.state
                submitData.isSale = false

                submitData.attributePriceList.map(item=>{
                    if (item.isSale === true) {
                        submitData.isSale = true
                    }
                })
        
                submitData.attributePriceListOfOrigin.map(item=>{
                    if (item.isSale === true) {
                        submitData.isSale = true
                    }
                })

                this.setState({
                    submitData:submitData
                })
            }
        )
    }

    handleStatusOfNewChange = (id, checked, event) => {

        let {submitData}  = this.state

        const index = submitData.attributePriceList.map(item=>item.id).indexOf(id)
        submitData.attributePriceList[index].isSale = checked

        

        this.setState(
            {
            submitData:submitData
            },
            ()=>{

                let {submitData}  = this.state
                submitData.isSale = false

                submitData.attributePriceList.map(item=>{
                    if (item.isSale === true) {
                        submitData.isSale = true
                    }
                })
        
                submitData.attributePriceListOfOrigin.map(item=>{
                    if (item.isSale === true) {
                        submitData.isSale = true
                    }
                })

                this.setState({
                    submitData:submitData
                })
            }
        )

        



        
    }

    handleAddAttributePrice = () => {

        let {submitData, attributeIdOfSelect,attributeNameOfSelect, priceOfInput}  = this.state
        let isExists = false

        if (attributeIdOfSelect===null || attributeNameOfSelect===null) {
            alert("请选择属性")
            return
        }
        if (priceOfInput===null) {
            alert("请输入价格")
            return
        }

        submitData.attributePriceList.map(item=>{
            if(item.id===attributeIdOfSelect){
                isExists = true
            }
        })

        submitData.attributePriceListOfOrigin.map(item=>{
            if(item.attribute1.id===attributeIdOfSelect){
                isExists = true
            }
        })

        if (isExists) {
            alert("已存在该属性")
            return
        }

        submitData.attributePriceList.push({ id: attributeIdOfSelect, name:attributeNameOfSelect, price: priceOfInput, isSale: false })

        this.setState({
            submitData:submitData,
            attributeIdOfSelect:null,
            attributeNameOfSelect:null,
            priceOfInput:null
        })
    }

    handleDeleteAttributePrice = (key) => {
        let {submitData}  = this.state

        const index = submitData.attributePriceList.map(item=>item.id).indexOf(key)
        submitData.attributePriceList.splice(index,1)

        this.setState({
            submitData:submitData
        })

    }

    handleIsSaleChange=(checked, event)=>{

        const {submitData} = this.state

        submitData.isSale = checked

        submitData.attributePriceList.map(item=>{
            item.isSale = checked
        })

        submitData.attributePriceListOfOrigin.map(item=>{
            item.isSale = checked
        })

        this.setState({
            submitData:submitData
        })
    }


    handleSortChange = (value) =>{

        const {submitData} = this.state

        submitData.sort = value

        this.setState({
            submitData:submitData
        })
    }

    

    handleSubmitButton = () => {
        const { jwt } = this.props

        let {submitData, iconVideoUploadProps, iconImageUploadProps, detailImageUploadProps} = this.state
        // if (submitData.id===null || submitData.id==="" || submitData.name===null || submitData.name==="") {
        //     alert("请输入商品名称")
        //     return
        // }

        console.log(submitData)
        copy(JSON.stringify(submitData))

        if (submitData.shangPinTypeId===null || submitData.shangPinTypeId==="" || submitData.shangPinTypeName===null || submitData.shangPinTypeName==="") {
            alert("请选择商品归属")
            return
        }

        if (submitData.introduce===null || submitData.introduce==="") {
            alert("请输入商品介绍")
            return
        }

        // if (submitData.attributePriceList.length===0) {
        //     alert("请添加商品属性和价格")
        //     return
        // }

        if (submitData.iconImageList.imageList.length===0) {
            alert("请添加商品展示图片")
            return
        }

        if (submitData.detailImageList.imageList.length===0) {
            alert("请添加商品详情图片")
            return
        }

        if (submitData.sort===null || submitData.sort==="") {
            alert("请输入排序")
            return
        }



        


        let url = System.URL.MODIFY_PRODUCT
        System.fetchPost(url,jwt,
            JSON.stringify(submitData),
            (data) => {
                if (data.code !== System.RESULT.SUCCESS.code) {
                    alert(JSON.stringify(data))
                    return
                }

                alert("成功修改商品")

                submitData={
                    id:null,
                    name:null,
                    introduce:null,
                    shangPinTypeId:null,
                    shangPinTypeName:null,
                    iconVideo:{directory:"",video:null},
                    iconImageList:{directory:"",imageList:[]},
                    detailImageList:{directory:"",imageList:[]},
                    attributePriceListOfOrigin:[],
                    attributePriceList:[],
                    isSale:false
                }

                iconVideoUploadProps.fileList=[]
                iconImageUploadProps.fileList=[]
                detailImageUploadProps.fileList=[]

                this.setState({
                    submitData:submitData,
                    shangPinInfoList:[],
                    shangPinAttributeListArray:[],
                    iconVideoUploadProps:iconVideoUploadProps,
                    iconImageUploadProps:iconImageUploadProps,
                    detailImageUploadProps:detailImageUploadProps,
                    shangPinSelectName: null
                })
            }

        )

    }

    render() {

        const { isLogin, mobilephone, clientHeight,attributeList } = this.props
        const { industryList, typeListArray,submitData,newAttributeNameInput, attributeIdOfSelect,attributeNameOfSelect,priceOfInput } = this.state;

        const shangPinSearchResultContent = this.state.shangPinInfoList.map(item => <Option key={item.id} value={item.name}>{item.name}</Option>)


        let industryTypeListContent = []
        for (let i = 0; i < industryList.length; i++) {

            let typeListContent = []
            
            typeListContent.push(typeListArray[i].map(item=>(
                <Option key={item.id} value={item.name}>{item.name}</Option>
            )))

            industryTypeListContent.push(
                <OptGroup key={industryList[i].id} label={industryList[i].name}>{typeListContent}</OptGroup>
            )
            
        }




        let attributeListContent = []
        attributeListContent.push(attributeList.map(item=>(
            <Option key={item.id} value={item.name}>{item.name}</Option>
        )))

        let attributePriceContent = []
        attributePriceContent.push(
            <Row key="attributePriceTitle" align="middle" style={{ width: "94%"}}>
                <Col className="horizontalCenter" flex="1"><Text>属性名称</Text></Col>
                <Col className="horizontalCenter" flex="1"><Text>输入</Text></Col>
            </Row>
        )
        attributePriceContent.push(
            <Row key="attributePriceInput" align="middle" style={{ width: "94"}}>
                <Col className="horizontalCenter" flex="4" style={{  }}>
                    <Select showSearch
                        placeholder="选择属性"
                        style={{ width: "100%" }}
                        onChange={this.handleAttributeSelect}
                        onSearch={this.handleShangPinAttributeSearch}
                        value={attributeNameOfSelect}
                        dropdownRender={menu => (
                            <div>
                              {menu}
                              <Divider style={{ margin: '4px 0' }} />
                              <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                <Input style={{ flex: 'auto' }} value={newAttributeNameInput} onChange={this.handleNewAttributeNameInputChange} />
                                <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }} onClick={this.addAttribute}>
                                  <PlusOutlined /> 添加
                                </a>
                              </div>
                            </div>
                          )}
                    >
                        {attributeListContent}
                    </Select>
                </Col>
                <Col className="horizontalCenter" flex="2" style={{ }}>
                    <InputNumber precision={2} min={0} max={99999} formatter={value => `¥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} placeholder="输入" onChange={this.handlePriceChange} style={{width:"100%"}} value={priceOfInput===null?priceOfInput:priceOfInput/100}/>
                </Col>
                <Col className="horizontalCenter" flex="1"><Button type="primary" onClick={this.handleAddAttributePrice}>添加</Button></Col>
            </Row>
        )
        attributePriceContent.push(submitData.attributePriceListOfOrigin.map(item=>(

            <Row key={item.id} align="middle" style={{ width: "94"}}>
                <Col className="horizontalCenter" flex="4" style={{  }}>
                    <Input disabled value={item.name} onChange={this.handleNameChange} />
                </Col>
                <Col className="horizontalCenter" flex="2" style={{ }}>
                    <InputNumber precision={2} min={0} max={99999} formatter={value => `¥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} placeholder="输入" onChange={(value)=>this.handlePriceOfOriginChange(item.id, value)} style={{width:"100%"}} value={item.price*1.0/100}/>
                </Col>
                <Col className="horizontalCenter" flex="1">
                    <Switch checkedChildren="在架" unCheckedChildren="下架" defaultChecked onChange={(checked, event)=>this.handleStatusOfOriginChange(item.id, checked, event)} checked={item.isSale} />

                    {/* <Button type="primary" style={{ visibility:"hidden" }}>修改</Button> */}
                </Col>
            </Row>

        )))
        attributePriceContent.push(submitData.attributePriceList.map(item=>(
            <Tag closable key={item.id} style={{ width: "100%"}} onClose={ ()=>{this.handleDeleteAttributePrice(item.id) } }>
                <Row align="middle" style={{ width: "95%",float:"left" }}>
                    <Col className="horizontalCenter" flex="4">
                        <Input disabled value={item.name} />
                    </Col>
                    <Col className="horizontalCenter" flex="2">
                        <InputNumber disabled precision={2} formatter={value => `¥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} style={{width:"100%"}} value={item.price*1.0/100}/>
                    </Col>
                    <Col className="horizontalCenter" flex="1">
                        <Switch checkedChildren="在架" unCheckedChildren="下架" defaultChecked onChange={(checked, event)=>this.handleStatusOfNewChange(item.id, checked, event)} checked={item.isSale} />
                    </Col>
                </Row>
            </Tag>
        )))


        return (
            <div className="horizontalCenter" style={this.props.isShow?{}:{display:"none"}}>
                <Space align="" direction="vertical" style={{width:"500px"}}>
                
                    

                    <Row align="middle" style={{ width: "100%" }}>
                        <Col className="horizontalCenter" flex="100px" style={{}}>搜索：</Col>
                        <Col flex="auto" style={{}}>
                            <Select
                                showSearch
                                value={this.state.shangPinSelectName}
                                placeholder="输入产品名称"
                                style={{ width: "100%" }}
                                defaultActiveFirstOption={false}
                                showArrow={false}
                                filterOption={false}
                                onSearch={this.handleShangPinSearch}
                                onChange={this.handleShangPinChange}
                                notFoundContent={null}
                            >
                                {shangPinSearchResultContent}
                            </Select>
                        </Col>
                    </Row>

                    <Divider />

                    <Row align="middle" style={{ width: "100%" }}>
                        <Col className="horizontalCenter" flex="100px" style={{}}>编码：</Col>
                        <Col flex="auto" style={{}}>
                            <Input disabled value={submitData.id} onChange={this.handleNameChange} />
                        </Col>
                    </Row>

                    <Row align="middle" style={{ width: "100%" }}>
                        <Col className="horizontalCenter" flex="100px" style={{}}>名称：</Col>
                        <Col flex="auto" style={{}}>
                            <Input disabled value={submitData.name} onChange={this.handleNameChange} />
                        </Col>
                    </Row>

                    <Row align="middle" style={{ width: "100%" }}>
                        <Col className="horizontalCenter" flex="100px" style={{}}>归属：</Col>
                        <Col flex="auto" style={{}}>
                            <Select placeholder="选择归属" style={{ width: "100%" }} onChange={this.handleTypeSelect} value={submitData.shangPinTypeName}>
                                {industryTypeListContent}
                            </Select>
                        </Col>
                    </Row>

                    <Row align="middle" style={{ width: "100%" }}>
                        <Col className="horizontalCenter" flex="100px" style={{}}>描述：</Col>
                        <Col flex="auto">
                            <TextArea
                                value={this.state.submitData.introduce}
                                onChange={this.handleIntroduceChange}
                                placeholder="输入描述"
                                autoSize={{ minRows: 5}}
                            />
                        </Col>
                    </Row>

                    <Row align="middle" style={{ width: "100%" }}>
                        <Col className="horizontalCenter" flex="100px" style={{}}>属性：</Col>
                        <Col flex="auto">
                            <div style={{width:"400px"}}>
                                {attributePriceContent}
                            </div>
                        </Col>
                    </Row>

                    <Row align="middle" style={{ width: "100%" }}>
                        <Col className="horizontalCenter" flex="100px" style={{}}>批量上下架：</Col>
                        <Col flex="auto">
                            <Switch checkedChildren="在架" unCheckedChildren="下架" defaultChecked onChange={this.handleIsSaleChange} checked={submitData.isSale} />
                        </Col>
                    </Row>

                    <Row align="middle" style={{ width: "100%" }}>
                        <Col className="horizontalCenter" flex="100px" style={{}}>视频：</Col>
                        <Col flex="auto">
                            <div key={Math.random()}>
                                <Upload {...this.state.iconVideoUploadProps} beforeUpload={System.beforeVideoUpload}>
                                    <Button>
                                        <UploadOutlined /> 添加
                                 </Button>
                                </Upload>
                            </div>
                        </Col>
                    </Row>

                    <Row align="middle" style={{ width: "100%" }}>
                        <Col className="horizontalCenter" flex="100px" style={{}}>展示：</Col>
                        <Col flex="auto">
                            <div key={Math.random()}>
                                <Upload {...this.state.iconImageUploadProps} beforeUpload={System.beforeIconUpload}>
                                    <Button>
                                        <UploadOutlined /> 添加
                                 </Button>
                                </Upload>
                            </div>
                        </Col>
                    </Row>

                    <Row align="middle" style={{ width: "100%" }}>
                        <Col className="horizontalCenter" flex="100px" style={{}}>详情：</Col>
                        <Col flex="auto">
                            <div key={Math.random()}>
                                <Upload {...this.state.detailImageUploadProps} beforeUpload={System.beforeDetailUpload}>
                                    <Button>
                                        <UploadOutlined /> 添加
                                 </Button>
                                </Upload>
                            </div>
                        </Col>
                    </Row>

                    <Row align="middle" style={{ width: "100%" }}>
                        <Col className="horizontalCenter" flex="100px" style={{}}>排序：</Col>
                        <Col flex="auto" style={{}}>
                            <InputNumber precision={0} min={1} max={99999} placeholder="输入" onChange={this.handleSortChange} style={{width:"100%"}} value={submitData.sort}/>
                        </Col>
                    </Row>

                    <Space className="space_around" direction="horizontal" style={{width:"100%", marginTop:"10px"}}>
                        <Button type="primary" onClick={this.handleSubmitButton}>提交</Button>
                    </Space>

                </Space>
            </div>
        )
    }
}

export default connect(
    //mapStateToProps
    state => {
        return {
            attributeList: state.attributeList,
            jwt: state.loginInfo.jwt
        }
    },
    //mapDispatchToProps
    {
        updateAttributeList
    }
)(ProductModifyPage);