import { Button, Col, Divider, Input,InputNumber, Row, Select, Space, Typography, Tag, Upload } from 'antd';
import React from "react";
import { connect } from "react-redux";
import "../system/common.css";
import * as System from "../system/System"
import { UploadOutlined, PlusOutlined } from '@ant-design/icons';
import {updateAttributeList} from "../store/reactReduxAction"
import pinyin from 'pinyin'

const { Text, Link, Title } = Typography;
const { Option, OptGroup } = Select;
const { TextArea } = Input;

// const props = {
//     action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
//     onChange({ file, fileList }) {
//       if (file.status !== 'uploading') {
//         console.log(file, fileList);
//       }
//     },
//     defaultFileList: [
//       {
//         uid: '1',
//         name: 'xxx.png',
//         status: 'done',
//         response: 'Server Error 500', // custom error message to show
//         url: 'http://www.baidu.com/xxx.png',
//       },
//     ],
//   };


class ProductAddPage extends React.Component {

    // customRequest = (info) => {
    //     const formData = new FormData();
    //     formData.append('file', info.file);//名字和后端接口名字对应

    //     System.fetchPost(
    //         System.URL.UPLOAD_IMAGE,
    //         formData,
    //         (data) => {
    //             console.log(data)
    //             if (data.code !== 100) {
    //                 alert(JSON.stringify(data))
    //                 return
    //             }
    //         }
    //     )
    // }

    constructor( props ) {
        super( props )

        const {updateAttributeList} = props
        const { jwt } = this.props

        // System.fetchGet(System.URL.LOGIN+'?mobilephone=13829900000&appKey=appKey',(data)=>{

        //     if ( data.code!==System.RESULT.SUCCESS.code ) {
        //         alert(JSON.stringify(data))
        //         return
        //     }

        // 读取全部行业类型
        let url = System.URL.FIND_SHANGPIN_MODULE_INDUSTRY_TYPE_LIST
        System.fetchGet(url, jwt,(data) => {

            if (data.code !== System.RESULT.SUCCESS.code) {
                alert(System.URL.FIND_SHANGPIN_MODULE_INDUSTRY_TYPE_LIST+JSON.stringify(data))
                return
            }

            this.setState({
                industryList: data.shangPinIndustryList,
                typeListArray: data.shangPinTypeListArray
            })

        })

        // 读取全部属性
        url = System.URL.READ_ATTRIBUTE_LIST_OF_ALL
        System.fetchGet(url, jwt, (data) => {

            if (data.code !== System.RESULT.SUCCESS.code) {
                alert(JSON.stringify(data))
                return
            }

            updateAttributeList(data.attributeList)

        })
            
        // })

        

        this.state = {
            // industryMap: [{ key: "key1", value: "value1" }, { key: "key2", value: "value2" }],
            // name: '',

            industryList: [],
            typeListArray: [],
            attributeIdOfSelect:null,
            attributeNameOfSelect:null,
            newAttributeNameInput:null,
            priceOfInput:null,
            submitData:{
                id:null,
                name:null,
                typeId:null,
                typeName:null,
                introduce:null,
                attributePriceList:[],
                iconVideo:{directory:"",video:null},
                iconImageList:{directory:"",imageList:[]},
                detailImageList:{directory:"",imageList:[]},
            },
            // submitData:{
            //     id:"shangPinInfo_ming2cheng1_20200824171002193",
            //     name:"名称",
            //     typeId:"shangPinType_shiCai_fuShi_peiCai",
            //     typeName:"配菜",
            //     introduce:"介绍",
            //     attributePriceList:[{attributeId: "attribute_guige1_xiang", attributeName: "箱", price: 2}],
            //     iconImageList:{directory:"",imageList:[]},
            //     detailImageList:{directory:"",imageList:[]},
            // },
            iconVideoUploadProps: {
                action: System.URL.UPLOAD_VIDEO,
                withCredentials:true,
                onChange({ file, fileList }) {
                },
                customRequest: info => {
                    const { jwt } = this.props

                    const formData = new FormData();
                    formData.append('file', info.file);//名字和后端接口名字对应

                    let url = System.URL.UPLOAD_VIDEO
                    System.fetchPost(url,jwt,
                        formData,
                        (data) => {
                            if (data.code !== System.RESULT.SUCCESS.code) {
                                alert(JSON.stringify(data))
                                return
                            }

                            let {iconVideoUploadProps, submitData} = this.state
                            submitData.iconVideo.video = data.name
                            iconVideoUploadProps.fileList = []
                            iconVideoUploadProps.fileList.push({
                                uid: data.uid,
                                name: data.name,
                                status: 'done',
                                response: '',
                                url: data.url,
                            })
                            this.setState({
                                iconVideoUploadProps:iconVideoUploadProps,
                                submitData: submitData
                            })
                        }
                    )
                },
                onRemove: file => {//删除视频调用

                    let {iconVideoUploadProps, submitData} = this.state
                    let index = iconVideoUploadProps.fileList.indexOf(file);
                    iconVideoUploadProps.fileList.splice(index, 1)

                    submitData.iconVideo.video = ""
                    this.setState({
                        iconVideoUploadProps: iconVideoUploadProps,
                        submitData:submitData
                    })
                },
                fileList: [],
            },
            iconImageUploadProps: {
                action: System.URL.UPLOAD_IMAGE,
                withCredentials:true,
                onChange({ file, fileList }) {
                },
                customRequest: info => {
                    const { jwt } = this.props

                    const formData = new FormData();
                    formData.append('file', info.file);//名字和后端接口名字对应

                    let url = System.URL.UPLOAD_IMAGE
                    System.fetchPost(url,jwt,
                        formData,
                        (data) => {
                            if (data.code !== System.RESULT.SUCCESS.code) {
                                alert(JSON.stringify(data))
                                return
                            }

                            let {iconImageUploadProps, submitData} = this.state
                            submitData.iconImageList.imageList.push(data.name)
                            iconImageUploadProps.fileList.push({
                                uid: data.uid,
                                name: data.name,
                                status: 'done',
                                response: '',
                                url: data.url,
                            })
                            this.setState({
                                iconImageUploadProps:iconImageUploadProps,
                                submitData: submitData
                            })
                        }
                    )
                },
                onRemove: file => {//删除图片调用

                    let {iconImageUploadProps, submitData} = this.state
                    let index = iconImageUploadProps.fileList.indexOf(file);
                    // iconImageUploadProps.fileList = iconImageUploadProps.fileList.slice();
                    iconImageUploadProps.fileList.splice(index, 1)

                    index = submitData.iconImageList.imageList.indexOf(file.name);
                    submitData.iconImageList.imageList.splice(index, 1)
                    this.setState({
                        iconImageUploadProps: iconImageUploadProps,
                        submitData:submitData
                    })
                },
                fileList: [],
            },

            detailImageUploadProps: {
                action: System.URL.UPLOAD_IMAGE,
                withCredentials:true,
                onChange({ file, fileList }) {
                },
                customRequest: info => {
                    const { jwt } = this.props

                    const formData = new FormData();
                    formData.append('file', info.file);//名字和后端接口名字对应

                    let url = System.URL.UPLOAD_IMAGE
                    System.fetchPost(url,jwt,
                        formData,
                        (data) => {
                            if (data.code !== System.RESULT.SUCCESS.code) {
                                alert(JSON.stringify(data))
                                return
                            }

                            let {detailImageUploadProps, submitData} = this.state
                            submitData.detailImageList.imageList.push(data.name)
                            detailImageUploadProps.fileList.push({
                                uid: data.uid,
                                name: data.name,
                                status: 'done',
                                response: '',
                                url: data.url,
                            })
                            this.setState({
                                detailImageUploadProps:detailImageUploadProps,
                                submitData:submitData
                            })
                        }
                    )
                },
                onRemove: file => {//删除图片调用

                    let {detailImageUploadProps,submitData} = this.state
                    let index = detailImageUploadProps.fileList.indexOf(file);
                    // detailImageUploadProps.fileList = detailImageUploadProps.fileList.slice();
                    detailImageUploadProps.fileList.splice(index, 1)

                    index = submitData.detailImageList.imageList.indexOf(file.name);
                    submitData.detailImageList.imageList.splice(index, 1)
                    this.setState({
                        detailImageUploadProps: detailImageUploadProps,
                        submitData:submitData
                    })
                },
                fileList: [],
            }
        };
        

    }



    handleTypeSelect = (value, option) => {

        let {submitData}  = this.state
        submitData.typeId = option.key
        submitData.typeName = option.value
        

        this.setState({
            submitData:submitData
        })
    }

    handleAttributeSelect = (value, option) => {

        this.setState({
            attributeIdOfSelect :option.key,
            attributeNameOfSelect: option.value
        })
    }

    handleNewAttributeNameInputChange = event => {
        this.setState({
            newAttributeNameInput: event.target.value,
        })
    }

    addAttribute = () => {
        const { jwt } = this.props

        const { newAttributeNameInput } = this.state;
        const { updateAttributeList, attributeList } = this.props;

        if (newAttributeNameInput === null || newAttributeNameInput === '') {
            return
        }

        let attributePinYin = pinyin(newAttributeNameInput, { style: pinyin.STYLE_TONE2 }).toString()
        attributePinYin = attributePinYin.match(/[a-zA-Z0-9]/g).join("")
        const id = "attribute_guige1_" + attributePinYin
        const parameterString = "id=" + id + "&" + "name=" + encodeURIComponent(newAttributeNameInput)

        let url = System.URL.ADD_ATTRIBUTE + "?" + parameterString
        System.fetchGet(url, jwt, (data) => {

            if (data.code !== System.RESULT.SUCCESS.code) {
                alert(JSON.stringify(data))
                return
            }

            let url = System.URL.READ_ATTRIBUTE_LIST_OF_ALL
            System.fetchGet(url, jwt, (data) => {

                if (data.code !== System.RESULT.SUCCESS.code) {
                    alert(JSON.stringify(data))
                    return
                }

                updateAttributeList(data.attributeList)

            })

        })

        this.setState({

        });
    };

    handleShangPinAttributeSearch = value => {

        const { jwt } = this.props

        let {shangPinSelectName} = this.state
        const {updateAttributeList, attributeList} = this.props

        if( value===null || value==="" ){
            let url = System.URL.READ_ATTRIBUTE_LIST_OF_ALL
            System.fetchGet(url, jwt, (data) => {

                if (data.code !== System.RESULT.SUCCESS.code) {
                    alert(JSON.stringify(data))
                    return
                }

                updateAttributeList(data.attributeList)
            })
        }else{
            let url = System.URL.READ_ATTRIBUTE_LIST_BY_KEYWORD+"?keyword="+value
            System.fetchGet(url, jwt, (data) => {

                if (data.code !== System.RESULT.SUCCESS.code) {
                    alert(JSON.stringify(data))
                    return
                }

                updateAttributeList(data.attributeList)
            })
        }
       
    }

    handleNameChange = (e) => {
        let {submitData}  = this.state
        let id = "shangPinInfo_" + pinyin(e.target.value, {style: pinyin.STYLE_TONE2}) + "_" + System.getYyyymmddhhmmsssss()
        id = System.removeSpecialChar(id)

        submitData.id = id
        submitData.name = e.target.value

        this.setState({
            submitData:submitData
        })
    }

    handleIntroduceChange = (e) => {
        let {submitData}  = this.state
        submitData.introduce = e.target.value

        this.setState({
            submitData:submitData
        })
    }

    handlePriceChange = (value) => {

        this.setState({
            priceOfInput:value*100
        })
    }

    handleAddAttributePrice = () => {

        let {submitData, attributeIdOfSelect,attributeNameOfSelect, priceOfInput}  = this.state
        let isExists = false

        if (attributeIdOfSelect===null || attributeNameOfSelect===null) {
            alert("请选择属性")
            return
        }
        if (priceOfInput===null) {
            alert("请输入价格")
            return
        }

        submitData.attributePriceList.map(item=>{
            if(item.attributeId===attributeIdOfSelect){
                isExists = true
            }
        })

        if (isExists) {
            alert("已存在该属性")
            return
        }

        submitData.attributePriceList.push({ attributeId: attributeIdOfSelect, attributeName:attributeNameOfSelect, price: priceOfInput })

        this.setState({
            submitData:submitData,
            attributeIdOfSelect:null,
            attributeNameOfSelect:null,
            priceOfInput:null
        })
    }

    handleDeleteAttributePrice = (key) => {
        let {submitData}  = this.state

        const index = submitData.attributePriceList.map(item=>item.attributeId).indexOf(key)
        submitData.attributePriceList.splice(index,1)

        this.setState({
            submitData:submitData

        })

    }




    handleResetButton = () => {


        let {submitData,iconVideoUploadProps, iconImageUploadProps, detailImageUploadProps} = this.state
        submitData = {
            id:null,
            name:null,
            typeId:null,
            typeName:null,
            introduce:null,
            attributePriceList:[],
            iconVideo:{directory:"",video:null},
            iconImageList:{directory:"",imageList:[]},
            detailImageList:{directory:"",imageList:[]},
        }
        iconVideoUploadProps.fileList=[]
        iconImageUploadProps.fileList=[]
        detailImageUploadProps.fileList=[]

        this.setState({
            attributeIdOfSelect:null,
            attributeNameOfSelect:null,
            priceOfInput:null,
            submitData: submitData,
            iconVideoUploadProps: iconVideoUploadProps,
            iconImageUploadProps: iconImageUploadProps,
            detailImageUploadProps: detailImageUploadProps
        })
    }

    handleSubmitButton = () => {
        const { jwt } = this.props

        let {submitData,iconImageUploadProps, detailImageUploadProps} = this.state

        if (submitData.id===null || submitData.id==="" || submitData.name===null || submitData.name==="") {
            alert("请输入商品名称")
            return
        }

        if (submitData.typeId===null || submitData.typeId==="" || submitData.typeName===null || submitData.typeName==="") {
            alert("请选择商品归属")
            return
        }

        if (submitData.introduce===null || submitData.introduce==="") {
            alert("请输入商品介绍")
            return
        }

        if (submitData.attributePriceList.length===0) {
            alert("请添加商品属性和价格")
            return
        }

        if (submitData.iconImageList.imageList.length===0) {
            alert("请添加商品展示图片")
            return
        }

        if (submitData.detailImageList.imageList.length===0) {
            alert("请添加商品详情图片")
            return
        }

        // let parameters = ""
        // parameters = parameters+"id="+submitData.id
        // parameters = parameters+"&"
        // parameters = parameters+"name="+submitData.name
        // parameters = parameters+"&"
        // parameters = parameters+"introduce="+submitData.introduce
        // parameters = parameters+"&"

        let url = System.URL.ADD_PRODUCT
        System.fetchPost(url,jwt,
            JSON.stringify(submitData),
            (data) => {
                if (data.code !== System.RESULT.SUCCESS.code) {
                    alert(JSON.stringify(data))
                    return
                }

                alert("成功添加商品")
                this.handleResetButton()
            }

            
        )

    }

    


    

    // // start shangPinIndustry
    // handleIndustrySelect = (value,option) => {
    //     console.log(option)
    // }

    // handleIndustryInput = event => {
    //     this.setState({
    //         name: event.target.value,
    //     })
    // }

    // handleIndustryAdd = () => {
    //     console.log(this.state.industryMap);
    //     const { industryMap, name } = this.state;
    //     this.setState({
    //         industryMap: [...industryMap, {key:name,value:name}],
    //         name: '',
    //     })
    // }
    // // end shangPinIndustry

    render() {

        const { isLogin, mobilephone, clientHeight, attributeList } = this.props
        const { industryMap, name, industryList, typeListArray, attributeNameOfSelect, newAttributeNameInput, priceOfInput, submitData } = this.state;

        let industryListContent = []

        for (let i = 0; i < industryList.length; i++) {

            let typeListContent = []
            
            typeListContent.push(typeListArray[i].map(item=>(
                <Option key={item.id} value={item.name}>{item.name}</Option>
            )))

            industryListContent.push(
                <OptGroup key={industryList[i].id} label={industryList[i].name}>{typeListContent}</OptGroup>
            )
            
        }

        let attributeListContent = []
        attributeListContent.push(attributeList.map(item=>(
            <Option key={item.id} value={item.name}>{item.name}</Option>
        )))
            


        let attributePriceContent = []

        attributePriceContent.push(
            <Row key="attributePriceTitle" align="middle" style={{ width: "94%"}}>
                <Col className="horizontalCenter" flex="1"><Text>属性名称</Text></Col>
                <Col className="horizontalCenter" flex="1"><Text>输入</Text></Col>
            </Row>
        )
        attributePriceContent.push(
            <Row key="attributePriceInput" align="middle" style={{ width: "94"}}>
                <Col className="horizontalCenter" flex="4" style={{  }}>
                    <Select showSearch
                        placeholder="选择属性"
                        style={{ width: "100%" }}
                        onChange={this.handleAttributeSelect}
                        onSearch={this.handleShangPinAttributeSearch}
                        value={attributeNameOfSelect}
                        dropdownRender={menu => (
                            <div>
                              {menu}
                              <Divider style={{ margin: '4px 0' }} />
                              <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                <Input style={{ flex: 'auto' }} value={newAttributeNameInput} onChange={this.handleNewAttributeNameInputChange} />
                                <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }} onClick={this.addAttribute}>
                                  <PlusOutlined /> 添加
                                </a>
                              </div>
                            </div>
                          )}
                    >
                        {attributeListContent}
                    </Select>
                </Col>
                <Col className="horizontalCenter" flex="2" style={{ }}>
                    {/* <InputNumber precision={2} min={0} max={99999} formatter={value => `¥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={value => value.replace(/\¥\s?|(,*)/g, '')} placeholder="输入" onChange={this.handlePriceChange} style={{width:"100%"}} value={priceOfInput===null?priceOfInput:priceOfInput/100}/> */}
                    <InputNumber precision={2} min={0} max={99999} formatter={value => `¥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} placeholder="输入" onChange={this.handlePriceChange} style={{width:"100%"}} value={priceOfInput===null?priceOfInput:priceOfInput/100}/>
                </Col>
                <Col className="horizontalCenter" flex="1"><Button type="primary" onClick={this.handleAddAttributePrice}>添加</Button></Col>
            </Row>
        )
        attributePriceContent.push(submitData.attributePriceList.map(item=>(
            <Tag closable key={item.attributeId} style={{ width: "100%"}} onClose={ ()=>{this.handleDeleteAttributePrice(item.attributeId) } }>
                <Row align="middle" style={{ width: "95%",float:"left" }}>
                    <Col className="horizontalCenter" flex="1"><Text>{item.attributeName}</Text></Col>
                    <Col className="horizontalCenter" flex="1"><Text>{item.price*1.0/100}</Text></Col>
                </Row>
            </Tag>
        )))




        return (
            <div className="horizontalCenter" style={this.props.isShow?{}:{display:"none"}}>

                <Space align="" direction="vertical" style={{width:"500px"}}>

                    <Row align="middle" style={{ width: "100%" }}>
                        <Col className="horizontalCenter" flex="100px" style={{}}>名称：</Col>
                        <Col flex="auto" style={{}}>
                            <Input placeholder="输入名称" value={this.state.submitData.name} onChange={this.handleNameChange} />
                        </Col>
                    </Row>

                    <Row align="middle" style={{ width: "100%" }}>
                        <Col className="horizontalCenter" flex="100px" style={{}}>归属：</Col>
                        <Col flex="auto" style={{}}>
                            <Select placeholder="选择归属" style={{ width: "100%" }} onChange={this.handleTypeSelect} value={submitData.typeName}>
                                {industryListContent}
                            </Select>
                        </Col>
                    </Row>

                    {/* <Row align="middle" style={{ width: "100%" }}>
                        <Col className="horizontalCenter" flex="100px" style={{}}>归属大类：</Col>
                        <Col flex="auto">
                            <Select
                                style={{ width: "100%" }}
                                placeholder="选择归属大类"
                                onChange={this.handleIndustrySelect}
                                dropdownRender={menu => (
                                    <div>
                                        {menu}
                                        <Divider style={{ margin: '4px 0' }} />
                                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                            <Input style={{ flex: 'auto' }} value={name} onChange={this.handleIndustryInput} />
                                            <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }} onClick={this.handleIndustryAdd}>
                                                <PlusOutlined /> 添加
                                            </a>
                                        </div>
                                    </div>
                                )}
                            >
                                
                                {industryList.map(item => (
                                    <Option key={item.id} value={item.name}>{item.name}</Option>
                                ))}

                            </Select>
                        </Col>
                    </Row>


                    <Row align="middle" style={{ width: "100%" }}>
                        <Col className="horizontalCenter" flex="100px" style={{}}>细分小类：</Col>
                        <Col flex="auto">
                            <Select
                                style={{ width: "100%" }}
                                placeholder="选择细分小类："
                                onChange={this.handleIndustrySelect}
                                dropdownRender={menu => (
                                    <div>
                                        {menu}
                                        <Divider style={{ margin: '4px 0' }} />
                                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                            <Input style={{ flex: 'auto' }} value={name} onChange={this.handleIndustryInput} />
                                            <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }} onClick={this.handleIndustryAdd}>
                                                <PlusOutlined /> 添加
                                            </a>
                                        </div>
                                    </div>
                                )}
                            >
                                
                                {industryList.map(item => (
                                    <Option key={item.id} value={item.name}>{item.name}</Option>
                                ))}

                            </Select>
                        </Col>
                    </Row> */}


                    <Row align="middle" style={{ width: "100%" }}>
                        <Col className="horizontalCenter" flex="100px" style={{}}>描述：</Col>
                        <Col flex="auto">
                            <TextArea
                                value={this.state.submitData.introduce}
                                onChange={this.handleIntroduceChange}
                                placeholder="输入描述"
                                autoSize={{ minRows: 5}}
                            />
                        </Col>
                    </Row>

                    {/* <Divider /> */}
                    <Row align="middle" style={{ width: "100%" }}>
                        <Col className="horizontalCenter" flex="100px" style={{}}>属性：</Col>
                        <Col flex="auto">
                            <div style={{width:"400px"}}>
                                {attributePriceContent}
                            </div>
                        </Col>
                    </Row>

                    {/* <Divider /> */}
                    <Row align="middle" style={{ width: "100%" }}>
                        <Col className="horizontalCenter" flex="100px" style={{}}>视频：</Col>
                        <Col flex="auto">
                            <div key={Math.random()}>
                                <Upload {...this.state.iconVideoUploadProps} beforeUpload={System.beforeVideoUpload} >
                                    <Button>
                                        <UploadOutlined /> 添加
                                 </Button>
                                </Upload>
                            </div>
                        </Col>
                    </Row>

                    {/* <Divider /> */}
                    <Row align="middle" style={{ width: "100%" }}>
                        <Col className="horizontalCenter" flex="100px" style={{}}>展示：</Col>
                        <Col flex="auto">
                            <div key={Math.random()}>
                                <Upload {...this.state.iconImageUploadProps} beforeUpload={System.beforeIconUpload} >
                                    <Button>
                                        <UploadOutlined /> 添加
                                 </Button>
                                </Upload>
                            </div>
                        </Col>
                    </Row>

                    {/* <Divider /> */}
                    <Row align="middle" style={{ width: "100%" }}>
                        <Col className="horizontalCenter" flex="100px" style={{}}>详情：</Col>
                        <Col flex="auto">
                            <div key={Math.random()}>
                                <Upload {...this.state.detailImageUploadProps} beforeUpload={System.beforeDetailUpload}>
                                    <Button>
                                        <UploadOutlined /> 添加
                                 </Button>
                                </Upload>
                            </div>
                        </Col>
                    </Row>

                    <Space className="space_around" direction="horizontal" style={{width:"100%", marginTop:"10px"}}>
                        
                        <Button type="primary" onClick={this.handleResetButton}>重置</Button>
                        <Button type="primary" onClick={this.handleSubmitButton}>提交</Button>
                    </Space>

                </Space>
            </div>
        )
    }
}

export default connect(
    //mapStateToProps
    state => {
        return {
            attributeList: state.attributeList,
            jwt: state.loginInfo.jwt
        }
    },
    //mapDispatchToProps
    {
        updateAttributeList
    }
)(ProductAddPage);