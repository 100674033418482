import React, { useState } from "react"
import "../../system/common.css"
import { connect } from "react-redux"
import {
    Switch,
    Button,
    Col,
    Divider,
    Input,
    InputNumber,
    Row,
    Select,
    Space,
    Typography,
    Tag,
    Upload,
    Radio,
    Pagination,
    message,
    Modal,
    Dropdown
} from 'antd';
import Item from "antd/lib/list/Item";
import * as System from "../../system/System"
import { UploadOutlined,DownOutlined } from '@ant-design/icons';

const { Text, Link, Title } = Typography;
const { Option, OptGroup } = Select;
const { TextArea } = Input
const style: React.CSSProperties = { background: '', padding: '8px 0' };

class IndexFrequentExternalSystemPage extends React.Component {

    constructor( props ) {
        super( props )

        this.state = {
            isTipModalOpen:false,
            modalContent:"",
            page:1,
            pageSize:10,
            resultCount:0,
        }
        
    }


    componentDidMount() {

    }


    onClickExternalSystemButton=(url)=>{
        this.comQueryButton.focus()
        this.comQueryButton.blur()

        const { jwt } = this.props
        url = System.addUrlParameter(url, "jwt", jwt)
        window.open(url, '_blank')
    }


    onClickUrlButton=(url)=>{
        this.comQueryButton.focus()
        this.comQueryButton.blur()

        window.open(url, '_blank')
    }


    onClickTestButton=()=>{
        this.comQueryButton.focus()
        this.comQueryButton.blur()

        // alert("测试")
        const { jwt } = this.props
        alert(jwt)
    }



    

    render() {

        const { isLogin, mobilephone, clientHeight } = this.props

        return (
            <div className="horizontalCenter" style={this.props.isShow?{}:{display:"none"}}>
                <Modal wrap title="提示" width={800} open={this.state.isTipModalOpen} onOk={()=>this.setState({isTipModalOpen:false})} onCancel={()=>this.setState({isTipModalOpen:false})}>
                    {this.state.modalContent}
                </Modal>


                <Space align="" direction="vertical" style={{width:"100%"}}>
                    <Divider orientation="left">外包开发</Divider>
                    <Row gutter={16} style={{marginBottom:"20px"}}>
                        <Col className="gutter-row" span={6}>
                            <div className="horizontalCenter hwWhiteBackground" style={{}}>
                                <Button type="primary" ref={(button)=>this.comQueryButton = button} onClick={()=>this.onClickExternalSystemButton("https://huangzhiwei.com/hwang-shop/")} style={{width:"220px"}}>
                                    商城后台
                                </Button>
                            </div>
                        </Col>
                        <Col className="gutter-row" span={6}>
                            <div className="horizontalCenter hwWhiteBackground" style={{}}>
                                <Button type="ghost" ref={(button)=>this.comQueryButton = button} onClick={()=>this.onClickTestButton()} style={{width:"220px"}}>
                                    测试函数
                                </Button>
                            </div>
                        </Col>
                        {/*<Col className="gutter-row" span={6}>*/}
                        {/*    <div style={style}>col-6</div>*/}
                        {/*</Col>*/}
                        {/*<Col className="gutter-row" span={6}>*/}
                        {/*    <div style={style}>col-6</div>*/}
                        {/*</Col>*/}
                        {/*<Col className="gutter-row" span={6}>*/}
                        {/*    <div style={style}>col-6</div>*/}
                        {/*</Col>*/}
                    </Row>


                    <Divider orientation="left" style={{paddingTop:'30px'}}>第三方系统</Divider>
                    <Row gutter={16} style={{marginBottom:"20px"}}>
                        <Col className="gutter-row" span={6}>
                            <div className="horizontalCenter hwWhiteBackground" style={{}}>
                                <Button type="primary" disabled={true} ref={(button)=>this.comQueryButton = button} onClick={()=>this.onClickExternalSystemButton()} style={{width:"220px"}}>
                                    待引入
                                </Button>
                            </div>
                        </Col>
                        <Col className="gutter-row" span={6}>
                            <div className="horizontalCenter hwWhiteBackground" style={{}}>
                                <Button type="ghost" disabled={true} ref={(button)=>this.comQueryButton = button} onClick={()=>this.onClickExternalSystemButton()} style={{width:"220px"}}>
                                    待引入
                                </Button>
                            </div>
                        </Col>
                        <Col className="gutter-row" span={6}>
                            <div className="horizontalCenter hwWhiteBackground" style={{}}>
                                <Button type="primary" disabled={true} ref={(button)=>this.comQueryButton = button} onClick={()=>this.onClickExternalSystemButton()} style={{width:"220px"}}>
                                    待引入
                                </Button>
                            </div>
                        </Col>
                        <Col className="gutter-row" span={6}>
                            <div className="horizontalCenter hwWhiteBackground" style={{}}>
                                <Button type="ghost" disabled={true} ref={(button)=>this.comQueryButton = button} onClick={()=>this.onClickExternalSystemButton()} style={{width:"220px"}}>
                                    待引入
                                </Button>
                            </div>
                        </Col>
                    </Row>


                    <Divider orientation="left" style={{paddingTop:'30px'}}>常用链接</Divider>
                    <Row gutter={16} style={{marginBottom:"20px"}}>
                        <Col className="gutter-row" span={6}>
                            <div className="horizontalCenter hwWhiteBackground" style={{}}>
                                <Button type="primary" ref={(button)=>this.comQueryButton = button} onClick={()=>this.onClickUrlButton("https://yiyan.baidu.com")} style={{width:"220px"}}>
                                    文心一言
                                </Button>
                            </div>
                        </Col>
                        <Col className="gutter-row" span={6}>
                            <div className="horizontalCenter hwWhiteBackground" style={{}}>
                                <Button type="ghost" ref={(button)=>this.comQueryButton = button} onClick={()=>this.onClickUrlButton("https://www.iamwawa.cn/renminbi.html")} style={{width:"220px"}}>
                                    娃娃工具
                                </Button>
                            </div>
                        </Col>
                        <Col className="gutter-row" span={6}>
                            <div className="horizontalCenter hwWhiteBackground" style={{}}>
                                <Button type="primary" ref={(button)=>this.comQueryButton = button} onClick={()=>this.onClickUrlButton("https://www.baidu.com/")} style={{width:"220px"}}>
                                    百度
                                </Button>
                            </div>
                        </Col>
                        <Col className="gutter-row" span={6}>
                            <div className="horizontalCenter hwWhiteBackground" style={{}}>
                                <Button type="ghost" ref={(button)=>this.comQueryButton = button} onClick={()=>this.onClickUrlButton("https://www.tianyancha.com/")} style={{width:"220px"}}>
                                    天眼查
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Space>




                {/*<Space align="" direction="vertical" style={{width:"100%"}}>*/}
                {/*    <Row align="right" style={{ width: "100%" }}>*/}
                {/*        <Col flex="100px">*/}
                {/*            <Button type="primary" onClick={()=>{}}>新增合作商</Button>*/}
                {/*        </Col>*/}
                {/*        <Col flex="auto"></Col>*/}
                {/*        <Col align="right" flex="300px" style={{}}>*/}
                {/*            /!*<Button type={"primary"} ref={(button)=>this.comQueryButton = button} onClick={()=>{}}>查询</Button>*!/*/}
                {/*            <Input style={{ width: '230px' }} defaultValue="" onChange={()=>{}}/>*/}
                {/*            <Button type="primary" ref={(button)=>this.comQueryButton = button} onClick={()=>{}}>搜索</Button>*/}
                {/*        </Col>*/}
                {/*    </Row>*/}

                {/*    <Divider orientation="middle"></Divider>*/}
                {/*    内容*/}
                {/*    <Divider/>*/}


                {/*    <Row align="middle" style={{ width: "100%" }}>*/}
                {/*        <Col flex="auto" align="middle" style={{}}>*/}
                {/*            <Pagination showSizeChanger current={this.state.page} pageSize={this.state.pageSize} pageSizeOptions={[2,10,50]} defaultCurrent={1} total={this.state.resultCount} onChange={this.onChangePageOrSize} />*/}
                {/*        </Col>*/}
                {/*    </Row>*/}

                {/*</Space>*/}
            </div>
        )
    }
}

export default connect(
    //mapStateToProps
    state => {
        return {
            jwt: state.loginInfo.jwt
        }
    },
    //mapDispatchToProps
    {

    }
)(IndexFrequentExternalSystemPage);