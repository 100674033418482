import React, { Component } from "react";
import { connect } from "react-redux";
import Template from "../system/Template"
import { Spin, Space, Input, Button, Alert, Typography } from 'antd'
import 'antd/dist/antd.min.css';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import * as System from "../system/System"
import {loginSuccess, loginFail} from "../store/reactReduxAction"

const { Text, Link } = Typography;


class TestPage extends React.Component{


    test(result){
        console.log(result)
    }

    constructor( props ) {
        super( props )

        this.state = {isLoading : false,
            page: {
                clientWidth: 0,
                clientHeight: 0,
                centerWidth: 0,
                sideWidth: 0,
                sideContent: null,
                
                pageWidth: 0,
                pageHeight: 0
            }
        }
        this.state.isLoading = false
        
        this.loadContent = this.loadContent.bind(this)
        this.test = this.test.bind(this)
    }

    loadContent(page){
        this.setState({
            page : page
        })

    }

    render() {

        const { isLogin, loginSuccess, loginFail } = this.props;

        return <Template content={
            <div className="horizontalCenter verticalCenter" style={{height:this.state.page.clientHeight,display:"block"}}>
                <div>{isLogin===true?"已登录":"未登录"}</div>
                <div>
                    <button onClick={loginFail}>重置</button>
                    <button onClick={loginSuccess}>登陆</button>
                </div>
            </div>

        } isLoading={this.state.isLoading} loadContent={this.loadContent.bind(this)} />
    }
}

export default connect(
    //mapStateToProps
    state => {
        // console.log("state", state);
        return {
            isLogin: state.isLogin,
            jwt: state.loginInfo.jwt
        };
    },
    //mapDispatchToProps
    {
        loginSuccess,
        loginFail
    },
)(TestPage);