import React from "react"
import { connect } from "react-redux";
import Template,{withSubscription} from "../system/Template"
import "./login.css"
import "../system/common.css"
import {Spin, Space, Input, Button, Alert, Typography, message, Layout} from 'antd'
import 'antd/dist/antd.min.css';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import * as System from "../system/System"
import {loginSuccess, loginFail, windowResize} from "../store/reactReduxAction"
import $ from 'jquery'
import { Route, Redirect } from "react-router-dom";

const { Header, Footer, Sider, Content } = Layout;

const { Text, Link } = Typography;
const TemplateWithSubscription = withSubscription(Template)

const qrcodeUrl = "https://huangzhiwei.com/hwang-account/weixinCreateLoginQrcode"

class Login extends React.Component{
    




    constructor( props ) {
        super( props )

        this.state = {
            isLoading:false,
            mobilephone: "13829900001",
            // password: "001",
            password: "",
            qrcodeUrl: "",
            qrcodeId: "",
            responseData: ""
        }
        
    }

    componentDidMount() {

        let timeStamp = ""+Date.now();

        this.setState({
            qrcodeUrl: qrcodeUrl+"?timeStamp="+timeStamp,
            qrcodeId: timeStamp
        })

        // loginSuccess(this.state.mobilephone, "eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiJkMzY4NzdmYWM1OGM0M2RkYWRlODJiZTBlNTU4NTI0NSIsInN1YiI6IntcImFjY291bnRJZFwiOlwiYWNjb3VudElkX2h1YW5nWmhpV2VpXCIsXCJhY2NvdW50VHlwZUlkXCI6XCJhY2NvdW50VHlwZUlkX2FkbWluXCIsXCJwZXJtaXNzaW9uTGlzdFwiOltcImFjY291bnRUeXBlSWRfYWRtaW5cIixcImFjY291bnRQZXJtaXNzaW9uSWRfbWFuYWdlQWNjb3VudFwiLFwiYWNjb3VudFBlcm1pc3Npb25JZF9leHBvcnREYXRhXCJdfSIsImlzcyI6InNnIiwiaWF0IjoxNzMwMTI3MTQ2LCJleHAiOjE3NjE2NjMxNDZ9.CSOynY8YQQZvMZ7iLNuyNHJtgdwsqLlXmLLbWc2RmQE")
        // const { jwt } = this.props
        // alert(jwt)
    }

    handleClickWeixinQrcode=(e)=>{
        let timeStamp = ""+Date.now();

        this.setState({
            qrcodeUrl: qrcodeUrl+"?timeStamp="+timeStamp,
            qrcodeId: timeStamp
        })
    }

    handleClickLoginButton=(e)=>{
        const { jwt } = this.props

        const parameterString = "qrcodeId=" + this.state.qrcodeId
        const url = System.URL.WEIXIN_QRCODE_LOGIN+"?"+parameterString

        System.fetchGet(url, jwt, (data)=>{

            if (data["jwt"] == null) {
                alert("登陆失败")
            } else {
                const { loginSuccess } = this.props;
                // loginSuccess(data["account"]["mobilephone"])
                loginSuccess(data["accountId"], data["jwt"])
                // alert("accountId:"+data["accountId"]+"\naccountTypeId:"+data["accountTypeId"]+"\njwt:"+data["jwt"]);
            }

            // this.setState({
            //     responseData: data["jwt"]
            // })


            // if ( data.code !== System.RESULT.SUCCESS.code ) {
            //
            //     return
            // }

        })
    }


    render() {

        const { isLogin, mobilephone, jwt, loginSuccess, loginFail, clientHeight } = this.props;

        if(isLogin){
            return  <Redirect to="/web/homePage" />
         }

        const content=
        // <div className="" style={{height:clientHeight}}>
        <Layout style={{height:clientHeight}}>
            <Header className="horizontalCenter verticalCenter" style={{backgroundColor: "lightblue"}}>
            </Header>
            <Content className="horizontalCenter verticalCenter" style={{backgroundColor: "lightblue"}}>
                <div style={{width:"100%"}}>
                    <Space className="horizontalCenter" style={{width:"100%",height:"300px"}}>
                        <img src={this.state.qrcodeUrl} alt="微信扫码登陆的二维码无法显示" onClick={this.handleClickWeixinQrcode}/>
                    </Space>
                    <Space className="horizontalCenter" style={{width:"100%", height:"50px"}}>
                        <Button type="primary" onClick={this.handleClickLoginButton}>已扫码，点击登陆</Button>
                    </Space>
                    <Space className="horizontalCenter" style={{color: 'red', backgroundColor: "blue", width:"100%", height:"30px", marginTop:"10px"}}>
                        {this.state.errorTip}
                    </Space>
                    <Space className="horizontalCenter" style={{width:"100%", height:"30px", marginTop:"10px"}}>
                        <Text>{this.state.responseData}</Text>
                    </Space>
                </div>

            </Content>
            <Footer className="horizontalCenter verticalCenter" style={{backgroundColor: "lightblue"}}>
                ICP备案号：
                <a href="https://beian.miit.gov.cn/" target="_blank">
                    粤ICP备18089759号-1
                </a>
            </Footer>
        </Layout>

        return <TemplateWithSubscription isLoading={this.state.isLoading}>{content}</TemplateWithSubscription>

    }


}



export default connect(
    //mapStateToProps
    state => {
        return {
            isLogin: state.loginInfo.isLogin,
            mobilephone: state.loginInfo.mobilephone,
            jwt: state.loginInfo.jwt,
            clientHeight : state.clientHeight
        };
    },
    //mapDispatchToProps
    {
        loginSuccess,
        loginFail,
    },
)(Login);