import React, { useState } from "react"
import "../../system/common.css"
import { connect } from "react-redux"
import {
    Switch,
    Button,
    Col,
    Divider,
    Input,
    InputNumber,
    Row,
    Select,
    Space,
    Typography,
    Tag,
    Upload,
    Radio,
    Pagination,
    message,
    Modal,
    Dropdown
} from 'antd';
import Item from "antd/lib/list/Item";
import * as System from "../../system/System"
import { UploadOutlined,DownOutlined } from '@ant-design/icons';
import ReactDOM from 'react-dom';
import { Map, Marker } from 'react-amap';
// import styles from '../amap.less'

const { Text, Link, Title } = Typography;
const { Option, OptGroup } = Select;
const { TextArea } = Input
const style: React.CSSProperties = { background: '', padding: '8px 0' };

class IndexFrequentAmapPage extends React.Component {

    constructor( props ) {
        super( props )

        this.state = {
            isTipModalOpen:false,
            modalContent:"",
            page:1,
            pageSize:10,
            resultCount:0,
        }
        
    }


    componentDidMount() {

    }


    onClickExternalSystemButton=(url)=>{
        this.comQueryButton.focus()
        this.comQueryButton.blur()

        const { jwt } = this.props
        url = System.addUrlParameter(url, "jwt", jwt)
        window.open(url, '_blank')
    }


    onClickUrlButton=(url)=>{
        this.comQueryButton.focus()
        this.comQueryButton.blur()

        window.open(url, '_blank')
    }


    onClickTestButton=()=>{
        this.comQueryButton.focus()
        this.comQueryButton.blur()

        // alert("测试")
        const { jwt } = this.props
        alert(jwt)
    }



    

    render() {

        const { isLogin, mobilephone, clientHeight } = this.props

        return (
            // <div className="horizontalCenter" style={this.props.isShow?{}:{display:"none"}}>
            //     <Modal wrap title="提示" width={800} open={this.state.isTipModalOpen} onOk={()=>this.setState({isTipModalOpen:false})} onCancel={()=>this.setState({isTipModalOpen:false})}>
            //         {this.state.modalContent}
            //     </Modal>
            //
            //
            //     <Space align="" direction="vertical" style={{width:"100%"}}>
            //         粉色反而风格
            //     </Space>
            //
            //
            // </div>
            // <div style={this.props.isShow?{}:{display:"none"}}>
            //     <div style={{ width: '80%', height: '100%'}}>
            //         <div style={this.props.isShow?{}:{display:"none"}}>
            //             <div>
            //         <Map events={this.amapEvents} amapkey={"eae41041f0ee1ddbecfb4466ff6f433c"}>
            //             <Marker position={this.markerPosition} events={this.markerEvents} />
            //         </Map>
            //             </div>
            //         </div>
            //
            //     </div>


            <div className="horizontalCenter" style={this.props.isShow?{}:{display:"none"}}>

                <Modal wrap title="提示" width={800} open={this.state.isTipModalOpen} onOk={()=>this.setState({isTipModalOpen:false})} onCancel={()=>this.setState({isTipModalOpen:false})}>
                    {this.state.modalContent}
                </Modal>

                <div style={{ width: '1000px', height: '584px'}}>

                    <Map events={this.amapEvents} amapkey={"eae41041f0ee1ddbecfb4466ff6f433c"}>
                        <Marker position={this.markerPosition} events={this.markerEvents} />
                    </Map>
                </div>


            </div>
        )
    }
}

export default connect(
    //mapStateToProps
    state => {
        return {
            jwt: state.loginInfo.jwt
        }
    },
    //mapDispatchToProps
    {

    }
)(IndexFrequentAmapPage);