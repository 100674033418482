import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Login from './account/Login';
import Router from './page/Router';
import TestPage from './test/TestPage';
import * as serviceWorker from './serviceWorker';
import store from "./store/reactReduxStore";
import {Provider} from "react-redux";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(

    <Provider store={store}>
        {/* <TestPage /> */}
        {/* <Login /> */}
        <Router />
    </Provider>
    // <React.StrictMode>
    //     <App />
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
