import { Button, Col, Divider, Input,InputNumber, Row, Select, Space, Typography, Tag, Upload } from 'antd';
import React from "react";
import { connect } from "react-redux";
import "../system/common.css";
import * as System from "../system/System"
import { UploadOutlined, PlusOutlined } from '@ant-design/icons';
import {updateAttributeList} from "../store/reactReduxAction"
import pinyin from 'pinyin'

const { Text, Link, Title } = Typography;
const { Option, OptGroup } = Select;
const { TextArea } = Input;


class QiTaPhoneNumberPage extends React.Component {

    constructor( props ) {
        super( props )

        const {updateAttributeList} = props 

        this.state = {

            submitData:{
                fileName:null,
            },

            excelUploadProps: {
                action: System.URL.UPLOAD_EXCEL,
                withCredentials:true,
                onChange({ file, fileList }) {
                },
                customRequest: info => {

                    const { jwt } = this.props

                    const formData = new FormData();
                    formData.append('file', info.file);//名字和后端接口名字对应

                    let url = System.URL.UPLOAD_EXCEL
                    System.fetchPost(url,jwt,
                        formData,
                        (data) => {
                            if (data.code !== System.RESULT.SUCCESS.code) {
                                alert(JSON.stringify(data))
                                return
                            }

                            let {excelUploadProps, submitData} = this.state
                            submitData.fileName = data.name
                            excelUploadProps.fileList = []
                            excelUploadProps.fileList.push({
                                uid: data.uid,
                                name: data.name,
                                status: 'done',
                                response: '',
                                url: data.url,
                            })
                            this.setState({
                                excelUploadProps:excelUploadProps,
                                submitData: submitData
                            })
                        }
                    )
                },
                onRemove: file => {//删除视频调用

                    let {excelUploadProps, submitData} = this.state
                    let index = excelUploadProps.fileList.indexOf(file);
                    excelUploadProps.fileList.splice(index, 1)

                    submitData.fileName = null
                    this.setState({
                        excelUploadProps: excelUploadProps,
                        submitData:submitData
                    })
                },
                fileList: [],
            },



        }

    }


    handleUpdateButton = () => {

        const { jwt } = this.props
        let {submitData,excelUploadProps} = this.state

        if (submitData.fileName==null || submitData.fileName=="") {
            alert("请添加号码文件")
            return
        }

        let url = System.URL.UPDATE_PHONE_NUMBER+"?fileName="+this.state.submitData.fileName
        System.fetchGet(url, jwt, (data) => {
                if (data.code !== System.RESULT.SUCCESS.code) {
                    alert(JSON.stringify(data))
                    return
                }

                submitData = {
                    fileName:null,
                }
                excelUploadProps.fileList=[]
        
                this.setState({
                    submitData: submitData,
                    excelUploadProps: excelUploadProps,
                })

                alert("成功更新号码库")
            }

            
        )

    }

    render() {

        return (
            <div className="horizontalCenter" style={this.props.isShow?{}:{display:"none"}}>

                <Space align="" direction="vertical" style={{width:"500px"}}>

                    <Row align="middle" style={{ width: "100%" }}>
                        <Col className="horizontalCenter" flex="100px" style={{}}>上传号码：</Col>
                        <Col flex="auto">
                            <div key={Math.random()}>
                                <Upload {...this.state.excelUploadProps} beforeUpload={System.beforeExcelUpload}>
                                    <Button>
                                        <UploadOutlined /> 上传
                                    </Button>
                                </Upload>
                            </div>
                        </Col>
                    </Row>

                    <Row align="middle" style={{ width: "100%" }}>
                        <Col className="horizontalCenter" flex="auto" style={{}}>
                            <Button type="primary" onClick={this.handleUpdateButton}>更新</Button>
                        </Col>
                    </Row>

                </Space>
            </div>
        )
    }
}

export default connect(
    //mapStateToProps
    state => {
        return {
            jwt: state.loginInfo.jwt
        }
    },
    //mapDispatchToProps
    {
    }
)(QiTaPhoneNumberPage);
