import React from "react"
import $ from 'jquery'
import {connect} from "react-redux";
import {loginFail, loginSuccess} from "../store/reactReduxAction";

const URL_PREFIX = "https://huangzhiwei.com"
export const INIT_DATA_MOBILEPHONE = 13829900001
export const INIT_DATA_IS_LOGIN = false
export const INIT_DATA_JWT = null

// const URL_PREFIX = "http://localhost:8082"
// export const INIT_DATA_MOBILEPHONE = "13829900000"
// export const INIT_DATA_IS_LOGIN = false


class System extends React.Component{
    constructor(props){
        super(props)
    }

}


export function addUrlParameter(url, key, value)  {
    if (!url.includes("?")) {
        url = url + "?" + key + "=" + value
    }else {
        url = url + "&" + key + "=" + value
    }

    return url
}



export function fetchGet(url, jwt, callBack)  {

    let isIncludeParameter = url.includes("?")

    if(jwt !== null) {
        if (isIncludeParameter === true) {
            url = url + "&jwt=" + jwt
        } else {
            url = url + "?jwt=" + jwt
        }
    }

    fetch(
        url,
        {
            method: "GET",
            mode: "cors",
            headers: {
                'Accept': 'application/json,text/plain,*/*'
            },
            credentials: "include"
        }
    ).then(
        response => response.json()
    ).then(
        result => { 
            callBack(result)
        }
    ).catch(
        e => {
            console.log(e)
        }
    )
}


export function fetchPost(url, jwt, body, callBack)  {

    let isIncludeParameter = url.includes("?")

    if(jwt !== null) {
        if (isIncludeParameter === true) {
            url = url + "&jwt=" + jwt
        } else {
            url = url + "?jwt=" + jwt
        }
    }

    fetch(
        url,
        {
            method: "POST",
            mode: "cors",
            headers: {
                'Accept': 'application/json,text/plain,*/*'
            },
            body: body,
            credentials: "include"
        }
    ).then(
        response => response.json()
    ).then(
        result => {
            callBack(result)
        }
    ).catch(
        e => {
            console.log(e)
        }
    )
}


export function checkIsMobilephone(mobilephone)  {
    let reg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
    if (!reg.test(mobilephone)) {
        return false
    }
    return true
}


export function checkIsEmail(email)  {
    console.log("hwang", email)
    let reg = /\S+@\S+\.\S+/;
    if (!reg.test(email)) {
        return false
    }
    return true
}


export function getYyyymmddhhmmsssss()  {

    let nowDate = new Date();
    let year = nowDate.getFullYear();
    let month = nowDate.getMonth()+1;
    let date = nowDate.getDate();
    if(month<10) month = "0"+month;
    if(date<10) date = "0"+date;

    let hour = nowDate.getHours();
    let minute = nowDate.getMinutes();
    let second = nowDate.getSeconds();
    let millisecond = nowDate.getMilliseconds();
    if(hour<10) hour = "0"+hour;
    if(minute<10) minute = "0"+minute;
    if(second<10) second = "0"+second;
    if(millisecond<10) {
        millisecond = "00"+millisecond
    }else if(millisecond<100){
        millisecond = "0"+millisecond
    }
    return year + month + date + hour + minute + second + millisecond;

}


export function removeSpecialChar(content){
    var pattern = /[a-zA-Z0-9_]{1}/;
    var resultContent = ""; 
    
    for (var i = 0; i < content.length; i++) { 
      var single = content.substr(i, 1);
    
      if(single.match(pattern)){
        resultContent = resultContent + single;
       } 
     }
    
    return resultContent;
  };


export default System
export const URL = {
    LOGIN: URL_PREFIX + "/hwang-account/loginByMobilephoneAndPassword",
    SEND_VERIFYCODE: URL_PREFIX + "/hwang-account/sendVerifyCode",
    FIND_ACCOUNT_LIST: URL_PREFIX + "/hwang-account/findAccountList",
    FIND_ACCOUNT_LIST_OF_PAGE: URL_PREFIX + "/hwang-account/findAccountListOfPage",
    FIND_ALL_ACCOUNT_TYPE_LIST: URL_PREFIX + "/hwang-account/findAllAccountTypeList",
    FIND_ALL_IS_OR_NOT_STATUS: URL_PREFIX + "/hwang-account/findAllIsOrNotStatusList",
    ADD_ACCOUNT: URL_PREFIX + "/hwang-account/addAccount",
    DELETE_ACCOUNT: URL_PREFIX + "/hwang-account/deleteAccount",
    FIND_ACCOUNT_BY_ID: URL_PREFIX + "/hwang-account/findAccountById",
    MODIFY_ACCOUNT: URL_PREFIX + "/hwang-account/modifyAccount",
    WEIXIN_QRCODE_LOGIN: URL_PREFIX + "/hwang-account/weixinQrcodeLogin",

    FIND_PARTNER_LIST_OF_PAGE: URL_PREFIX + "/hwang-company/findPartnerListOfPage",
    ADD_PARTNER: URL_PREFIX + "/hwang-company/addPartner",
    DELETE_PARTNER: URL_PREFIX + "/hwang-company/deletePartner",
    FIND_PARTNER_BY_ID: URL_PREFIX + "/hwang-company/findPartnerById",
    MODIFY_PARTNER: URL_PREFIX + "/hwang-company/modifyPartner",
    CREATE_ZHENXUAN_PACKAGE_AND_DOWNLOAD: URL_PREFIX + "/hwang-company/createZhenxuanPackageAndDownload",
    FIND_ZHENXUAN_TEMPLATE_FILE_LIST_AND_PRICE_FILE: URL_PREFIX + "/hwang-company/findZhenxuanTemplateFileListAndPriceFile",
    UPLOAD_ZHENXUAN_TEMPLATE_FILE: URL_PREFIX + "/hwang-company/uploadZhenxuanTemplateFile",
    DELETE_ZHENXUAN_TEMPLATE_FILE: URL_PREFIX + "/hwang-company/deleteZhenxuanTemplateFile",
    UPLOAD_ZHENXUAN_PRICE_FILE: URL_PREFIX + "/hwang-company/uploadZhenxuanPriceFile",
    DELETE_ZHENXUAN_PRICE_FILE: URL_PREFIX + "/hwang-company/deleteZhenxuanPriceFile",

    FIND_ALL_PRODUCT_PRIMARY_TYPE_LIST: URL_PREFIX + "/hwang-company/findAllProductPrimaryTypeList",
    FIND_PRODUCT_SECONDARY_TYPE_LIST: URL_PREFIX + "/hwang-company/findProductSecondaryTypeList",
    FIND_ALL_PRODUCT_SECONDARY_TYPE_LIST: URL_PREFIX + "/hwang-company/findAllProductSecondaryTypeList",
    FIND_ALL_PRODUCT_INFO_LIST: URL_PREFIX + "/hwang-company/findAllProductInfoList",
    FIND_PRODUCT_INFO_LIST_BY_KEYWORD: URL_PREFIX + "/hwang-company/findProductInfoListByKeyword",
    ADD_PRODUCT_PRIMARY_TYPE: URL_PREFIX + "/hwang-company/addProductPrimaryType",
    DELETE_PRODUCT_PRIMARY_TYPE: URL_PREFIX + "/hwang-company/deleteProductPrimaryType",
    UPDATE_PRODUCT_PRIMARY_TYPE: URL_PREFIX + "/hwang-company/updateProductPrimaryType",
    FINF_PRODUCT_PRIMARY_TYPE_BY_ID: URL_PREFIX + "/hwang-company/findProductPrimaryTypeById",
    ADD_PRODUCT_SECONDARY_TYPE: URL_PREFIX + "/hwang-company/addProductSecondaryType",
    DELETE_PRODUCT_SECONDARY_TYPE: URL_PREFIX + "/hwang-company/deleteProductSecondaryType",
    FINF_PRODUCT_SECONDARY_TYPE_BY_ID: URL_PREFIX + "/hwang-company/findProductSecondaryTypeById",
    UPDATE_PRODUCT_SECONDARY_TYPE: URL_PREFIX + "/hwang-company/updateProductSecondaryType",

    FIND_SHANGPIN_MODULE_INDUSTRY_TYPE_LIST: URL_PREFIX + "/findShangPinModuleIndustryTypeList",
    READ_ATTRIBUTE_LIST_OF_ALL: URL_PREFIX + "/admin/readAttributeListOfAll",
    READ_ATTRIBUTE_LIST_BY_KEYWORD: URL_PREFIX + "/admin/readAttributeListByKeyword",
    ADD_ATTRIBUTE: URL_PREFIX + "/admin/addAttribute",
    ADD_PRODUCT: URL_PREFIX + "/admin/addProduct",
    MODIFY_PRODUCT: URL_PREFIX + "/admin/modifyProduct",
    UPLOAD_IMAGE: URL_PREFIX + "/admin/uploadImage",
    UPLOAD_VIDEO: URL_PREFIX + "/admin/uploadVideo",
    UPLOAD_EXCEL: URL_PREFIX + "/admin/uploadExcel",
    FIND_PRODUCT_LIST_BY_PRODUCT_NAME: URL_PREFIX + "/admin/findProductListByProductName",
    UPDATE_PHONE_NUMBER: URL_PREFIX + "/admin/updatePhoneNumber",

    LOGOUT: URL_PREFIX + "/hwang-auth/logoutOfHwang",
    IMAGE_PREFIX: URL_PREFIX + "/static/uploadImage/",
    VIDEO_PREFIX: URL_PREFIX + "/static/uploadVideo/",
    OTHER_PREFIX: URL_PREFIX + "/static/uploadOther/"
}

export const ACTION = {
    LOGIN_SUCCESS: "loginSuccess",
    LOGIN_FAIL_NO_ACCOUNT: "loginFailNoAccount",
    LOGIN_FAIL_WRONG_PASSWORD: "loginFailWrongPassword",
    LOGIN_FAIL: "loginFail",

    LOGOUT: "logout",

    UPDATE_ATTRIBUTE_LIST: "updateAttributeList",

    WINDOW_RESIZE: "windowResize",
}

export const RESULT = {
    SUCCESS: {code:100,message:"成功"},
}

export function beforeVideoUpload(file){
    const isMp4 = file.type === 'video/mp4';
    if (!isMp4) {
        alert("上传展示视频格式仅为.mp4")
    }
    const isLower100M = file.size *1.0 / 1024  < 100*1024;
    if (!isLower100M) {
        alert("上传展示视频大小应小于100M")
    }
    return isMp4 && isLower100M;
}

export function beforeIconUpload(file){
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        alert("上传展示图片格式仅为.jpg和.png")
    }
    const isLower200K = file.size *1.0 / 1024  < 200;
    if (!isLower200K) {
        alert("上传展示图片大小应小于200K")
    }
    return isJpgOrPng && isLower200K;
}

export function beforeDetailUpload(file){
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        alert("上传展示图片格式仅为.jpg和.png")
    }
    const isLower1M = file.size *1.0 / 1024 /1024  < 1;
    if (!isLower1M) {
        alert("上传展示图片大小应小于1M")
    }
    return isJpgOrPng && isLower1M;
}

export function beforeExcelUpload(file){
    console.log(file.type);
    const isExcel = file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    if (!isExcel) {
        alert("上传文件格式仅为.xls和.xlsx")
    }
    return isExcel;
}


//  @param prop 属性字段
//  @returns 排序后的数组 {Array}
export function arrayRank(key) {
    return function (obj1, obj2) {
        var val1 = obj1[key];
        var val2 = obj2[key];
        if (!isNaN(Number(val1)) && !isNaN(Number(val2))) {
            val1 = Number(val1);
            val2 = Number(val2);
        }
        if (val1 < val2) { //降序排列
            return 1;
        } else if (val1 > val2) {
            return -1;
        } else {
            return 0;
        }
    };
}


export function beforeCompressUpload(file){
    const isCompressFile = file.type === 'application/zip' || file.type === 'application/x-rar'
    if (!isCompressFile) {
        alert("上传格式仅为.zip和.rar")
    }
    const isLower50M = file.size *1.0 / 1024 / 1024  < 50;
    if (!isLower50M) {
        alert("上传文件大小应小于50M")
    }
    return isCompressFile && isLower50M;
}






// export function arrayRank(key, desc) {
//     return function (a, b) {
//         return desc ? ~~(a[key] < b[key]) : ~~(a[key] > b[key]);
//     }
// }


// export default connect(
//     //mapStateToProps
//     state => {
//         return {
//             isLogin: state.loginInfo.isLogin,
//             mobilephone: state.loginInfo.mobilephone,
//             jwt: state.loginInfo.jwt,
//             clientHeight : state.clientHeight
//         };
//     },
//     //mapDispatchToProps
//     {
//         // loginSuccess,
//         // loginFail,
//     },
// )(System);
