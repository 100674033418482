import React, { useState } from "react"
import "../system/common.css"
import { connect } from "react-redux"
import {
    Switch,
    Button,
    Col,
    Divider,
    Input,
    InputNumber,
    Row,
    Select,
    Space,
    Typography,
    Tag,
    Upload,
    Radio,
    Pagination,
    message,
    Modal,
    Dropdown
} from 'antd';
import Item from "antd/lib/list/Item";
import * as System from "../system/System"
import { UploadOutlined,DownOutlined } from '@ant-design/icons';
import {loginFail, loginSuccess} from "../store/reactReduxAction";

const { Text, Link, Title } = Typography;
const { Option, OptGroup } = Select;
const { TextArea } = Input

class AccountManagePage extends React.Component {

    constructor( props ) {
        super( props )
        
        // System.fetchGet(System.URL.LOGIN+'?mobilephone=13829900000&&appKey=appKey',(data)=>{

        //     if ( data.code!==System.RESULT.SUCCESS.code ) {
        //         alert(JSON.stringify(data))
        //         return
        //     }

           
            
        // })

        this.state = {
            accountTypeIdOfSelect: "all",
            accountCondition: "",
            isTipModalOpen:false,
            isConfirmDeleteModalOpen:false,
            isAddAccountModalOpen:false,
            isModifyAccountModalOpen:false,
            modalContent:"",
            accountList:[],
            accountTypeListOfAll:[],
            statusListOfAll:[],
            accountIdOfWillDelete:"",
            page:1,
            pageSize:10,
            resultCount:0,
            // addAccountModal:{
            //     accountTypeIdOfSelect:"",
            //     accountTypeNameOfSelect:"请选择账户类型",
            //     statusIdOfSelect:"",
            //     statusNameOfSelect:"请选择状态",
            //     mobilephone:"",
            //     password:"",
            //     email:""
            // }
            addAccountModal:{
                accountTypeIdOfSelect:"accountTypeId_user",
                accountTypeNameOfSelect:"用户",
                statusIdOfSelect:"isOrNotStatusId_yes",
                statusNameOfSelect:"是",
                mobilephone:"13829900004",
                password:"004",
                email:"602634486@qq.com"
            },
            modifyAccountModal:{
                id:"",
                mobilephone:"",
                accountTypeId:"",
                accountTypeName:"",
                password:"",
                email:"",
                weiXinOpenId:"",
                registerTime:"",
                statusId:"",
                statusName:""
            }
        }
        
    }

    componentDidMount() {
        const { jwt } = this.props

        this.handleClickQueryButton()

        let url = System.URL.FIND_ALL_ACCOUNT_TYPE_LIST
        System.fetchGet(url,jwt,(data)=>{

            if ( data.code!==100 ) {
                this.setState({
                    isTipModalOpen: true,
                    modalContent: JSON.stringify(data)
                })
                return
            }

            this.setState({
                accountTypeListOfAll: data.accountTypeList
            })
        })


        url = System.URL.FIND_ALL_IS_OR_NOT_STATUS
        System.fetchGet(url, jwt, (data)=>{

            if ( data.code!==100 ) {
                this.setState({
                    isTipModalOpen: true,
                    modalContent: JSON.stringify(data)
                })
                return
            }

            this.setState({
                statusListOfAll: data.statusList
                // isTipModalOpen: true,
                // modalContent: "添加成功"
            })
        })


        // let nowDateTime = System.getYyyymmddhhmmsssss().substring(0,14)
        // this.setState({
        //     isTipModalOpen: true,
        //     modalContent: nowDateTime
        // })
    }

    handleChangePageOrSize=(page, pageSize)=>{
        this.comQueryButton.focus()
        this.comQueryButton.blur()

        this.setState({
            page: page,
            pageSize: pageSize
        }, ()=> {
            this.handleClickQueryButton()
        })

    }


    handleClickAddAccountModalSubmitButton=()=>{
        const { jwt } = this.props

        this.comQueryButton.focus()
        this.comQueryButton.blur()

        const isMobilephone = System.checkIsMobilephone(this.state.addAccountModal.mobilephone)
        if (isMobilephone===false){
            this.setState({
                isTipModalOpen: true,
                modalContent: "手机号码格式不正确"
            })
            return
        }

        const isPassword = (this.state.addAccountModal.password!=="")
        if (isPassword===false){
            this.setState({
                isTipModalOpen: true,
                modalContent: "密码格式不正确"
            })
            return
        }

        const isEmail = System.checkIsEmail(this.state.addAccountModal.email)
        if (isEmail===false){
            this.setState({
                isTipModalOpen: true,
                modalContent: "邮箱格式不正确"
            })
            return
        }

        let url = System.URL.ADD_ACCOUNT
        url = url+"/"+this.state.addAccountModal.mobilephone
        url = url+"/"+this.state.addAccountModal.accountTypeIdOfSelect
        url = url+"/"+this.state.addAccountModal.password
        url = url+"/"+this.state.addAccountModal.email
        url = url+"/"+this.state.addAccountModal.statusIdOfSelect
        System.fetchGet(url, jwt,(data)=>{

            if ( data.code!==100 ) {
                this.setState({
                    isTipModalOpen: true,
                    modalContent: JSON.stringify(data)
                })
                return
            }

            let addAccountModal = this.state.addAccountModal
            addAccountModal.accountTypeIdOfSelect = "accountTypeId_user"
            addAccountModal.accountTypeNameOfSelect = "用户"
            addAccountModal.statusIdOfSelect = "isOrNotStatusId_yes"
            addAccountModal.statusNameOfSelect = "是"
            addAccountModal.mobilephone = "13829900004"
            addAccountModal.password = "004"
            addAccountModal.email = "602634486@qq.com"

            this.setState({
                addAccountModal: addAccountModal,
                isTipModalOpen: true,
                modalContent: JSON.stringify(data),
                isAddAccountModalOpen: false
            })

            this.handleClickQueryButton()


        })
    }

    handleChangeAddAccountModalMobilephoneInput=(e)=>{

        let addAccountModal = this.state.addAccountModal
        addAccountModal.mobilephone = e.target.value

        this.setState({
            addAccountModal: addAccountModal
        })
    }


    handleChangeAddAccountModalPasswordInput=(e)=>{

        let addAccountModal = this.state.addAccountModal
        addAccountModal.password = e.target.value

        this.setState({
            addAccountModal: addAccountModal
        })
    }


    handleChangeAddAccountModalEmailInput=(e)=>{

        let addAccountModal = this.state.addAccountModal
        addAccountModal.email = e.target.value

        this.setState({
            addAccountModal: addAccountModal
        })
    }


    handleClickAddAccountModalAccountTypeDropdown = ({ key }) => {

        let addAccountModal = this.state.addAccountModal

        addAccountModal.accountTypeIdOfSelect = key
        this.state.accountTypeListOfAll.map(item=>{
            if(item["id"]===key){
                addAccountModal.accountTypeNameOfSelect = item["name"]
            }
        })

        this.setState({
            addAccountModal: addAccountModal
        })
    }


    handleClickAddAccountModalStatusDropdown = ({ key }) => {

        let addAccountModal = this.state.addAccountModal

        addAccountModal.statusIdOfSelect = key
        this.state.statusListOfAll.map(item=>{
            if(item["id"]===key){
                addAccountModal.statusNameOfSelect = item["name"]
            }
        })

        this.setState({
            addAccountModal: addAccountModal
        })
    }



    handleClickModifyAccountModalAccountTypeDropdown = ({ key }) => {

        let modifyAccountModal = this.state.modifyAccountModal

        modifyAccountModal.accountTypeId = key
        this.state.accountTypeListOfAll.map(item=>{
            if(item["id"]===key){
                modifyAccountModal.accountTypeName = item["name"]
            }
        })

        this.setState({
            modifyAccountModal: modifyAccountModal
        })
    }


    handleClickModifyAccountModalStatusDropdown = ({ key }) => {

        let modifyAccountModal = this.state.modifyAccountModal

        modifyAccountModal.statusId = key
        this.state.statusListOfAll.map(item=>{
            if(item["id"]===key){
                modifyAccountModal.statusName = item["name"]
            }
        })

        this.setState({
            modifyAccountModal: modifyAccountModal
        })
    }


    handleClickQueryButton_Backup = () => {

        const { jwt } = this.props

        this.comQueryButton.focus()
        this.comQueryButton.blur()

        let url = System.URL.FIND_ACCOUNT_LIST
        if( this.state.accountTypeIdOfSelect !== "all" ) {
            url = url + "?accountTypeId=" + this.state.accountTypeIdOfSelect
            if( this.state.accountCondition !== "" ) {
                url = url + "&condition=" + this.state.accountCondition
            }
        } else {
            if( this.state.accountCondition !== "" ) {
                url = url + "?condition=" + this.state.accountCondition
            }
        }

        System.fetchGet(url, jwt, (data)=>{

            if ( data.code!==100 ) {
                this.setState({
                    isTipModalOpen: true,
                    modalContent: JSON.stringify(data)
                })
                return
            }

            this.setState({
                accountList: data.accountList
            })


        })
    }


    handleClickQueryButton = () => {

        const { jwt } = this.props

        this.comQueryButton.focus()
        this.comQueryButton.blur()

        let url = System.URL.FIND_ACCOUNT_LIST_OF_PAGE
        url = url + "?page=" + this.state.page
        url = url + "&pageSize=" + this.state.pageSize
        if( this.state.accountTypeIdOfSelect !== "all" ) {
            url = url + "&accountTypeId=" + this.state.accountTypeIdOfSelect
        }
        if( this.state.accountCondition !== "" ) {
            url = url + "&condition=" + this.state.accountCondition
        }
        // url = url + "&jwt=" + jwt

        // this.setState({
        //     isTipModalOpen: true,
        //     modalContent: url
        // })
        //
        // return

        System.fetchGet(url, jwt,(data)=>{

            if ( data.code!==100 ) {
                this.setState({
                    isTipModalOpen: true,
                    modalContent: JSON.stringify(data)
                })
                return
            }

            // this.setState({
            //     isTipModalOpen: true,
            //     modalContent: JSON.stringify(data)
            // })

            this.setState({
                resultCount: data.resultCount,
                accountList: data.accountList
            })

        })
    }

    handleClickAddAccountButton = () => {

        this.setState({
            isAddAccountModalOpen:true
        })
        this.comQueryButton.focus()
        this.comQueryButton.blur()
    }

    handleChangeAccountType = (value) =>{

        this.setState({
            accountTypeIdOfSelect: value
        })

        this.comQueryButton.focus()
        this.comQueryButton.blur()
    }

    handleChangeAccountCondition = (e) =>{

        this.setState({
            accountCondition: e.target.value
        })
    }


    handleClickWillDeleteAccount = (accountId) =>{
        this.comQueryButton.focus()
        this.comQueryButton.blur()

        this.setState({
            isConfirmDeleteModalOpen: true,
            accountIdOfWillDelete: accountId
        })
    }


    handleClickCancelDeleteAccount = (accountId) =>{
        this.comQueryButton.focus()
        this.comQueryButton.blur()

        this.setState({
            isConfirmDeleteModalOpen: false,
            accountIdOfWillDelete: ""
        })
    }


    handleClickConfirmDeleteAccount = (id) =>{
        const { jwt } = this.props

        this.comQueryButton.focus()
        this.comQueryButton.blur()

        let url = System.URL.DELETE_ACCOUNT
        url = url+"/"+id
        System.fetchGet(url, jwt, (data)=>{
            if ( data.code!==100 ) {
                this.setState({
                    isTipModalOpen: true,
                    modalContent: JSON.stringify(data),
                    isConfirmDeleteModalOpen:false
                })
                return
            }

            this.setState({
                isTipModalOpen: true,
                modalContent: "删除成功",
                isConfirmDeleteModalOpen:false
            })

            this.handleClickQueryButton()
        })


    }



    handleClickModifyAccountButton = (accountId) =>{
        const { jwt } = this.props

        this.comQueryButton.focus()
        this.comQueryButton.blur()

        let url = System.URL.FIND_ACCOUNT_BY_ID
        url = url+"/"+accountId
        // console.log("hwang",url)
        System.fetchGet(url, jwt, (data)=>{
            if ( data.code!==100 ) {
                this.setState({
                    isTipModalOpen: true,
                    modalContent: JSON.stringify(data),
                })
                return
            }

            let modifyAccountModal = this.state.modifyAccountModal
            modifyAccountModal.id = data.account.id
            modifyAccountModal.mobilephone = data.account.mobilephone
            modifyAccountModal.accountTypeId = data.account.accountType.id
            modifyAccountModal.accountTypeName = data.account.accountType.name
            modifyAccountModal.password = data.account.password
            modifyAccountModal.email = data.account.email
            modifyAccountModal.weiXinOpenId = data.account.weiXinOpenId
            modifyAccountModal.registerTime = data.account.registerTime
            modifyAccountModal.statusId = data.account.status.id
            modifyAccountModal.statusName = data.account.status.name

            this.setState({
                // isTipModalOpen: true,
                // modalContent: JSON.stringify(data),
                isModifyAccountModalOpen:true,
                modifyAccountModal: modifyAccountModal
            })
        })
    }



    handleClickModifyAccountModalSubmitButton = () =>{

        const { jwt } = this.props

        this.comQueryButton.focus()
        this.comQueryButton.blur()

        // message.info(typeof this.state.modifyAccountModal.password)


        let url = System.URL.MODIFY_ACCOUNT
        url = url + "?accountId=" + this.state.modifyAccountModal.id
        url = url + "&mobilephone=" + this.state.modifyAccountModal.mobilephone
        url = url + "&accountTypeId=" + this.state.modifyAccountModal.accountTypeId
        if(typeof(this.state.modifyAccountModal.password) === 'undefined'){
            url = url + "&password="
        }else {
            url = url + "&password=" + this.state.modifyAccountModal.password
        }

        url = url + "&email=" + this.state.modifyAccountModal.email
        url = url + "&weiXinOpenId=" + this.state.modifyAccountModal.weiXinOpenId
        url = url + "&statusId=" + this.state.modifyAccountModal.statusId
        // console.log("hwang",url)
        // message.info(url)
        System.fetchGet(url, jwt, (data)=>{
            if ( data.code!==100 ) {
                this.setState({
                    isTipModalOpen: true,
                    modalContent: JSON.stringify(data),
                })
                return
            }

            this.setState({
                // isTipModalOpen: true,
                // modalContent: url,
                isModifyAccountModalOpen:false,
            })

            message.success("修改成功")

            this.handleClickQueryButton()
        })
    }


    handleChangeModifyAccountModalMobilephoneInput=(e)=>{

        let modifyAccountModal = this.state.modifyAccountModal
        modifyAccountModal.mobilephone = e.target.value

        this.setState({
            modifyAccountModal: modifyAccountModal
        })
    }

    handleChangeModifyAccountModalPasswordInput=(e)=>{

        let modifyAccountModal = this.state.modifyAccountModal
        modifyAccountModal.password = e.target.value

        this.setState({
            modifyAccountModal: modifyAccountModal
        })
    }

    handleChangeModifyAccountModalEmailInput=(e)=>{

        let modifyAccountModal = this.state.modifyAccountModal
        modifyAccountModal.email = e.target.value

        this.setState({
            modifyAccountModal: modifyAccountModal
        })
    }

    handleChangeModifyAccountModalWeiXinOpenIdInput=(e)=>{

        let modifyAccountModal = this.state.modifyAccountModal
        modifyAccountModal.weiXinOpenId = e.target.value

        this.setState({
            modifyAccountModal: modifyAccountModal
        })
    }


    

    render() {

        const { isLogin, mobilephone, jwt, loginSuccess, loginFail, clientHeight } = this.props;

        let accountListContent = []
        accountListContent.push(
                <Row align="middle" style={{ width: "100%",color:"white"}}>
                    <Col className="hwBlueBackground" align="middle" flex="200px" style={{}}>
                        编码
                    </Col>
                    <Col className="hwBlueBackground" align="middle" flex="120px" style={{}}>
                        名称
                    </Col>
                    <Col className="hwBlueBackground" align="middle" flex="120px" style={{}}>
                        手机号码
                    </Col>
                    <Col className="hwBlueBackground" align="middle" flex="200px" style={{}}>
                        邮箱
                    </Col>
                    <Col className="hwBlueBackground" align="middle" flex="100px" style={{}}>
                        注册时间
                    </Col>
                    <Col className="hwBlueBackground" align="middle" flex="100px" style={{}}>
                        是否有效
                    </Col>
                    <Col className="hwBlueBackground" flex="auto" style={{}}>
                        -
                    </Col>
                    <Col className="hwBlueBackground" align="middle" flex="70px" style={{}}>
                        -
                    </Col>
                    <Col className="hwBlueBackground" align="middle" flex="70px" style={{}}>
                        -
                    </Col>
                </Row>
        )
        accountListContent.push(
            this.state.accountList.map((item,index) => (
                <Row align="middle" style={{ width: "100%"}}>
                    <Col align="middle" flex="200px" style={{backgroundColor:index%2===1?"lightgray":"white"}}>
                        {item.id}
                    </Col>
                    <Col align="middle" flex="120px" style={{backgroundColor:index%2===1?"lightgray":"white"}}>
                        {item.accountType.name}
                    </Col>
                    <Col align="middle" flex="120px" style={{backgroundColor:index%2===1?"lightgray":"white"}}>
                        {item.mobilephone}
                    </Col>
                    <Col align="middle" flex="200px" style={{backgroundColor:index%2===1?"lightgray":"white"}}>
                        {item.email}
                    </Col>
                    <Col align="middle" flex="100px" style={{backgroundColor:index%2===1?"lightgray":"white"}}>
                        {item.registerTime.substring(0,10)}
                    </Col>
                    <Col align="middle" flex="100px" style={{backgroundColor:index%2===1?"lightgray":"white"}}>
                        {item.status.name}
                    </Col>
                    <Col flex="auto" style={{}}>
                    </Col>
                    <Col align="middle" flex="70px" style={{}}>
                        <Button onClick={(accountId)=>this.handleClickModifyAccountButton(item.id)}>编辑</Button>
                    </Col>
                    <Col align="middle" flex="70px" style={{}}>
                        <Button onClick={(accountId)=>{this.handleClickWillDeleteAccount(item.id)}}>删除</Button>
                    </Col>
                </Row>
            ))
        )

        let addAccountModalAccountTypeDropdownMenuItems = this.state.accountTypeListOfAll.map((item,index) => (
            {label:item.name, key:item.id}
        ))

        let addAccountModalStatusDropdownMenuItems = this.state.statusListOfAll.map((item,index) => (
            {label:item.name, key:item.id}
        ))

        // console.log("hwang", addAccountModalStatusDropdownMenuItems)
        


        return (
            <div className="horizontalCenter" style={this.props.isShow?{}:{display:"none"}}>
                <Modal maskClosable={false} wrap title="提示" width={800} open={this.state.isTipModalOpen} onOk={()=>this.setState({isTipModalOpen:false})} onCancel={()=>this.setState({isTipModalOpen:false})}>
                    {this.state.modalContent}
                </Modal>

                <Modal
                    maskClosable={false}
                    title="提示"
                    open={this.state.isConfirmDeleteModalOpen}
                    onOk={(accountId)=>this.handleClickConfirmDeleteAccount(this.state.accountIdOfWillDelete)}
                    onCancel={this.handleClickCancelDeleteAccount}
                >
                    <p>是否删除</p>
                </Modal>


                <Modal maskClosable={false} title="新建账号" width={800} style={{}} okText="提交" cancelText="取消" open={this.state.isAddAccountModalOpen} onOk={this.handleClickAddAccountModalSubmitButton} onCancel={()=>this.setState({isAddAccountModalOpen:false})}>
                    <Space direction="vertical" style={{width:"100%", height:"400px"}}>

                        <Row align="middle" style={{ width: "100%"}}>
                            <Col flex="10%"></Col>
                            <Col flex="auto">
                                <Space direction="vertical" style={{width:"100%"}}>
                                    <Row align="middle" style={{ width: "100%"}}>
                                        <Col align="right" flex="100px">手机号码：</Col>
                                        <Col flex="auto">
                                            <Input style={{ width: "80%"}} value={this.state.addAccountModal.mobilephone} onChange={this.handleChangeAddAccountModalMobilephoneInput}></Input>
                                        </Col>
                                    </Row>
                                    <Row align="middle" style={{ width: "100%"}}>
                                        <Col align="right" flex="100px">账号类型：</Col>
                                        <Col flex="auto">
                                            <Dropdown trigger={"click"} menu={{items:addAccountModalAccountTypeDropdownMenuItems, onClick:this.handleClickAddAccountModalAccountTypeDropdown}}>
                                                <Button>
                                                    <Space>
                                                        {this.state.addAccountModal.accountTypeNameOfSelect}
                                                        <DownOutlined />
                                                    </Space>
                                                </Button>
                                            </Dropdown>
                                        </Col>
                                    </Row>
                                    <Row align="" style={{ width: "100%"}}>
                                        <Col align="right" flex="100px">密码：</Col>
                                        <Col flex="auto">
                                            <Input style={{ width: "80%"}} value={this.state.addAccountModal.password} onChange={this.handleChangeAddAccountModalPasswordInput}></Input>
                                        </Col>
                                    </Row>
                                    <Row align="" style={{ width: "100%"}}>
                                        <Col align="right" flex="100px">邮箱：</Col>
                                        <Col flex="auto">
                                            <Input style={{ width: "80%"}} value={this.state.addAccountModal.email} onChange={this.handleChangeAddAccountModalEmailInput}></Input>
                                        </Col>
                                    </Row>
                                    <Row align="middle" style={{ width: "100%"}}>
                                        <Col align="right" flex="100px">是否有效：</Col>
                                        <Col flex="auto">
                                            <Dropdown trigger={"click"} menu={{items:addAccountModalStatusDropdownMenuItems, onClick:this.handleClickAddAccountModalStatusDropdown}}>
                                                <Button>
                                                    <Space>
                                                        {this.state.addAccountModal.statusNameOfSelect}
                                                        <DownOutlined />
                                                    </Space>
                                                </Button>
                                            </Dropdown>
                                        </Col>
                                    </Row>
                                </Space>
                            </Col>
                        </Row>


                    </Space>
                </Modal>



                <Modal maskClosable={false} title="编辑账号" width={800} style={{}} okText="提交" cancelText="取消" open={this.state.isModifyAccountModalOpen} onOk={this.handleClickModifyAccountModalSubmitButton} onCancel={()=>this.setState({isModifyAccountModalOpen:false})}>
                    <Space direction="vertical" style={{width:"100%", height:"400px"}}>

                        <Row align="middle" style={{ width: "100%"}}>
                            <Col flex="10%"></Col>
                            <Col flex="auto">
                                <Space direction="vertical" style={{width:"100%"}}>
                                    <Row align="middle" style={{ width: "100%"}}>
                                        <Col align="right" flex="100px">账号编码：</Col>
                                        <Col flex="auto">
                                            <Input disabled="disabled" style={{ width: "80%"}} value={this.state.modifyAccountModal.id} onChange={{}}></Input>
                                        </Col>
                                    </Row>
                                    <Row align="middle" style={{ width: "100%"}}>
                                        <Col align="right" flex="100px">手机号码：</Col>
                                        <Col flex="auto">
                                            <Input style={{width: "80%"}} value={this.state.modifyAccountModal.mobilephone} onChange={this.handleChangeModifyAccountModalMobilephoneInput}></Input>
                                        </Col>
                                    </Row>
                                    <Row align="middle" style={{ width: "100%"}}>
                                        <Col align="right" flex="100px">账号类型：</Col>
                                        <Col flex="auto">
                                            <Dropdown trigger={"click"} menu={{items:addAccountModalAccountTypeDropdownMenuItems, onClick:this.handleClickModifyAccountModalAccountTypeDropdown}}>
                                                <Button>
                                                    <Space>
                                                        {this.state.modifyAccountModal.accountTypeName}
                                                        <DownOutlined />
                                                    </Space>
                                                </Button>
                                            </Dropdown>
                                        </Col>
                                    </Row>
                                    <Row align="" style={{ width: "100%"}}>
                                        <Col align="right" flex="100px">密码：</Col>
                                        <Col flex="auto">
                                            <Input style={{ width: "80%"}} value={this.state.modifyAccountModal.password} onChange={this.handleChangeModifyAccountModalPasswordInput}></Input>
                                        </Col>
                                    </Row>
                                    <Row align="" style={{ width: "100%"}}>
                                        <Col align="right" flex="100px"></Col>
                                        <Col flex="auto" style={{color:"red"}}>
                                            注：密码为空，代表不修改原密码
                                        </Col>
                                    </Row>
                                    <Row align="" style={{ width: "100%"}}>
                                        <Col align="right" flex="100px">邮箱：</Col>
                                        <Col flex="auto">
                                            <Input style={{ width: "80%"}} value={this.state.modifyAccountModal.email} onChange={this.handleChangeModifyAccountModalEmailInput}></Input>
                                        </Col>
                                    </Row>
                                    <Row align="" style={{ width: "100%"}}>
                                        <Col align="right" flex="100px">微信OpenId：</Col>
                                        <Col flex="auto">
                                            <Input style={{ width: "80%"}} value={this.state.modifyAccountModal.weiXinOpenId} onChange={this.handleChangeModifyAccountModalWeiXinOpenIdInput}></Input>
                                        </Col>
                                    </Row>
                                    <Row align="" style={{ width: "100%"}}>
                                        <Col align="right" flex="100px">注册时间：</Col>
                                        <Col flex="auto">
                                            <Input disabled="disabled" style={{ width: "80%"}} value={this.state.modifyAccountModal.registerTime} onChange={{}}></Input>
                                        </Col>
                                    </Row>
                                    <Row align="middle" style={{ width: "100%"}}>
                                        <Col align="right" flex="100px">是否有效：</Col>
                                        <Col flex="auto">
                                            <Dropdown trigger={"click"} menu={{items:addAccountModalStatusDropdownMenuItems, onClick:this.handleClickModifyAccountModalStatusDropdown}}>
                                                <Button>
                                                    <Space>
                                                        {this.state.modifyAccountModal.statusName}
                                                        <DownOutlined />
                                                    </Space>
                                                </Button>
                                            </Dropdown>
                                        </Col>
                                    </Row>
                                </Space>
                            </Col>
                        </Row>


                    </Space>
                </Modal>



                <Space align="" direction="vertical" style={{width:"100%"}}>
                    <Row align="right" style={{ width: "100%" }}>
                        <Col flex="100px">
                            <Button type="primary" onClick={this.handleClickAddAccountButton}>新增账号</Button>
                        </Col>
                        <Col align="right" flex="auto" style={{}}>
                            <Radio.Group defaultValue={"all"} onChange={(e)=>this.handleChangeAccountType(e.target.value)}>
                                <Radio.Button value="all">全部</Radio.Button>
                                <Radio.Button value="accountTypeId_admin">超级管理员</Radio.Button>
                                <Radio.Button value="accountTypeId_manager">管理员</Radio.Button>
                                <Radio.Button value="accountTypeId_user">用户</Radio.Button>
                            </Radio.Group>
                        </Col>
                        <Col flex="10px"></Col>
                        <Col align="right" flex="300px" style={{}}>
                            {/*<Button type={"primary"} ref={(button)=>this.comQueryButton = button} onClick={this.handleQueryButton}>查询</Button>*/}
                            <Input style={{ width: '230px' }} defaultValue="" onChange={this.handleChangeAccountCondition}/>
                            <Button type="primary" ref={(button)=>this.comQueryButton = button} onClick={this.handleClickQueryButton}>查询</Button>
                        </Col>
                    </Row>

                    <Divider orientation="middle"></Divider>
                        {accountListContent}
                    <Divider/>


                    <Row align="middle" style={{ width: "100%" }}>
                        <Col flex="auto" align="middle" style={{}}>
                            <Pagination showSizeChanger current={this.state.page} pageSize={this.state.pageSize} pageSizeOptions={[2,10,50]} defaultCurrent={1} total={this.state.resultCount} onChange={this.handleChangePageOrSize} />
                        </Col>
                    </Row>

                </Space>
            </div>
        )
    }
}

// export default connect(
//     //mapStateToProps
//     state => {
//         return {
//         }
//     },
//     //mapDispatchToProps
//     {
//
//     }
// )(AccountManagePage);

export default connect(
    //mapStateToProps
    state => {
        return {
            isLogin: state.loginInfo.isLogin,
            mobilephone: state.loginInfo.mobilephone,
            jwt: state.loginInfo.jwt,
            clientHeight : state.clientHeight
        };
    },
    //mapDispatchToProps
    {
        loginSuccess,
        loginFail,
    },
)(AccountManagePage);