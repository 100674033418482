import React, { useState } from "react"
import "../../system/common.css"
import { connect } from "react-redux"
import {
    Switch,
    Button,
    Col,
    Divider,
    Input,
    InputNumber,
    Row,
    Select,
    Space,
    Typography,
    Tag,
    Upload,
    Radio,
    Pagination,
    message,
    Modal,
    Dropdown, Tooltip
} from 'antd';
import Item from "antd/lib/list/Item";
import * as System from "../../system/System"
import { UploadOutlined,DownOutlined } from '@ant-design/icons';
import {loginSuccess} from "../../store/reactReduxAction";

const { Text, Link, Title } = Typography;
const { Option, OptGroup } = Select;
const { TextArea } = Input

class CompanyIctProductPage extends React.Component {

    constructor( props ) {
        super( props )

        this.state = {
            isTipModalOpen:false,
            modalContent:"",
            page:1,
            pageSize:10,
            resultCount:0,
            statusListOfAll:[],
            productPrimaryTypeList:[],
            productSecondaryTypeList:[],
            productInfoList:[],
            productPrimaryTypeIdOfSelect:"",
            productPrimaryTypeNameOfSelect:"一级类目全部",
            productSecondaryTypeIdOfSelect:"",
            productSecondaryTypeNameOfSelect:"二级类目全部",
            keywordOfSearchProductInfoName:"",

            isProductPrimaryTypeManageModalOpen:false,
            isProductPrimaryTypeAddModalOpen:false,

            isConfirmDeleteProductPrimaryTypeModalOpen: false,
            productPrimaryTypeIdOfWillDelete: "",

            isProductPrimaryTypeModifyModalOpen:false,
            productPrimaryTypeIdOfModify: "",

            productPrimaryTypeAddModalData:{
                id:"productPrimaryType_XXX_XXX",
                name:"XXX",
                sort:"9"
            },

            productPrimaryTypeModifyModalData:{
                id:"",
                name:"",
                sort:""
            },

            productSecondaryTypeManageModalData:{
                productPrimaryTypeIdOfSelect:"",
                productPrimaryTypeNameOfSelect:"一级类目全部",

                productSecondaryTypeIdOfAdd:"productSecondaryType_XXX_XXX_XXX",
                productSecondaryTypeNameOfAdd:"IPS",
                productPrimaryTypeIdOfAdd:"",
                productPrimaryTypeNameOfAdd:"一级类目全部",
                productSecondaryTypeSortOfAdd:"99",

                productSecondaryTypeIdOfWillDelete: "",

                productSecondaryTypeIdOfModify: "",
                productSecondaryTypeNameOfModify: "",
                productPrimaryTypeIdOfModify: "",
                productPrimaryTypeNameOfModify: "",
                productSecondaryTypeSortOfModify: "",

                isProductSecondaryTypeManageModalOpen:false,
                isProductSecondaryTypeAddModalOpen:false,
                isProductSecondaryTypeConfirmDeleteModalOpen: false,
                isProductSecondaryTypeModifyModalOpen:false,

                productSecondaryTypeList:[],
            },

            addProductModalData:{
                isModalOpen: false,

                id: "shangPinInfo_computer_networkDevice_router_huasan_NX30",
                name: "",
                brand: "",
                model: "",
                specification: "",
                unit: "",
                productSecondaryTypeId: "",
                productSecondaryTypeName: "",
                costPrice: "",
                sellPrice: "",
                statusId: "",
                statusName: "",
                sort: "",

            }
        }
        
    }





    componentDidMount() {

        const { jwt } = this.props

        this.updateProductPrimaryTypeList()

        this.updateProductInfoList()

        let productSecondaryTypeManageModalData = this.state.productSecondaryTypeManageModalData

        productSecondaryTypeManageModalData.isProductSecondaryTypeManageModalOpen = false
        productSecondaryTypeManageModalData.isProductSecondaryTypeAddModalOpen = false

        this.setState({
            // isProductPrimaryTypeManageModalOpen: false,
            // isProductPrimaryTypeAddModalOpen: true,
            productSecondaryTypeManageModalData:productSecondaryTypeManageModalData,
        })

        this.updateProductSecondaryTypeListOfProductSecondaryTypeManageModal(productSecondaryTypeManageModalData.productPrimaryTypeIdOfSelect)



        let addProductModalData = this.state.addProductModalData

        // addProductModalData.isModalOpen = true
        addProductModalData.isModalOpen = false

        this.setState({
            addProductModalData:addProductModalData,
        })



        let url = System.URL.FIND_ALL_IS_OR_NOT_STATUS
        System.fetchGet(url,jwt,(data)=>{

            if ( data.code!==100 ) {
                this.setState({
                    isTipModalOpen: true,
                    modalContent: JSON.stringify(data)
                })
                return
            }

            this.setState({
                statusListOfAll: data.statusList
            })
        })

    }



    onChangePageOrSize=(page, pageSize)=>{
        this.comQueryButton.focus()
        this.comQueryButton.blur()

        this.setState({
            page: page,
            pageSize: pageSize
        }, ()=> {
            this.onClickSearchProductInfoListButton()
        })

    }



    updateProductPrimaryTypeList = () =>{
        const { jwt } = this.props
        let url = System.URL.FIND_ALL_PRODUCT_PRIMARY_TYPE_LIST
        System.fetchGet(url,jwt,(data)=>{

            if ( data.code!==100 ) {
                this.setState({
                    isTipModalOpen: true,
                    modalContent: JSON.stringify(data)
                })
                return
            }

            this.setState({
                productPrimaryTypeList: data.productPrimaryTypeList,
            })

        })
    }



    updateProductInfoList = () =>{
        const { jwt } = this.props
        let url = System.URL.FIND_ALL_PRODUCT_INFO_LIST
        System.fetchGet(url,jwt,(data)=>{

            if ( data.code!==100 ) {
                this.setState({
                    isTipModalOpen: true,
                    modalContent: JSON.stringify(data)
                })
                return
            }

            this.setState({
                productInfoList: data.productInfoList,
            })
        })
    }



    onClickSearchProductPrimaryTypeDropdown = ({ key }) => {
        const { jwt } = this.props

        this.state.productPrimaryTypeList.map(item=>{
            if(item["id"]===key){

                this.setState({
                    productPrimaryTypeIdOfSelect: item["id"],
                    productPrimaryTypeNameOfSelect: item["name"]
                })

                let url = System.URL.FIND_PRODUCT_SECONDARY_TYPE_LIST
                url = url + "?productPrimaryTypeId=" + item["id"]
                System.fetchGet(url,jwt,(data)=>{

                    if ( data.code!==100 ) {
                        this.setState({
                            isTipModalOpen: true,
                            modalContent: JSON.stringify(data)
                        })
                        return
                    }

                    // alert(JSON.stringify(data))

                    this.setState({
                        productSecondaryTypeList: data.productSecondaryTypeList,
                        productSecondaryTypeIdOfSelect: "",
                        productSecondaryTypeNameOfSelect: "二级类目全部"
                    },()=>{
                        this.onClickSearchProductInfoListButton()
                    })
                })
            }
        })

        if (key===""){
            this.setState({
                productPrimaryTypeIdOfSelect: "",
                productPrimaryTypeNameOfSelect: "一级类目全部",
                productSecondaryTypeIdOfSelect: "",
                productSecondaryTypeNameOfSelect: "二级类目全部",
                productSecondaryTypeList:[]
            },()=>{
                this.onClickSearchProductInfoListButton()
            })
        }
    }



    onClickSearchProductSecondaryTypeDropdown = ({ key }) => {

        this.state.productSecondaryTypeList.map(item=>{
            if(item["id"]===key){

                this.setState({
                    productSecondaryTypeIdOfSelect: item["id"],
                    productSecondaryTypeNameOfSelect: item["name"]
                },()=>{
                    this.onClickSearchProductInfoListButton()
                })
            }
        })

        if (key===""){
            this.setState({
                productSecondaryTypeIdOfSelect: "",
                productSecondaryTypeNameOfSelect: "二级类目全部"
            },()=>{
                this.onClickSearchProductInfoListButton()
            })
        }
    }



    onChangeSearchProductInfoListInput = (e) =>{

        this.setState({
            keywordOfSearchProductInfoName: e.target.value
        })
    }



    onClickSearchProductInfoListButton = () => {
        const { jwt } = this.props

        this.comQueryButton.focus()
        this.comQueryButton.blur()

        let url = System.URL.FIND_PRODUCT_INFO_LIST_BY_KEYWORD
        url = url + "?page=" + this.state.page
        url = url + "&pageSize=" + this.state.pageSize
        if( this.state.keywordOfSearchProductInfoName !== "" ) {
            url = url + "&keywordOfSearchProductInfoName=" + this.state.keywordOfSearchProductInfoName
        }
        if( this.state.productPrimaryTypeIdOfSelect !== "" ) {
            url = url + "&productPrimaryTypeId=" + this.state.productPrimaryTypeIdOfSelect
        }
        if( this.state.productSecondaryTypeIdOfSelect !== "" ) {
            url = url + "&productSecondaryTypeId=" + this.state.productSecondaryTypeIdOfSelect
        }
        System.fetchGet(url,jwt,(data)=>{

            if ( data.code!==100 ) {
                this.setState({
                    isTipModalOpen: true,
                    modalContent: JSON.stringify(data)
                })
                return
            }

            // alert(JSON.stringify(data))

            this.setState({
                productInfoList: data.productInfoList,
                resultCount: data.resultCount
            })
        })
    }



    onChangeProductPrimaryTypeAddModalIdInput = (e) => {

        let productPrimaryTypeAddModalData = this.state.productPrimaryTypeAddModalData
        productPrimaryTypeAddModalData.id = e.target.value

        this.setState({
            productPrimaryTypeAddModalData: productPrimaryTypeAddModalData
        })
    }



    onChangeProductPrimaryTypeAddModalNameInput = (e) => {

        let productPrimaryTypeAddModalData = this.state.productPrimaryTypeAddModalData
        productPrimaryTypeAddModalData.name = e.target.value

        this.setState({
            productPrimaryTypeAddModalData: productPrimaryTypeAddModalData
        })
    }



    onChangeProductPrimaryTypeAddModalSortInput = (e) => {

        let productPrimaryTypeAddModalData = this.state.productPrimaryTypeAddModalData
        productPrimaryTypeAddModalData.sort = e.target.value

        this.setState({
            productPrimaryTypeAddModalData: productPrimaryTypeAddModalData
        })
    }



    onClickProductPrimaryTypeAddButton = () => {
        const { jwt } = this.props
        this.comQueryButton.focus()
        this.comQueryButton.blur()

        let url = System.URL.ADD_PRODUCT_PRIMARY_TYPE
        url = url + "?id=" + this.state.productPrimaryTypeAddModalData.id
        url = url + "&name=" + this.state.productPrimaryTypeAddModalData.name
        url = url + "&sort=" + this.state.productPrimaryTypeAddModalData.sort
        System.fetchGet(url,jwt,(data)=>{

            if ( data.code!==100 ) {
                this.setState({
                    isTipModalOpen: true,
                    modalContent: JSON.stringify(data)
                })
                return
            }

            this.setState({
                isProductPrimaryTypeManageModalOpen:true,
                isProductPrimaryTypeAddModalOpen:false
            })

            this.updateProductPrimaryTypeList()

        })
    }



    onClickWillDeleteProductPrimaryTypeButton = (productPrimaryTypeId) =>{
        this.comQueryButton.focus()
        this.comQueryButton.blur()

        this.setState({
            isConfirmDeleteProductPrimaryTypeModalOpen: true,
            isProductPrimaryTypeManageModalOpen:false,
            productPrimaryTypeIdOfWillDelete: productPrimaryTypeId
        })
    }



    onClickModifyProductPrimaryTypeButton = (productPrimaryTypeId) =>{
        const { jwt } = this.props
        this.comQueryButton.focus()
        this.comQueryButton.blur()

        let url = System.URL.FINF_PRODUCT_PRIMARY_TYPE_BY_ID
        url = url + "?productPrimaryTypeId=" + productPrimaryTypeId
        System.fetchGet(url,jwt,(data)=>{

            if ( data.code!==100 ) {
                this.setState({
                    isTipModalOpen: true,
                    modalContent: JSON.stringify(data)
                })
                return
            }



            let productPrimaryTypeModifyModalData = this.state.productPrimaryTypeModifyModalData
            productPrimaryTypeModifyModalData.id = data.productPrimaryType.id
            productPrimaryTypeModifyModalData.name = data.productPrimaryType.name
            productPrimaryTypeModifyModalData.sort = ""+data.productPrimaryType.sort

            this.setState({
                isProductPrimaryTypeModifyModalOpen: true,
                isProductPrimaryTypeManageModalOpen:false,
                productPrimaryTypeModifyModalData: productPrimaryTypeModifyModalData
            })


        })
    }



    onClickConfirmDeleteProductPrimaryType = (productPrimaryTypeId) =>{
        const { jwt } = this.props
        this.comQueryButton.focus()
        this.comQueryButton.blur()

        let url = System.URL.DELETE_PRODUCT_PRIMARY_TYPE
        url = url+"?productPrimaryTypeId="+productPrimaryTypeId
        System.fetchGet(url,jwt,(data)=>{
            if ( data.code!==100 ) {
                this.setState({
                    isTipModalOpen: true,
                    modalContent: JSON.stringify(data),
                    isConfirmDeleteModalOpen:false
                })
                return
            }

            this.setState({
                productPrimaryTypeIdOfSelect: "",
                productPrimaryTypeNameOfSelect: "一级类目全部",
                productSecondaryTypeIdOfSelect: "",
                productSecondaryTypeNameOfSelect: "二级类目全部",

                // isTipModalOpen: true,
                // modalContent: "删除成功",

                isConfirmDeleteProductPrimaryTypeModalOpen: false,
                isProductPrimaryTypeManageModalOpen:true,
            },()=>{
                this.updateProductPrimaryTypeList()
                this.onClickSearchProductInfoListButton()
            })

            message.success("删除成功")

        })
    }



    onClickModifyButtonOfProductPrimaryTypeModifyModal = ()=>{
        const { jwt } = this.props
        this.comQueryButton.focus()
        this.comQueryButton.blur()


        let url = System.URL.UPDATE_PRODUCT_PRIMARY_TYPE
        url = url+"?productPrimaryTypeId="+this.state.productPrimaryTypeModifyModalData.id
        url = url+"&productPrimaryTypeName="+this.state.productPrimaryTypeModifyModalData.name
        url = url+"&productPrimaryTypeSort="+this.state.productPrimaryTypeModifyModalData.sort
        System.fetchGet(url,jwt,(data)=>{
            if ( data.code!==100 ) {
                this.setState({
                    isTipModalOpen: true,
                    modalContent: JSON.stringify(data),
                    isConfirmDeleteModalOpen:false
                })
                return
            }

            this.setState({
                isProductPrimaryTypeManageModalOpen: true,
                isProductPrimaryTypeModifyModalOpen: false,
            },()=>{
                this.updateProductPrimaryTypeList()
            })

            message.success("修改成功")

        })
    }



    updateProductSecondaryTypeListOfProductSecondaryTypeManageModal= (productPrimaryTypeId)=> {

        const { jwt } = this.props
        if(productPrimaryTypeId!==""){
            let url = System.URL.FIND_PRODUCT_SECONDARY_TYPE_LIST
            url = url+"?productPrimaryTypeId="+productPrimaryTypeId
            System.fetchGet(url,jwt,(data)=>{
                if ( data.code!==100 ) {
                    this.setState({
                        isTipModalOpen: true,
                        modalContent: JSON.stringify(data),
                        isConfirmDeleteModalOpen:false
                    })
                    return
                }

                let productSecondaryTypeManageModalData = this.state.productSecondaryTypeManageModalData
                productSecondaryTypeManageModalData.productSecondaryTypeList = data.productSecondaryTypeList

                this.setState({
                    productSecondaryTypeManageModalData: productSecondaryTypeManageModalData
                })

            })
        }else {
            let url = System.URL.FIND_ALL_PRODUCT_SECONDARY_TYPE_LIST
            System.fetchGet(url,jwt,(data)=>{
                if ( data.code!==100 ) {
                    this.setState({
                        isTipModalOpen: true,
                        modalContent: JSON.stringify(data),
                        isConfirmDeleteModalOpen:false
                    })
                    return
                }

                let productSecondaryTypeManageModalData = this.state.productSecondaryTypeManageModalData
                productSecondaryTypeManageModalData.productSecondaryTypeList = data.productSecondaryTypeList

                this.setState({
                    productSecondaryTypeManageModalData: productSecondaryTypeManageModalData
                })

            })
        }

    }



    onClickProductPrimaryTypeDropdownOfProductSecondaryTypeManageModal = ({ key }) =>{

        let productSecondaryTypeManageModalData = this.state.productSecondaryTypeManageModalData

        this.state.productPrimaryTypeList.map(item=>{
            if(item["id"]===key){

                productSecondaryTypeManageModalData.productPrimaryTypeIdOfSelect = item["id"]
                productSecondaryTypeManageModalData.productPrimaryTypeNameOfSelect = item["name"]

                this.updateProductSecondaryTypeListOfProductSecondaryTypeManageModal(item["id"])
            }
        })

        if (key===""){
            productSecondaryTypeManageModalData.productPrimaryTypeIdOfSelect = ""
            productSecondaryTypeManageModalData.productPrimaryTypeNameOfSelect = "一级类目全部"

            this.updateProductSecondaryTypeListOfProductSecondaryTypeManageModal("")
        }

        this.setState({
            productSecondaryTypeManageModalData: productSecondaryTypeManageModalData
        })
    }



    onClickProductPrimaryTypeDropdownOfProductSecondaryTypeAddModal = ({ key }) =>{

        let productSecondaryTypeManageModalData = this.state.productSecondaryTypeManageModalData

        this.state.productPrimaryTypeList.map(item=>{
            if(item["id"]===key){
                productSecondaryTypeManageModalData.productPrimaryTypeIdOfAdd = item["id"]
                productSecondaryTypeManageModalData.productPrimaryTypeNameOfAdd = item["name"]
            }
        })

        if (key===""){
            productSecondaryTypeManageModalData.productPrimaryTypeIdOfAdd = ""
            productSecondaryTypeManageModalData.productPrimaryTypeNameOfAdd = "一级类目全部"
        }

        this.setState({
            productSecondaryTypeManageModalData: productSecondaryTypeManageModalData
        })
    }



    onClickProductSecondaryTypeAddButton = () => {
        const { jwt } = this.props
        this.comQueryButton.focus()
        this.comQueryButton.blur()

        if(this.state.productSecondaryTypeManageModalData.productPrimaryTypeIdOfAdd===""){
            message.warn('请选择"归属一级目录"')
            return
        }

        let url = System.URL.ADD_PRODUCT_SECONDARY_TYPE
        url = url + "?id=" + this.state.productSecondaryTypeManageModalData.productSecondaryTypeIdOfAdd
        url = url + "&name=" + this.state.productSecondaryTypeManageModalData.productSecondaryTypeNameOfAdd
        url = url + "&productPrimaryTypeId=" + this.state.productSecondaryTypeManageModalData.productPrimaryTypeIdOfAdd
        url = url + "&sort=" + this.state.productSecondaryTypeManageModalData.productSecondaryTypeSortOfAdd
        System.fetchGet(url,jwt,(data)=>{

            if ( data.code!==100 ) {
                this.setState({
                    isTipModalOpen: true,
                    modalContent: JSON.stringify(data)
                })
                return
            }


            let productSecondaryTypeManageModalData = this.state.productSecondaryTypeManageModalData
            productSecondaryTypeManageModalData.isProductSecondaryTypeManageModalOpen = true
            productSecondaryTypeManageModalData.isProductSecondaryTypeAddModalOpen = false

            this.setState({
                productSecondaryTypeManageModalData: productSecondaryTypeManageModalData
            })


            this.updateProductSecondaryTypeListOfProductSecondaryTypeManageModal(
                this.state.productSecondaryTypeManageModalData.productPrimaryTypeIdOfSelect
            )
            message.success("添加成功")

        })
    }



    onClickConfirmDeleteProductSecondaryType = (productSecondaryTypeId) =>{
        const { jwt } = this.props
        this.comQueryButton.focus()
        this.comQueryButton.blur()

        let url = System.URL.DELETE_PRODUCT_SECONDARY_TYPE
        url = url+"?productSecondaryTypeId="+productSecondaryTypeId
        System.fetchGet(url,jwt,(data)=>{
            if ( data.code!==100 ) {
                this.setState({
                    isTipModalOpen: true,
                    modalContent: JSON.stringify(data),
                    isConfirmDeleteModalOpen:false
                })
                return
            }

            let productSecondaryTypeManageModalData = this.state.productSecondaryTypeManageModalData
            productSecondaryTypeManageModalData.isProductSecondaryTypeManageModalOpen = true
            productSecondaryTypeManageModalData.isProductSecondaryTypeConfirmDeleteModalOpen = false

            this.setState({
                productSecondaryTypeManageModalData: productSecondaryTypeManageModalData
            },()=>{
                this.updateProductSecondaryTypeListOfProductSecondaryTypeManageModal(this.state.productSecondaryTypeManageModalData.productPrimaryTypeIdOfSelect)
            })

            message.success("删除成功")

        })
    }



    onClickProductPrimaryTypeDropdownOfProductSecondaryTypeModifyModal = ({ key }) =>{

        let productSecondaryTypeManageModalData = this.state.productSecondaryTypeManageModalData

        this.state.productPrimaryTypeList.map(item=>{
            if(item["id"]===key){
                productSecondaryTypeManageModalData.productPrimaryTypeIdOfModify = item["id"]
                productSecondaryTypeManageModalData.productPrimaryTypeNameOfModify = item["name"]
            }
        })

        if (key===""){
            productSecondaryTypeManageModalData.productPrimaryTypeIdOfModify = ""
            productSecondaryTypeManageModalData.productPrimaryTypeNameOfModify = "一级类目全部"
        }

        this.setState({
            productSecondaryTypeManageModalData: productSecondaryTypeManageModalData
        })
    }



    onClickModifyButtonOfProductSecondaryTypeModifyModal = ()=>{
        const { jwt } = this.props
        this.comQueryButton.focus()
        this.comQueryButton.blur()

        if(this.state.productSecondaryTypeManageModalData.productPrimaryTypeIdOfModify===""){
            message.warn('请选择"归属一级目录"')
            return
        }

        let url = System.URL.UPDATE_PRODUCT_SECONDARY_TYPE
        url = url+"?productSecondaryTypeId="+this.state.productSecondaryTypeManageModalData.productSecondaryTypeIdOfModify
        url = url+"&productSecondaryTypeName="+this.state.productSecondaryTypeManageModalData.productSecondaryTypeNameOfModify
        url = url+"&productPrimaryTypeId="+this.state.productSecondaryTypeManageModalData.productPrimaryTypeIdOfModify
        url = url+"&productSecondaryTypeSort="+this.state.productSecondaryTypeManageModalData.productSecondaryTypeSortOfModify

        System.fetchGet(url,jwt,(data)=>{
            if ( data.code!==100 ) {
                this.setState({
                    isTipModalOpen: true,
                    modalContent: JSON.stringify(data),
                    isConfirmDeleteModalOpen:false
                })
                return
            }

            let productSecondaryTypeManageModalData = this.state.productSecondaryTypeManageModalData
            productSecondaryTypeManageModalData.isProductSecondaryTypeManageModalOpen = true
            productSecondaryTypeManageModalData.isProductSecondaryTypeModifyModalOpen = false

            this.setState({
                productSecondaryTypeManageModalData: productSecondaryTypeManageModalData
            },()=>{
                this.updateProductSecondaryTypeListOfProductSecondaryTypeManageModal(productSecondaryTypeManageModalData.productPrimaryTypeIdOfSelect)
            })

            message.success("修改成功")

        })
    }



    onClickAddButtonInAddProductModal = ()=>{

    }

    

    render() {

        const { isLogin, mobilephone, clientHeight } = this.props


        let productInfoListContent = []


        productInfoListContent.push(
            <Row align="middle" style={{ width: "100%",color:"white"}}>
                <Col className="hwBlueBackground" align="middle" flex="80px" style={{}}>
                    编码
                </Col>
                <Col className="hwBlueBackground" align="middle" flex="80px" style={{}}>
                    名称
                </Col>
                <Col className="hwBlueBackground" align="middle" flex="80px" style={{}}>
                    品牌
                </Col>
                <Col className="hwBlueBackground" align="middle"flex="80px" style={{}}>
                    型号
                </Col>
                <Col className="hwBlueBackground" align="middle" flex="80px" style={{}}>
                    规格
                </Col>
                <Col className="hwBlueBackground" align="middle" flex="80px" style={{}}>
                    单位
                </Col>
                <Col className="hwBlueBackground" align="middle" flex="80px" style={{}}>
                    二级类目
                </Col>
                <Col className="hwBlueBackground" align="middle" flex="80px" style={{}}>
                    成本
                </Col>
                <Col className="hwBlueBackground" align="middle" flex="80px" style={{}}>
                    售价
                </Col>
                <Col className="hwBlueBackground" align="middle" flex="80px" style={{}}>
                    状态
                </Col>
                <Col className="hwBlueBackground" align="middle" flex="auto" style={{}}>
                    -
                </Col>
            </Row>
        )
        productInfoListContent.push(
            this.state.productInfoList.map((item,index) => (
                <Row align="middle" style={{ width: "100%"}}>
                    <Col className={"HWEllipase"} align="middle" style={{backgroundColor:index%2===1?"lightgray":"white", width:"80px", wordWrap:'break-word'}}>
                        <Tooltip title={item.id}>
                            {item.id}
                        </Tooltip>
                    </Col>
                    <Col align="middle" style={{backgroundColor:index%2===1?"lightgray":"white", width:"80px", wordWrap:'break-word'}}>
                        <Tooltip title={item.name}>
                            {item.name}
                        </Tooltip>
                    </Col>
                    <Col align="middle" style={{backgroundColor:index%2===1?"lightgray":"white", width:"80px", wordWrap:'break-word'}}>
                        <Tooltip title={item.brand}>
                            {item.brand}
                        </Tooltip>
                    </Col>
                    <Col align="middle" style={{backgroundColor:index%2===1?"lightgray":"white", width:"80px", wordWrap:'break-word'}}>
                        <Tooltip title={item.model}>
                            {item.model}
                        </Tooltip>
                    </Col>
                    <Col className={"HWEllipase"} align="middle" style={{backgroundColor:index%2===1?"lightgray":"white", width:"80px", wordWrap:'break-word'}}>
                        <Tooltip title={item.specification}>
                            {item.specification}
                        </Tooltip>
                    </Col>
                    <Col align="middle" style={{backgroundColor:index%2===1?"lightgray":"white", width:"80px", wordWrap:'break-word'}}>
                        <Tooltip title={item.unit}>
                            {item.unit}
                        </Tooltip>
                    </Col>
                    <Col align="middle" style={{backgroundColor:index%2===1?"lightgray":"white", width:"80px", wordWrap:'break-word'}}>
                        <Tooltip title={item.productSecondaryType.name}>
                            {item.productSecondaryType.name}
                        </Tooltip>
                    </Col>
                    <Col align="middle" style={{backgroundColor:index%2===1?"lightgray":"white", width:"80px", wordWrap:'break-word'}}>
                        <Tooltip title={item.costPrice}>
                            {item.costPrice}
                        </Tooltip>
                    </Col>
                    <Col align="middle" style={{backgroundColor:index%2===1?"lightgray":"white", width:"80px", wordWrap:'break-word'}}>
                        <Tooltip title={item.sellPrice}>
                            {item.sellPrice}
                        </Tooltip>
                    </Col>
                    <Col align="middle" style={{backgroundColor:index%2===1?"lightgray":"white", width:"80px", wordWrap:'break-word'}}>
                        <Tooltip title={item.status.name}>
                            {item.status.name}
                        </Tooltip>
                    </Col>
                    <Col align="middle" flex={"auto"} style={{backgroundColor:index%2===1?"lightgray":"white", wordWrap:'break-word'}}>
                        -
                    </Col>
                    <Col align="middle" flex="70px" style={{}}>
                        <Button onClick={(partnerId)=>this.onClickModifyPartnerButton(item.id)}>编辑</Button>
                    </Col>
                    <Col align="middle" flex="70px" style={{}}>
                        <Button onClick={(partnerId)=>{this.onClickWillDeletePartner(item.id)}}>删除</Button>
                    </Col>
                    <Col align="middle" flex="70px" style={{}}>
                        <Button onClick={(partnerId,partnerName)=>{this.onClickZhenxuanButton(item.id, item.name)}}>详情</Button>
                    </Col>
                </Row>
            ))
        )


        let productPrimaryTypeListContentInModal = []
        productPrimaryTypeListContentInModal.push(
            this.state.productPrimaryTypeList.map((item,index) => (
                <Row align="middle" style={{ width: "100%"}}>
                    <Col flex={"30%"} className={"HWEllipase"} align="middle" style={{backgroundColor:index%2===1?"lightgray":"white", wordWrap:'break-word'}}>
                        <Tooltip title={item.id}>
                            {item.id}
                        </Tooltip>
                    </Col>
                    <Col flex={"20%"} align="middle" style={{backgroundColor:index%2===1?"lightgray":"white", wordWrap:'break-word'}}>
                        <Tooltip title={item.name}>
                            {item.name}
                        </Tooltip>
                    </Col>
                    <Col flex={"20%"} align="middle" style={{backgroundColor:index%2===1?"lightgray":"white", wordWrap:'break-word'}}>
                        {item.sort}
                    </Col>
                    <Col align="middle" flex={"auto"} style={{backgroundColor:index%2===1?"lightgray":"white", wordWrap:'break-word'}}>
                        -
                    </Col>
                    <Col align="middle" flex="70px" style={{}}>
                        <Button onClick={(productPrimaryTypeId)=>this.onClickModifyProductPrimaryTypeButton(item.id)}>编辑</Button>
                    </Col>
                    <Col align="middle" flex="70px" style={{}}>
                        <Button onClick={(productPrimaryTypeId)=>{this.onClickWillDeleteProductPrimaryTypeButton(item.id)}}>删除</Button>
                    </Col>
                </Row>
            ))
        )



        let productSecondaryTypeListContentInModal = []
        productSecondaryTypeListContentInModal.push(
            this.state.productSecondaryTypeManageModalData.productSecondaryTypeList.map((item,index) => (
                <Row align="middle" style={{ width: "100%"}}>
                    <Col flex={"20%"} className={"HWEllipase"} align="middle" style={{backgroundColor:index%2===1?"lightgray":"white", wordWrap:'break-word'}}>
                        <Tooltip title={item.id}>
                            {item.id}
                        </Tooltip>
                    </Col>
                    <Col flex={"20%"} align="middle" style={{backgroundColor:index%2===1?"lightgray":"white", wordWrap:'break-word'}}>
                        {item.name}
                    </Col>
                    <Col flex={"20%"} align="middle" style={{backgroundColor:index%2===1?"lightgray":"white", wordWrap:'break-word'}}>
                        {item.productPrimaryType.name}
                    </Col>
                    <Col flex={"20%"} align="middle" style={{backgroundColor:index%2===1?"lightgray":"white", wordWrap:'break-word'}}>
                        {item.sort}
                    </Col>
                    <Col align="middle" flex={"auto"} style={{backgroundColor:index%2===1?"lightgray":"white", wordWrap:'break-word'}}>
                        -
                    </Col>
                    <Col align="middle" flex="70px" style={{}}>
                        <Button onClick={()=>{
                            const { jwt } = this.props
                            this.comQueryButton.focus()
                            this.comQueryButton.blur()

                            let url = System.URL.FINF_PRODUCT_SECONDARY_TYPE_BY_ID
                            url = url + "?productSecondaryTypeId=" + item.id
                            System.fetchGet(url,jwt,(data)=>{

                                if ( data.code!==100 ) {
                                    this.setState({
                                        isTipModalOpen: true,
                                        modalContent: JSON.stringify(data)
                                    })
                                    return
                                }

                                let productSecondaryTypeManageModalData = this.state.productSecondaryTypeManageModalData
                                productSecondaryTypeManageModalData.productSecondaryTypeIdOfModify = data.productSecondaryType.id
                                productSecondaryTypeManageModalData.productSecondaryTypeNameOfModify = data.productSecondaryType.name
                                productSecondaryTypeManageModalData.productPrimaryTypeIdOfModify = data.productSecondaryType.productPrimaryType.id
                                productSecondaryTypeManageModalData.productPrimaryTypeNameOfModify = data.productSecondaryType.productPrimaryType.name
                                productSecondaryTypeManageModalData.productSecondaryTypeSortOfModify = ""+data.productSecondaryType.sort
                                productSecondaryTypeManageModalData.isProductSecondaryTypeModifyModalOpen = true
                                productSecondaryTypeManageModalData.isProductSecondaryTypeManageModalOpen = false

                                this.setState({productSecondaryTypeManageModalData:productSecondaryTypeManageModalData})

                            })




                        }}>编辑</Button>
                    </Col>
                    <Col align="middle" flex="70px" style={{}}>
                        <Button
                            onClick={()=>{
                                this.comQueryButton.focus()
                                this.comQueryButton.blur()

                                let productSecondaryTypeManageModalData = this.state.productSecondaryTypeManageModalData
                                productSecondaryTypeManageModalData.isProductSecondaryTypeConfirmDeleteModalOpen = true
                                productSecondaryTypeManageModalData.isProductSecondaryTypeManageModalOpen = false
                                productSecondaryTypeManageModalData.productSecondaryTypeIdOfWillDelete = item.id

                                this.setState({
                                    productSecondaryTypeManageModalData: productSecondaryTypeManageModalData
                                })
                            }}

                                // this.setState({
                                //     isConfirmDeleteProductPrimaryTypeModalOpen: true,
                                //     isProductPrimaryTypeManageModalOpen:false,
                                //     productPrimaryTypeIdOfWillDelete: productPrimaryTypeId
                                // })
                                // this.onClickWillDeleteProductPrimaryTypeButton()}
                        >删除</Button>
                    </Col>
                </Row>
            ))
        )


        let productPrimaryTypeDropdownMenuItems = this.state.productPrimaryTypeList.map((item,index) => (
            {label:item.name, key:item.id}
        ))
        productPrimaryTypeDropdownMenuItems.unshift({label:"一级类目全部", key:""})


        let productSecondaryTypeDropdownMenuItems = this.state.productSecondaryTypeList.map((item,index) => (
            {label:item.name, key:item.id}
        ))
        productSecondaryTypeDropdownMenuItems.unshift({label:"二级类目全部", key:""})


        let statusDropdownMenuItems = this.state.statusListOfAll.map((item,index) => (
            {label:item.name, key:item.id}
        ))


        return (
            <div className="horizontalCenter" style={this.props.isShow?{}:{display:"none"}}>
                <Modal wrap title="提示" width={800} open={this.state.isTipModalOpen} onOk={()=>this.setState({isTipModalOpen:false})} onCancel={()=>this.setState({isTipModalOpen:false})}>
                    {this.state.modalContent}
                </Modal>


                <Modal  maskClosable={false} title="一级类目管理" width={1000} style={{}} footer={[]} open={this.state.isProductPrimaryTypeManageModalOpen} onCancel={()=>this.setState({isProductPrimaryTypeManageModalOpen:false})}>
                    <Space direction="vertical" style={{width   :"100%", minHeight:"400px"}}>

                        <Row align="middle" style={{ width: "100%"}}>
                            <Col flex="auto">
                            </Col>
                            <Col align="right" flex="10%">
                                <Button type="primary" onClick={()=>{
                                    this.comQueryButton.focus();
                                    this.comQueryButton.blur();
                                    this.setState({isProductPrimaryTypeManageModalOpen:false, isProductPrimaryTypeAddModalOpen:true})}}>新增</Button>
                            </Col>
                        </Row>
                        <Row align="middle" style={{ width: "100%",color:"white"}}>
                            <Col className="hwBlueBackground" align="middle" flex="30%" style={{}}>
                                编码
                            </Col>
                            <Col className="hwBlueBackground" align="middle" flex="20%" style={{}}>
                                名称
                            </Col>
                            <Col className="hwBlueBackground" align="middle" flex="20%" style={{}}>
                                排序
                            </Col>
                            <Col className="hwBlueBackground" align="middle" flex="auto" style={{}}>
                                -
                            </Col>
                        </Row>
                        <Row align="middle" style={{ width: "100%"}}>
                            <div style={{width:"100%", maxHeight:"300px", overflowY:"auto"}}>
                                <Space direction="vertical" style={{width:"100%"}}>
                                    {productPrimaryTypeListContentInModal}
                                </Space>
                            </div>
                        </Row>
                    </Space>
                </Modal>

                <Modal maskClosable={false} title="一级类目管理-增加" width={1000} style={{}}
                        open={this.state.isProductPrimaryTypeAddModalOpen}
                        onCancel={()=>{
                            this.comQueryButton.focus();
                            this.comQueryButton.blur();
                            this.setState({isProductPrimaryTypeManageModalOpen:true,isProductPrimaryTypeAddModalOpen:false})
                        }}
                        onOk={this.onClickProductPrimaryTypeAddButton}
                >
                    <Space direction="vertical" style={{width   :"100%", minHeight:"400px"}}>
                        <Row align="middle" style={{ width: "100%"}}>
                            <Col flex="10%"></Col>
                            <Col flex="auto">
                                <Space direction="vertical" style={{width:"100%"}}>
                                    <Row align="middle" style={{ width: "100%"}}>
                                        <Col align="right" flex="100px">编码：</Col>
                                        <Col flex="auto">
                                            <Tooltip title={"规则：productPrimaryType_超类目_一级类目"}>
                                                <Input value={this.state.productPrimaryTypeAddModalData.id} style={{ width: "100%"}}
                                                       onChange={this.onChangeProductPrimaryTypeAddModalIdInput}
                                                ></Input>
                                            </Tooltip>
                                        </Col>
                                        <Col flex="100px" style={{}}>
                                        </Col>
                                    </Row>
                                    <Row align="middle" style={{ width: "100%"}}>
                                        <Col align="right" flex="100px">名称：</Col>
                                        <Col flex="auto">
                                            <Tooltip title={"例：网络设备、服务器、辅材"}>
                                                <Input value={this.state.productPrimaryTypeAddModalData.name} style={{ width: "100%"}} onChange={this.onChangeProductPrimaryTypeAddModalNameInput}></Input>
                                            </Tooltip>
                                        </Col>
                                        <Col flex="100px" style={{}}>
                                        </Col>
                                    </Row>
                                    <Row align="middle" style={{ width: "100%"}}>
                                        <Col align="right" flex="100px">排序：</Col>
                                        <Col flex="auto">
                                            <Input value={this.state.productPrimaryTypeAddModalData.sort} style={{ width: "100%"}} onChange={this.onChangeProductPrimaryTypeAddModalSortInput}></Input>
                                        </Col>
                                        <Col flex="100px" style={{}}>
                                        </Col>
                                    </Row>
                                </Space>
                            </Col>
                            <Col flex="10%"></Col>
                        </Row>
                    </Space>
                </Modal>

                <Modal maskClosable={false} title="一级类目管理-修改" width={1000} style={{}}
                       open={this.state.isProductPrimaryTypeModifyModalOpen}
                       onCancel={()=>{
                           this.comQueryButton.focus();
                           this.comQueryButton.blur();
                           this.setState({isProductPrimaryTypeManageModalOpen:true,isProductPrimaryTypeModifyModalOpen:false})
                       }}
                       onOk={this.onClickModifyButtonOfProductPrimaryTypeModifyModal}
                >
                    <Space direction="vertical" style={{width   :"100%", minHeight:"400px"}}>
                        <Row align="middle" style={{ width: "100%"}}>
                            <Col flex="10%"></Col>
                            <Col flex="auto">
                                <Space direction="vertical" style={{width:"100%"}}>
                                    <Row align="middle" style={{ width: "100%"}}>
                                        <Col align="right" flex="100px">编码：</Col>
                                        <Col flex="auto">
                                            <Input disabled="disabled" value={this.state.productPrimaryTypeModifyModalData.id} style={{ width: "100%"}}
                                                   onChange={(e)=>{
                                                       let productPrimaryTypeModifyModalData = this.state.productPrimaryTypeModifyModalData
                                                       productPrimaryTypeModifyModalData.id = e.target.value
                                                       this.setState({
                                                           productPrimaryTypeModifyModalData: productPrimaryTypeModifyModalData
                                                       })
                                                   }}
                                            ></Input>
                                        </Col>
                                        <Col flex="100px" style={{}}>
                                        </Col>
                                    </Row>
                                    <Row align="middle" style={{ width: "100%"}}>
                                        <Col align="right" flex="100px">名称：</Col>
                                        <Col flex="auto">
                                            <Tooltip title={"例：网络设备、服务器、辅材"}>
                                                <Input value={this.state.productPrimaryTypeModifyModalData.name} style={{ width: "100%"}}
                                                       onChange={(e)=>{
                                                           let productPrimaryTypeModifyModalData = this.state.productPrimaryTypeModifyModalData
                                                           productPrimaryTypeModifyModalData.name = e.target.value
                                                           this.setState({
                                                               productPrimaryTypeModifyModalData: productPrimaryTypeModifyModalData
                                                           })
                                                       }}></Input>
                                            </Tooltip>
                                        </Col>
                                        <Col flex="100px" style={{}}>
                                        </Col>
                                    </Row>
                                    <Row align="middle" style={{ width: "100%"}}>
                                        <Col align="right" flex="100px">排序：</Col>
                                        <Col flex="auto">
                                            <Input value={this.state.productPrimaryTypeModifyModalData.sort} style={{ width: "100%"}}
                                                   onChange={(e)=>{
                                                       let productPrimaryTypeModifyModalData = this.state.productPrimaryTypeModifyModalData
                                                       productPrimaryTypeModifyModalData.sort = e.target.value
                                                       this.setState({
                                                           productPrimaryTypeModifyModalData: productPrimaryTypeModifyModalData
                                                       })
                                                   }}></Input>
                                        </Col>
                                        <Col flex="100px" style={{}}>
                                        </Col>
                                    </Row>
                                </Space>
                            </Col>
                            <Col flex="10%"></Col>
                        </Row>
                    </Space>
                </Modal>

                <Modal
                    maskClosable={false}
                    title="一级类目管理-删除"
                    open={this.state.isConfirmDeleteProductPrimaryTypeModalOpen}
                    onOk={(productPrimaryTypeId)=>this.onClickConfirmDeleteProductPrimaryType(this.state.productPrimaryTypeIdOfWillDelete)}
                    onCancel={()=>{
                        this.comQueryButton.focus()
                        this.comQueryButton.blur()

                        this.setState({
                            isConfirmDeleteProductPrimaryTypeModalOpen: false,
                            isProductPrimaryTypeManageModalOpen: true,
                            productPrimaryTypeIdOfWillDelete: ""
                        })
                    }}
                >
                    <p>是否删除</p>
                </Modal>



                <Modal maskClosable={false} title="二级类目管理" width={1000} style={{}} footer={[]} open={this.state.productSecondaryTypeManageModalData.isProductSecondaryTypeManageModalOpen}
                        onCancel={()=>{
                            let productSecondaryTypeManageModalData = this.state.productSecondaryTypeManageModalData
                            productSecondaryTypeManageModalData.isProductSecondaryTypeManageModalOpen = false
                            this.setState({productSecondaryTypeManageModalData:productSecondaryTypeManageModalData})
                        }}>
                    <Space direction="vertical" style={{width   :"100%", minHeight:"400px"}}>

                        <Row align="middle" style={{ width: "100%"}}>
                            <Col flex="auto">
                            </Col>
                            <Col>
                                <Dropdown trigger={"click"} menu={{items:productPrimaryTypeDropdownMenuItems, onClick:this.onClickProductPrimaryTypeDropdownOfProductSecondaryTypeManageModal}}>
                                    <Button style={{width:"100%"}}>
                                        <Space direction="vertical" align="right" style={{width:"100%"}}>
                                            <Row style={{}}>
                                                <Col align="center" flex="auto">{this.state.productSecondaryTypeManageModalData.productPrimaryTypeNameOfSelect}</Col>
                                                <Col style={{}}><DownOutlined /></Col>
                                            </Row>
                                        </Space>
                                    </Button>
                                </Dropdown>
                            </Col>
                            <Col flex="1%"></Col>
                            <Col>
                                <Button type="primary" onClick={()=>{
                                    this.comQueryButton.focus()
                                    this.comQueryButton.blur()

                                    let productSecondaryTypeManageModalData = this.state.productSecondaryTypeManageModalData

                                    productSecondaryTypeManageModalData.isProductSecondaryTypeAddModalOpen = true
                                    productSecondaryTypeManageModalData.isProductSecondaryTypeManageModalOpen = false

                                    this.setState({productSecondaryTypeManageModalData:productSecondaryTypeManageModalData})}}>新增</Button>
                            </Col>
                        </Row>
                        <Row align="middle" style={{ width: "100%",color:"white"}}>
                            <Col className="hwBlueBackground" align="middle" flex="20%" style={{}}>
                                编码
                            </Col>
                            <Col className="hwBlueBackground" align="middle" flex="20%" style={{}}>
                                名称
                            </Col>
                            <Col className="hwBlueBackground" align="middle" flex="20%" style={{}}>
                                归属一级类目
                            </Col>
                            <Col className="hwBlueBackground" align="middle" flex="20%" style={{}}>
                                排序
                            </Col>
                            <Col className="hwBlueBackground" align="middle" flex="auto" style={{}}>
                                -
                            </Col>
                        </Row>
                        <Row align="middle" style={{ width: "100%"}}>
                            <div style={{width:"100%", maxHeight:"300px", overflowY:"auto"}}>
                                <Space direction="vertical" style={{width:"100%"}}>
                                    {productSecondaryTypeListContentInModal}
                                </Space>
                            </div>
                        </Row>
                    </Space>
                </Modal>

                <Modal maskClosable={false} title="二级类目管理-增加" width={1000} style={{}}
                       open={this.state.productSecondaryTypeManageModalData.isProductSecondaryTypeAddModalOpen}
                       onCancel={()=>{
                           this.comQueryButton.focus();
                           this.comQueryButton.blur();
                           let productSecondaryTypeManageModalData = this.state.productSecondaryTypeManageModalData
                           productSecondaryTypeManageModalData.isProductSecondaryTypeAddModalOpen = false
                           productSecondaryTypeManageModalData.isProductSecondaryTypeManageModalOpen = true
                           this.setState({productSecondaryTypeManageModalData: productSecondaryTypeManageModalData})
                       }}
                       onOk={this.onClickProductSecondaryTypeAddButton}
                >
                    <Space direction="vertical" style={{width   :"100%", minHeight:"400px"}}>
                        <Row align="middle" style={{ width: "100%"}}>
                            <Col flex="10%"></Col>
                            <Col flex="auto">
                                <Space direction="vertical" style={{width:"100%"}}>
                                    <Row align="middle" style={{ width: "100%"}}>
                                        <Col align="right" flex="100px">编码：</Col>
                                        <Col flex="auto">
                                            <Tooltip title={"规则：productSecondaryType_超类目_一级类目_二级类目"}>
                                                <Input value={this.state.productSecondaryTypeManageModalData.productSecondaryTypeIdOfAdd} style={{ width: "100%"}}
                                                       onChange={(e)=>{
                                                           let productSecondaryTypeManageModalData = this.state.productSecondaryTypeManageModalData
                                                           productSecondaryTypeManageModalData.productSecondaryTypeIdOfAdd = e.target.value
                                                           this.setState({
                                                               productSecondaryTypeManageModalData: productSecondaryTypeManageModalData
                                                           })}
                                                }></Input>
                                            </Tooltip>
                                        </Col>
                                        <Col flex="100px" style={{}}>
                                        </Col>
                                    </Row>
                                    <Row align="middle" style={{ width: "100%"}}>
                                        <Col align="right" flex="100px">名称：</Col>
                                        <Col flex="auto">
                                            <Tooltip title={"例：交换机、应用服务器、水晶头"}>
                                                <Input value={this.state.productSecondaryTypeManageModalData.productSecondaryTypeNameOfAdd}
                                                       style={{ width: "100%"}}
                                                       onChange={(e)=>{
                                                           let productSecondaryTypeManageModalData = this.state.productSecondaryTypeManageModalData
                                                           productSecondaryTypeManageModalData.productSecondaryTypeNameOfAdd = e.target.value
                                                           this.setState({
                                                               productSecondaryTypeManageModalData: productSecondaryTypeManageModalData
                                                           })}
                                                }></Input>
                                            </Tooltip>
                                        </Col>
                                        <Col flex="100px" style={{}}>
                                        </Col>
                                    </Row>
                                    <Row align="middle" style={{ width: "100%"}}>
                                        <Col align="right" flex="100px">归属一级类目：</Col>
                                        <Col flex="auto">
                                            <Dropdown trigger={"click"} menu={{items:productPrimaryTypeDropdownMenuItems, onClick:this.onClickProductPrimaryTypeDropdownOfProductSecondaryTypeAddModal}}>
                                                <Button style={{}}>
                                                    <Space direction="vertical" align="right" style={{width:"100%"}}>
                                                        <Row style={{}}>
                                                            <Col align="center" flex="auto">{this.state.productSecondaryTypeManageModalData.productPrimaryTypeNameOfAdd}</Col>
                                                            <Col style={{}}><DownOutlined /></Col>
                                                        </Row>
                                                    </Space>
                                                </Button>
                                            </Dropdown>
                                        </Col>
                                        <Col flex="100px" style={{}}>
                                        </Col>
                                    </Row>
                                    <Row align="middle" style={{ width: "100%"}}>
                                        <Col align="right" flex="100px">排序：</Col>
                                        <Col flex="auto">
                                            <Input value={this.state.productSecondaryTypeManageModalData.productSecondaryTypeSortOfAdd}
                                                   style={{ width: "100%"}}
                                                   onChange={(e)=>{
                                                       let productSecondaryTypeManageModalData = this.state.productSecondaryTypeManageModalData
                                                       productSecondaryTypeManageModalData.productSecondaryTypeSortOfAdd = e.target.value
                                                       this.setState({
                                                           productSecondaryTypeManageModalData: productSecondaryTypeManageModalData
                                                       })}
                                                   }></Input>
                                        </Col>
                                        <Col flex="100px" style={{}}>
                                        </Col>
                                    </Row>
                                </Space>
                            </Col>
                            <Col flex="10%"></Col>
                        </Row>
                    </Space>
                </Modal>

                <Modal
                    maskClosable={false}
                    title="二级类目管理-删除"
                    open={this.state.productSecondaryTypeManageModalData.isProductSecondaryTypeConfirmDeleteModalOpen}
                    onOk={(productSecondaryTypeId)=>this.onClickConfirmDeleteProductSecondaryType(this.state.productSecondaryTypeManageModalData.productSecondaryTypeIdOfWillDelete)}
                    onCancel={()=>{
                        this.comQueryButton.focus()
                        this.comQueryButton.blur()

                        let productSecondaryTypeManageModalData = this.state.productSecondaryTypeManageModalData
                        productSecondaryTypeManageModalData.isProductSecondaryTypeConfirmDeleteModalOpen = false
                        productSecondaryTypeManageModalData.isProductSecondaryTypeManageModalOpen = true
                        productSecondaryTypeManageModalData.productSecondaryTypeIdOfWillDelete = ""

                        this.setState({
                            productSecondaryTypeManageModalData: productSecondaryTypeManageModalData
                        })
                    }}
                >
                    <p>是否删除</p>
                </Modal>



                <Modal maskClosable={false} title="二级类目管理-修改" width={1000} style={{}}
                       open={this.state.productSecondaryTypeManageModalData.isProductSecondaryTypeModifyModalOpen}
                       onCancel={()=>{
                           this.comQueryButton.focus();
                           this.comQueryButton.blur();

                           let productSecondaryTypeManageModalData = this.state.productSecondaryTypeManageModalData
                           productSecondaryTypeManageModalData.isProductSecondaryTypeModifyModalOpen = false
                           productSecondaryTypeManageModalData.isProductSecondaryTypeManageModalOpen = true

                           this.setState({productSecondaryTypeManageModalData:productSecondaryTypeManageModalData})
                       }}
                       onOk={this.onClickModifyButtonOfProductSecondaryTypeModifyModal}
                >
                    <Space direction="vertical" style={{width   :"100%", minHeight:"400px"}}>
                        <Row align="middle" style={{ width: "100%"}}>
                            <Col flex="10%"></Col>
                            <Col flex="auto">
                                <Space direction="vertical" style={{width:"100%"}}>
                                    <Row align="middle" style={{ width: "100%"}}>
                                        <Col align="right" flex="100px">编码：</Col>
                                        <Col flex="auto">
                                            <Input disabled="disabled" value={this.state.productSecondaryTypeManageModalData.productSecondaryTypeIdOfModify} style={{ width: "100%"}}
                                                   onChange={(e)=>{
                                                       let productSecondaryTypeManageModalData = this.state.productSecondaryTypeManageModalData
                                                       productSecondaryTypeManageModalData.productSecondaryTypeIdOfModify = e.target.value
                                                       this.setState({
                                                           productSecondaryTypeManageModalData: productSecondaryTypeManageModalData
                                                       })
                                                   }}
                                            ></Input>
                                        </Col>
                                        <Col flex="100px" style={{}}>
                                        </Col>
                                    </Row>
                                    <Row align="middle" style={{ width: "100%"}}>
                                        <Col align="right" flex="100px">名称：</Col>
                                        <Col flex="auto">
                                            <Tooltip title={"例：网络设备、服务器、辅材"}>
                                                <Input value={this.state.productSecondaryTypeManageModalData.productSecondaryTypeNameOfModify} style={{ width: "100%"}}
                                                       onChange={(e)=>{
                                                           let productSecondaryTypeManageModalData = this.state.productSecondaryTypeManageModalData
                                                           productSecondaryTypeManageModalData.productSecondaryTypeNameOfModify = e.target.value
                                                           this.setState({
                                                               productSecondaryTypeManageModalData: productSecondaryTypeManageModalData
                                                           })
                                                       }}></Input>
                                            </Tooltip>
                                        </Col>
                                        <Col flex="100px" style={{}}>
                                        </Col>
                                    </Row>
                                    <Row align="middle" style={{ width: "100%"}}>
                                        <Col align="right" flex="100px">归属一级类目：</Col>
                                        <Col flex="auto">
                                            <Dropdown trigger={"click"} menu={{items:productPrimaryTypeDropdownMenuItems, onClick:this.onClickProductPrimaryTypeDropdownOfProductSecondaryTypeModifyModal}}>
                                                <Button style={{width:"160px"}}>
                                                    <Space direction="vertical" align="right" style={{width:"100%"}}>
                                                        <Row style={{}}>
                                                            <Col align="center" flex="auto">{this.state.productSecondaryTypeManageModalData.productPrimaryTypeNameOfModify}</Col>
                                                            <Col style={{}}><DownOutlined /></Col>
                                                        </Row>
                                                    </Space>
                                                </Button>
                                            </Dropdown>
                                        </Col>
                                        <Col flex="100px" style={{}}>
                                        </Col>
                                    </Row>
                                    <Row align="middle" style={{ width: "100%"}}>
                                        <Col align="right" flex="100px">排序：</Col>
                                        <Col flex="auto">
                                            <Input value={this.state.productSecondaryTypeManageModalData.productSecondaryTypeSortOfModify}
                                                   style={{ width: "100%"}}
                                                   onChange={(e)=>{
                                                       let productSecondaryTypeManageModalData = this.state.productSecondaryTypeManageModalData
                                                       productSecondaryTypeManageModalData.productSecondaryTypeSortOfModify = ""+e.target.value
                                                       this.setState({
                                                           productSecondaryTypeManageModalData: productSecondaryTypeManageModalData
                                                       })
                                                   }}></Input>
                                        </Col>
                                        <Col flex="100px" style={{}}>
                                        </Col>
                                    </Row>
                                </Space>
                            </Col>
                            <Col flex="10%"></Col>
                        </Row>
                    </Space>
                </Modal>



                <Modal maskClosable={false} title="新增产品" width={1000} style={{}}
                       open={this.state.addProductModalData.isModalOpen}
                       onCancel={()=>{
                           this.comQueryButton.focus();
                           this.comQueryButton.blur();

                           let addProductModalData = this.state.addProductModalData
                           addProductModalData.isModalOpen = false

                           this.setState({addProductModalData:addProductModalData})
                       }}
                       onOk={this.onClickAddButtonInAddProductModal}
                >
                    <Space direction="vertical" style={{width   :"100%", minHeight:"400px"}}>
                        <Row align="middle" style={{ width: "100%"}}>
                            <Col flex="10%"></Col>
                            <Col flex="auto">
                                <Space direction="vertical" style={{width:"100%"}}>
                                    <Row align="middle" style={{ width: "100%"}}>
                                        <Col align="right" flex="100px">编码：</Col>
                                        <Col flex="auto">
                                            <Input value={this.state.addProductModalData.id} style={{ width: "100%"}}
                                                   onChange={(e)=>{
                                                       let addProductModalData = this.state.addProductModalData
                                                       addProductModalData.id = e.target.value
                                                       this.setState({
                                                           addProductModalData: addProductModalData
                                                       })
                                                   }}
                                            ></Input>
                                        </Col>
                                        <Col flex="100px" style={{}}></Col>
                                    </Row>
                                    <Row align="middle" style={{ width: "100%"}}>
                                        <Col align="right" flex="100px">名称：</Col>
                                        <Col flex="auto">
                                            <Input value={this.state.addProductModalData.name} style={{ width: "100%"}}
                                                   onChange={(e)=>{
                                                       let addProductModalData = this.state.addProductModalData
                                                       addProductModalData.name = e.target.value
                                                       this.setState({
                                                           addProductModalData: addProductModalData
                                                       })
                                                   }}
                                            ></Input>
                                        </Col>
                                        <Col flex="100px" style={{}}></Col>
                                    </Row>
                                    <Row align="middle" style={{ width: "100%"}}>
                                        <Col align="right" flex="100px">品牌：</Col>
                                        <Col flex="auto">
                                            <Input value={this.state.addProductModalData.brand} style={{ width: "100%"}}
                                                   onChange={(e)=>{
                                                       let addProductModalData = this.state.addProductModalData
                                                       addProductModalData.brand = e.target.value
                                                       this.setState({
                                                           addProductModalData: addProductModalData
                                                       })
                                                   }}
                                            ></Input>
                                        </Col>
                                        <Col flex="100px" style={{}}></Col>
                                    </Row>
                                    <Row align="middle" style={{ width: "100%"}}>
                                        <Col align="right" flex="100px">型号：</Col>
                                        <Col flex="auto">
                                            <Input value={this.state.addProductModalData.model} style={{ width: "100%"}}
                                                   onChange={(e)=>{
                                                       let addProductModalData = this.state.addProductModalData
                                                       addProductModalData.model = e.target.value
                                                       this.setState({
                                                           addProductModalData: addProductModalData
                                                       })
                                                   }}
                                            ></Input>
                                        </Col>
                                        <Col flex="100px" style={{}}></Col>
                                    </Row>
                                    <Row align="middle" style={{ width: "100%"}}>
                                        <Col align="right" flex="100px">规格：</Col>
                                        <Col flex="auto">
                                            <TextArea rows={4} value={this.state.addProductModalData.specification} style={{ width: "100%"}}
                                                   onChange={(e)=>{
                                                       let addProductModalData = this.state.addProductModalData
                                                       addProductModalData.specification = e.target.value
                                                       this.setState({
                                                           addProductModalData: addProductModalData
                                                       })
                                                   }}
                                            ></TextArea>
                                        </Col>
                                        <Col flex="100px" style={{}}></Col>
                                    </Row>
                                    <Row align="middle" style={{ width: "100%"}}>
                                        <Col align="right" flex="100px">单位：</Col>
                                        <Col flex="auto">
                                            <Input value={this.state.addProductModalData.unit} style={{ width: "100%"}}
                                                   onChange={(e)=>{
                                                       let addProductModalData = this.state.addProductModalData
                                                       addProductModalData.unit = e.target.value
                                                       this.setState({
                                                           addProductModalData: addProductModalData
                                                       })
                                                   }}
                                            ></Input>
                                        </Col>
                                        <Col flex="100px" style={{}}></Col>
                                    </Row>
                                    <Row align="middle" style={{ width: "100%"}}>
                                        <Col align="right" flex="100px">归属类目：</Col>
                                        <Col flex="150px">
                                            <Dropdown trigger={"click"} menu={{items:productPrimaryTypeDropdownMenuItems, onClick:this.onClickSearchProductPrimaryTypeDropdown}}>
                                                <Button style={{width:"100%"}}>
                                                    <Space direction="vertical" align="right" style={{width:"100%"}}>
                                                        <Row style={{}}>
                                                            <Col align="center" flex="auto">{this.state.productPrimaryTypeNameOfSelect}</Col>
                                                            <Col style={{}}><DownOutlined /></Col>
                                                        </Row>
                                                    </Space>
                                                </Button>
                                            </Dropdown>
                                        </Col>
                                        <Col flex={"10px"}></Col>
                                        <Col flex="150px">
                                            <Dropdown trigger={"click"} menu={{items:productSecondaryTypeDropdownMenuItems, onClick:this.onClickSearchProductSecondaryTypeDropdown}}>
                                                <Button style={{width:"100%"}}>
                                                    <Space direction="vertical" align="right" style={{width:"100%"}}>
                                                        <Row style={{}}>
                                                            <Col align="center" flex="auto">{this.state.productSecondaryTypeNameOfSelect}</Col>
                                                            <Col style={{}}><DownOutlined /></Col>
                                                        </Row>
                                                    </Space>
                                                </Button>
                                            </Dropdown>
                                        </Col>
                                        <Col flex="100px" style={{}}></Col>
                                    </Row>
                                    <Row align="middle" style={{ width: "100%"}}>
                                        <Col align="right" flex="100px">成本：</Col>
                                        <Col flex="auto">
                                            <Input value={this.state.addProductModalData.costPrice} style={{ width: "100%"}}
                                                   onChange={(e)=>{
                                                       let addProductModalData = this.state.addProductModalData
                                                       addProductModalData.costPrice = e.target.value
                                                       this.setState({
                                                           addProductModalData: addProductModalData
                                                       })
                                                   }}
                                            ></Input>
                                        </Col>
                                        <Col flex="100px" style={{}}></Col>
                                    </Row>
                                    <Row align="middle" style={{ width: "100%"}}>
                                        <Col align="right" flex="100px">售价：</Col>
                                        <Col flex="auto">
                                            <Input value={this.state.addProductModalData.sellPrice} style={{ width: "100%"}}
                                                   onChange={(e)=>{
                                                       let addProductModalData = this.state.addProductModalData
                                                       addProductModalData.sellPrice = e.target.value
                                                       this.setState({
                                                           addProductModalData: addProductModalData
                                                       })
                                                   }}
                                            ></Input>
                                        </Col>
                                        <Col flex="100px" style={{}}></Col>
                                    </Row>
                                    <Row align="middle" style={{ width: "100%"}}>
                                        <Col align="right" flex="100px">状态：</Col>
                                        <Col flex="auto">
                                            <Dropdown trigger={"click"} menu={{items:statusDropdownMenuItems, onClick:this.onClickModifyPartnerModalStatusDropdown}}>
                                                <Button>
                                                    <Space>
                                                        {this.state.addProductModalData.statusName}
                                                        <DownOutlined />
                                                    </Space>
                                                </Button>
                                            </Dropdown>
                                        </Col>
                                        <Col flex="100px" style={{}}></Col>
                                    </Row>
                                    <Row align="middle" style={{ width: "100%"}}>
                                        <Col align="right" flex="100px">排序：</Col>
                                        <Col flex="auto">
                                            <Input value={this.state.addProductModalData.sort} style={{ width: "100%"}}
                                                   onChange={(e)=>{
                                                       let addProductModalData = this.state.addProductModalData
                                                       addProductModalData.sort = e.target.value
                                                       this.setState({
                                                           addProductModalData: addProductModalData
                                                       })
                                                   }}
                                            ></Input>
                                        </Col>
                                        <Col flex="100px" style={{}}></Col>
                                    </Row>
                                </Space>
                            </Col>
                            <Col flex="10%"></Col>
                        </Row>
                    </Space>
                </Modal>



                <Space align="" direction="vertical" style={{width:"100%"}}>
                    <Row align="middle"  style={{ width: "100%" }}>
                        <Col flex="125px">

                            <Button type="primary"
                                    onClick={()=>{
                                        this.comQueryButton.focus()
                                        this.comQueryButton.blur()

                                        this.setState({
                                            isProductPrimaryTypeManageModalOpen:true
                                        })
                                    }}
                            >一级类目管理</Button>
                        </Col>
                        <Col flex="125px">
                            <Button type="primary"
                                    onClick={()=>{
                                        this.comQueryButton.focus()
                                        this.comQueryButton.blur()

                                        let productSecondaryTypeManageModalData = this.state.productSecondaryTypeManageModalData
                                        productSecondaryTypeManageModalData.isProductSecondaryTypeManageModalOpen = true

                                        this.updateProductSecondaryTypeListOfProductSecondaryTypeManageModal(productSecondaryTypeManageModalData.productPrimaryTypeIdOfSelect)
                                        this.setState({productSecondaryTypeManageModalData:productSecondaryTypeManageModalData})
                                    }}>二级类目管理</Button>
                        </Col>
                        <Col flex="125px">
                            <Button type="primary"
                                    onClick={()=>{
                                        this.comQueryButton.focus()
                                        this.comQueryButton.blur()

                                        let addProductModalData = this.state.addProductModalData
                                        addProductModalData.isModalOpen=true

                                        this.setState({addProductModalData:addProductModalData})
                                    }}>新增产品
                            </Button>
                        </Col>
                        <Col flex="auto"></Col>
                        <Col style={{width:"320px"}}>
                            <Space align="" direction="vertical" style={{width:"100%"}}>
                                <Row style={{ width: "100%" }}>
                                    <Col flex="150px">
                                        <Dropdown trigger={"click"} menu={{items:productPrimaryTypeDropdownMenuItems, onClick:this.onClickSearchProductPrimaryTypeDropdown}}>
                                            <Button style={{width:"100%"}}>
                                                <Space direction="vertical" align="right" style={{width:"100%"}}>
                                                    <Row style={{}}>
                                                        <Col align="center" flex="auto">{this.state.productPrimaryTypeNameOfSelect}</Col>
                                                        <Col style={{}}><DownOutlined /></Col>
                                                    </Row>
                                                </Space>
                                            </Button>
                                        </Dropdown>
                                    </Col>
                                    <Col flex={"10px"}></Col>
                                    <Col flex="150px">
                                        <Dropdown trigger={"click"} menu={{items:productSecondaryTypeDropdownMenuItems, onClick:this.onClickSearchProductSecondaryTypeDropdown}}>
                                            <Button style={{width:"100%"}}>
                                                <Space direction="vertical" align="right" style={{width:"100%"}}>
                                                    <Row style={{}}>
                                                        <Col align="center" flex="auto">{this.state.productSecondaryTypeNameOfSelect}</Col>
                                                        <Col style={{}}><DownOutlined /></Col>
                                                    </Row>
                                                </Space>
                                            </Button>
                                        </Dropdown>
                                    </Col>
                                </Row>
                            </Space>
                        </Col>

                        <Col align="right" flex="300px" style={{}}>
                            {/*<Button type={"primary"} ref={(button)=>this.comQueryButton = button} onClick={()=>{}}>查询</Button>*/}
                            <Input style={{ width: '230px' }} value={this.state.keywordOfSearchProductInfoName} onChange={this.onChangeSearchProductInfoListInput}/>
                            <Button type="primary" ref={(button)=>this.comQueryButton = button} onClick={this.onClickSearchProductInfoListButton}>搜索</Button>
                        </Col>
                    </Row>

                    <Divider orientation="middle"></Divider>
                        {productInfoListContent}
                    <Divider/>


                    <Row align="middle" style={{ width: "100%" }}>
                        <Col flex="auto" align="middle" style={{}}>
                            <Pagination showSizeChanger current={this.state.page} pageSize={this.state.pageSize} pageSizeOptions={[2,10,50]} defaultCurrent={1} total={this.state.resultCount} onChange={this.onChangePageOrSize} />
                        </Col>
                    </Row>

                </Space>
            </div>
        )
    }
}

export default connect(
    //mapStateToProps
    state => {
        return {
            jwt: state.loginInfo.jwt
        }
    },
    //mapDispatchToProps
    {

    }
)(CompanyIctProductPage);