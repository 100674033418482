import { createStore, applyMiddleware, combineReducers } from "redux";
import logger from "redux-logger";
import thunk from "redux-thunk";
import loginInfoReducer from "./loginInfoReducer";
import clientHeightReducer from "./clientHeightReducer";
import attributeListReducer from "./attributeListReducer";
import { configureStore } from "@reduxjs/toolkit";

// const store = createStore(
//   combineReducers({
//     clientHeight : clientHeightReducer,
//     loginInfo : loginInfoReducer,
//     attributeList: attributeListReducer
//   }),
//   applyMiddleware(logger, thunk),
// );

const store = configureStore({
    reducer: {
        clientHeight : clientHeightReducer,
        loginInfo : loginInfoReducer,
        attributeList: attributeListReducer
    },
    middleware: [thunk, logger]
    },

);

export default store;
