import {ACTION} from "../system/System"
import * as System from "../system/System"

export default function attributeListReducer(state=[], action) {
    switch (action.type) {
        case ACTION.UPDATE_ATTRIBUTE_LIST:
            return action.attributeList;
        default:
            return state;
    }
}